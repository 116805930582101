import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ModeloDiagnostico } from 'src/app/models/modelo-diagnostico';
import { DominioService } from 'src/app/services/dominio.service';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloDiagnosticoService } from 'src/app/services/modelo-diagnostico.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { DominioIdDescricaoDTO } from 'src/app/utils/dominio-id-descricao.dto';
import { ModeloDiagnosticoFormComponent } from '../modelo-diagnostico-form/modelo-diagnostico-form.component';


@Component({
  selector: 'app-modelo-diagnostico-list',
  templateUrl: './modelo-diagnostico-list.component.html',
  styleUrls: ['./modelo-diagnostico-list.component.css']
})
export class ModeloDiagnosticoListComponent implements OnInit {

  formModeloDiagnosticoDadosFind: FormGroup;
  util: UtilityService;
  listMd: ModeloDiagnostico[] = new Array();
  page: number;
  pageSize: number = 4;
  totalSize: number;
  listDominioSituacao: DominioIdDescricaoDTO[];
  @Output() fnAlterar = new EventEmitter<number>();

  constructor(
    private fb: FormBuilder,
    private utilService: UtilityService,
    private modeloDiagnosticoService: ModeloDiagnosticoService,
    private modalService: ModalUiService,
    private dominioService: DominioService
  ) {
    this.util = utilService;
  }

  ngOnInit(): void {
    this.formModeloDiagnosticoDadosFind = this.fb.group({
      numero: [''],
      dataEnvioAprovacao: [''],
      descricao: ['', Validators.maxLength(200)],
      ativo: [''],
      dominioIdSituacao: ['']
    });
    this.page = 1;
    this.totalSize = 0;
    this.dominioService.getDominioModeloDiagnostivoSituacao().subscribe(response => {
      this.listDominioSituacao = response;
    });

    this.buscar();
  }

  buscar(id?: number) {
    this.modeloDiagnosticoService.find(id ? {numero : id} : this.formModeloDiagnosticoDadosFind.value, this.page - 1, this.pageSize).subscribe(response => {
      this.listMd = response.list;
      this.totalSize = response.totalSize;
    }, error => {
      if (error.status == 0) {
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: 'Ocorreu um erro ao listar o modelo diagnóstico',
          buttons: [EnumModalButtons.OK]
        });
      }
      this.listMd = new Array();
    });
  }

  alterar(id) {
    this.fnAlterar.emit(id);
  }

  enviarParaAprovacao(id: number) {
    this.modalService.open({
      buttons: [EnumModalButtons.YES, EnumModalButtons.NO],
      type: EnumModalType.ALERT,
      message: 'Deseja enviar o modelo diagnóstico para aprovação?',
      yesEvent: () => {
        this.formModeloDiagnosticoDadosFind.value.usuario = this.utilService.getDtnUserWrapper().getNomeUsuario();
        this.modeloDiagnosticoService.sendToApprove(id, this.formModeloDiagnosticoDadosFind.value).subscribe(response => {

          this.modalService.open({
            buttons: [EnumModalButtons.OK],
            type: EnumModalType.SUCCESS,
            message: "Modelo diagnóstico enviado para aprovação",
            okEvent: () => this.buscar(id)
          })

        },
          errorResponse => {

            switch (errorResponse.status) {
              case 404:
              case 422:
                this.modalService.open({
                  type: EnumModalType.ALERT,
                  message: errorResponse.error.errors.join('. '),
                  buttons: [EnumModalButtons.OK]
                });
                break;
              default:
                this.modalService.open({
                  type: EnumModalType.ERROR,
                  message: 'Não foi possível alterar o modelo diagnóstico',
                  buttons: [EnumModalButtons.OK]
                });
                break;
            }

          })
      }
    })
  }
}
