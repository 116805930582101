<form [formGroup]="formCalendario" (ngSubmit)="submit()">
  <fieldset class="form-group border p-2">
    <div class="form-row">
      <div class="col-12">
      
        <div fxLayout="row wrap">


          <div fxFlex="50" fxFillLayoutAlign="start center"> 
           <label for="situacao" class="col-md-2 col-form-label">Mês/ano</label>
            
               <input [matDatepicker]="dp"  placeholder="Mês/Ano" id="data" [formControl]="dateInput" name="date" formControlName="data" [readonly]="formCalendario.value.id" required>
               
               <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
               <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp, 'dateInput')"  ></mat-datepicker> 
           
             </div>
         <div fxFlex="50" fxFillLayoutAlign="start center"></div>         
           </div>
        <div class="form-row mb-3">
          <label for="situacao" class="col-md-2 col-form-label">Situação</label>
          <div class="col-md-3">
            <select id="situacao" class="custom-select" formControlName="dominioIdSituacao" required>
              <option value="" selected>--</option>
              <option *ngFor="let dominio of dominios" [value]="dominio.id">{{dominio.descricao}}</option>
            </select>
          </div>
        </div>
        <div class="form-row float-right">
          
          <button [disabled]="!dateValidator"  type="submit"  class="btn btn-success mr-2">
            <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
            </svg>
            Salvar</button>
            <button type="button" class="btn btn-warning mr-2" (click)="limpar()">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                <path
                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg>
              Limpar</button>
        </div>
      </div>
    </div>
  </fieldset>
</form>
<form [formGroup]="formFiltro" (ngSubmit)="buscar()">
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th id="clMesAno" >
          Mês/Ano
       

            <div fxFlex="50" fxFillLayoutAlign="start left"> 
              
                
                  <input [matDatepicker]="picker"  placeholder="Mês/Ano" [formControl]="dateFilter" name="date" formControlName="data" [readonly]="formCalendario.value.id" required>
                  
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                
                  <mat-datepicker #picker startView="multi-year" (monthSelected)="setMonthAndYear($event, picker, 'dateFilter')"  ></mat-datepicker> 
                      
                </div>
        </th>
        <th id="clSituacao">
          Situação
          <select id="situacao" class="custom-select" formControlName="dominioIdSituacao">
            <option value="" selected>--</option>
            <option *ngFor="let dominio of dominios" [value]="dominio.id">{{dominio.descricao}}</option>
          </select>
        </th>
        <th id="clBotoes">
          <button type="submit" class="btn btn-outline-secondary float-right">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
              <path fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
          </svg>
      </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mes of mesesCalendario">
        <td>{{mes.data + '-01' | date: 'MM/yyyy' }}</td>
        <td>{{mes.dominioDescricao}}</td>
        <td>
          <button type="button" class="btn btn-outline-secondary float-right" placement="bottom" ngbTooltip="Alterar" (click)="alterar(mes.id)">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z">
                </path>
            </svg>
        </button>
        </td>
      </tr>
      <tr *ngIf="mesesCalendario.length == 0">
        <td colspan="3" align="center">
          Nenhum registro encontrado.
        </td>
      </tr>
    </tbody>
  </table>
  <app-pagination *ngIf="mesesCalendario.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
    (pageChange)="buscar()"></app-pagination>
</form>
