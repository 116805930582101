
<form [formGroup]="formModeloDiagnosticoDadosFind" (ngSubmit)="buscar()">
    <div class="row mt-3">
        <div class="col-md-12">
            <table class="table table-condensed table-hover">
                <thead>
                    <tr>
                        <th style="width: 10%;" class="align-middle">
                            Número
                            <input type="number" class="form-control col-md-9" id="numero"
                                (keypress)="util.numberOnly($event)" formControlName="numero">
                        </th>
                        <th style="width: 12%;">
                            Data envio aprovação
                            <input type="date" class="form-control col-md-12" formControlName="dataEnvioAprovacao" />
                        </th>
                        <th>
                            Descrição
                            <input type="text" class="form-control col-md-12" id="descricao" maxlength="200"
                                formControlName="descricao">
                        </th>
                        <th style="width: 24%;">
                            Situação
                            <select id="dominioIdSituacao" class="custom-select col-md-12" formControlName="dominioIdSituacao">
                                <option value="" selected>--</option>
                                <option *ngFor="let dominio of listDominioSituacao" value="{{dominio.key}}">
                                    {{dominio.value}}</option>
                            </select>
                        </th>
                        <th style="width: 10%;">
                            Ativo
                            <select id="ativo" class="custom-select col-md-12" formControlName="ativo">
                                <option value="" selected>--</option>
                                <option value="true" selected>Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </th>
                        <th style="width: 6%;">
                            <button type="submit" class="btn btn-outline-secondary" onclick="this.blur()"
                                [disabled]="!formModeloDiagnosticoDadosFind.valid">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                    <path fill-rule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                </svg>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let md of listMd">
                        <td>{{md.numero}}</td>
                        <td>{{md.dataEnvioAprovacao}}</td>
                        <td>{{md.descricao}}</td>
                        <td>{{md.dominioSituacao.descricao}}</td>
                        <td>{{md.ativo}}</td>
                        <td>
                            <button type="button" class="btn btn-outline-secondary" placement="bottom" ngbTooltip="Alterar" (click)="alterar(md.id)">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z">
                                    </path>
                                </svg>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="listMd.length == 0">
                        <td colspan="6" align="center">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-pagination *ngIf="listMd.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="buscar()"></app-pagination>
        </div>
    </div>
</form>