<form [formGroup]="formResponsavel" (ngSubmit)="salvar()">
  <div class="modal-header">
    Incluir {{tabType == tabEnum.Acompanhante? 'Acompanhante' : 'Profissional de Apoio'}}
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="nome" class="col-md-3 col-form-label">Nome *</label>
          <div class="col-md-9">
            <input type="text" class="form-control col-md-9" id="nome" placeholder="Nome" formControlName="nome" />
            <div class="text-danger" *ngIf="controls.nome.invalid && (controls.nome.dirty || controls.nome.touched)">
              <small *ngIf="controls.nome.errors.required">
                  {{messageError.campoObrigatorio}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="cpf" class="col-md-3 col-form-label">CPF *</label>
          <div class="col-md-9">
            <input type="text" class="form-control col-md-9" id="cpf" placeholder="CPF" formControlName="cpf"
              [textMask]="{ mask: MASKS.cpf.textMask }" />
            <div class="text-danger"
              *ngIf="controls.cpf.invalid && (controls.cpf.dirty || controls.cpf.touched)">
              <small *ngIf="controls.cpf.errors.required">{{messageError.campoObrigatorio}}</small>
              <small *ngIf="controls.cpf.errors.minlength">{{messageError.campoInvalido}}</small>
              <small *ngIf="controls.cpf.errors.cpf">{{messageError.cpfInvalido}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="form-row">
      <button [disabled]="!formResponsavel.valid" type="submit" class="btn btn-success float-right" onclick="this.blur()">
        <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
        </svg>
        Salvar
      </button>
      <button type="button" class="btn btn-light ml-2" (click)="modalAtivaService.close('Close click')">
        Cancelar</button>
    </div>
  </div>
</form>
