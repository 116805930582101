import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModeloTermo } from 'src/app/models/modelo-termo';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloTermoCompromissoService } from 'src/app/services/modelo-termo.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { DominioIDModeloTermoSituacao } from 'src/app/utils/dominio-enum';

@Component({
  selector: 'app-modelo-termo-aprovacao-list',
  templateUrl: './modelo-termo-aprovacao-list.component.html',
  styleUrls: ['./modelo-termo-aprovacao-list.component.css']
})

export class ModeloTermoAprovacaoListComponent implements OnInit {
    formFiltro: FormGroup;
    page: number;
    pageSize: number;
    totalSize: number;
    modelosTermo: ModeloTermo[];
    
    constructor(
    private formBuilder: FormBuilder,
    private modeloTermoService: ModeloTermoCompromissoService,
    private modalService: ModalUiService
    ){ 
    }
    
    ngOnInit(): void {
        this.modelosTermo = new Array();
        this.page = 1;
        this.totalSize = 0;
        this.pageSize = 8;

        this.formFiltro = this.formBuilder.group({
            numero: [''],
            dataEnvioAprovacao: [''],
            descricao: ['', Validators.maxLength(200)],
            dominioIdSituacao: ['']
        });
        this.buscar();
    }

    buscar() {
        this.formFiltro.patchValue({dominioIdSituacao: DominioIDModeloTermoSituacao.AGUARDANDO_APROVACAO});
        this.modeloTermoService
            .find(this.formFiltro.value, (this.page - 1), this.pageSize)
            .subscribe(response => {
                this.modelosTermo = response.list;
                this.totalSize = response.totalSize;
            }, error => {
                if(error.status == 0) {
                    this.modalService.open({
                        type: EnumModalType.ERROR,
                        message: 'Ocorreu um erro ao listar os modelos pendentes de aprovação',
                        buttons: [EnumModalButtons.OK]
                    });
                }

              this.modelosTermo = new Array();
            });
    }

    visualizar(id?: number) {}
}