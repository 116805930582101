import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { EscolasAtendidasAnualmente } from "src/app/models/escolas-atendidas-anualmente";
import { EscolasAtendidasConsolidado } from "src/app/models/escolas-atendidas-consolidado";
import { ParametrosRelatorio } from "src/app/models/parametros-relatorio";
import { MessageService } from "src/app/services/message.service";
import { RelatoriosService } from "src/app/services/relatorios.service";
import { DynamicComponentDirective } from "src/app/template/dynamic-component/dynamic-component.directive";
import { AgrupamentoRelatorioEnum } from "src/app/utils/agrupamento-relatorio.enum";
import { DominioIdEscolaTipo, DominioIdPeriodoTodos } from "src/app/utils/dominio-enum";
import { KeyValuePair } from "src/app/utils/key-value-pair";
import { ChartData } from "src/app/utils/ng2-charts/chart-data";


@Component({
  selector: 'app-escolas-atendidas',
  templateUrl: './escolas-atendidas.component.html',
  styleUrls: ['./escolas-atendidas.component.css']
})
export class EscolasAtendidasComponent implements OnInit {
  @Input() parametros: ParametrosRelatorio;

  @ViewChild(DynamicComponentDirective)
  chartComponent: DynamicComponentDirective;

  abaAtiva: number = 0;
  abas: KeyValuePair<number, ChartData[]>[] = [];
  dados$: Observable<EscolasAtendidasConsolidado[]>;

  readonly agrupamentoRelatorio = AgrupamentoRelatorioEnum;
  readonly periodo = DominioIdPeriodoTodos;
  readonly tipoEscola = DominioIdEscolaTipo;
  readonly meses = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
  };
  readonly series = {
    1: '1º Ano',
    2: '2º Ano',
    3: '3º Ano',
    4: '4º Ano',
    5: '5º Ano'
  }
  readonly grupoMeses = {
    0: 'Geral',
    1: 'Jan à Abr.',
    2: 'Mai. à Ago.',
    3: 'Set. à Dez.'
  }

  constructor(private relatoriosService: RelatoriosService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.relatoriosService.getEscolasAtendidasDataChart(this.parametros).subscribe(e => {
      this.abas = [
        { key: 0, value: e.total },
        { key: 1, value: this.getTabData(e.data, 1, 4)},
        { key: 2, value: this.getTabData(e.data, 5, 8)},
        { key: 3, value: this.getTabData(e.data, 9, 12)}
      ];
    });

    this.dados$ = this.relatoriosService.getEscolasAtendidasData(this.parametros);
  }

  getTabData(data: ChartData[], monthStart: number, monthEnd: number): ChartData[] {
    let tabData = new Array<ChartData>();
    data.forEach((value, index, array) => {
      if(value.data.length > 0 && value.data.length >= monthStart - 1) {
        let chartData: ChartData = {
          data: [...value.data.slice((monthStart - 1), monthEnd)],
          label: value.label
        };
        tabData = [...tabData, chartData];
      }
      else {
        let chartData: ChartData = {
          data: [0, 0, 0, 0],
          label: value.label
        };
        tabData = [...tabData, chartData];
      }
    });

    return tabData;
  }

  getParamDescription(param: number, enumType: any): string {
    if(param == null || param == undefined) return '-';
    if(param == 0) return 'Todos';
    return enumType[param];
  }

  getCellData(valores: KeyValuePair<number, number>[], tipoEscola: DominioIdEscolaTipo) {
    return valores.find(v => v.key == tipoEscola).value;
  }

  print() {
    this.relatoriosService.printEscolasAtendidas(this.parametros)
      .subscribe((response) => {
        let blob = new Blob([response], { type: 'application/pdf' });
        let downloadURL = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = "QuantidadeEscolasAtendidas.pdf";
        link.click();
      }, (errorResponse) => {
        this.messageService.tratarMensagemErro(
          "Não foi possível gerar a impressão",
          true,
          errorResponse,
          () => {});
      });
  }
}
