import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TextMaskModule } from "angular2-text-mask";
import { NgBrazil } from "ng-brazil";
import { ServicesModule } from "src/app/services/services.module";
import { TemplateModule } from "src/app/template/template.module";
import { TurmaFormComponent } from "./turma-form/turma-form.component";
import { TurmaResponsavelComponent } from "./turma-responsavel/turma-responsavel.component";
import { TurmaRoutingModule } from "./turma-routing.module";
import { TurmaAcompanhanteProfissionalComponent } from "./turma-acompanhante-profissional/turma-acompanhante-profissional.component";
import { TurmaAlunoComponent } from './turma-aluno/turma-aluno.component';
import { TurmaChecklistComponent } from './turma-checklist/turma-checklist.component';
import { TurmaListComponent } from "./turma-list/turma-list.component";
import { TurmaViewComponent } from "./turma-view/turma-view.component";
import { TurmaChecklistViewComponent } from './turma-checklist-view/turma-checklist-view.component';

@NgModule({
  declarations: [
    TurmaFormComponent,
    TurmaAcompanhanteProfissionalComponent,
    TurmaResponsavelComponent,
    TurmaAlunoComponent,
    TurmaChecklistComponent,
    TurmaListComponent,
    TurmaViewComponent,
    TurmaChecklistViewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    TemplateModule,
    ServicesModule,
    TextMaskModule,
    NgBrazil,
    TurmaRoutingModule
  ],
  exports: [],
  bootstrap: []
})
export class TurmaModule {

}
