<div class="jumbotron" style="text-align: center;">
  <h3>Cadastrar Calendário</h3>
</div>
<div class="jumbotron" style="text-align: left;">
  <span>ATENÇÃO! As datas que não forem incluídas na listagem abaixo serão consideradas 'Não Disponíveis'</span>
</div>
<div class="container">
  <div class="row">
    <div class="col">
      <div class="row my-auto" style="height: 8vh"></div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.NAO_DISPONIVEL"></div>
        <div class="ml-2"><p class="mt-3">Não Disponível</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.DISPONIVEL"></div>
        <div class="ml-2"><p class="mt-3">Disponível</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.INDISPONIVEL"></div>
        <div class="ml-2"><p class="mt-3">Indisponível</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.FERIAS_RECESSO"></div>
        <div class="ml-2"><p class="mt-3">Férias/Recesso</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.AGENDADA"></div>
        <div class="ml-2"><p class="mt-3">Agendada</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.PLANEJAMENTO"></div>
        <div class="ml-2"><p class="mt-3">Planejamento</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.FERIADO"></div>
        <div class="ml-2"><p class="mt-3">Feriado/Recesso</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.REUNIAO"></div>
        <div class="ml-2"><p class="mt-3">Reunião</p></div>
      </div>
      <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.MANHA"></div>
        <div class="ml-2"><p class="mt-3">Matutino Agendado</p></div>
      </div>
       <div class="row mb-2">
        <div class="card col-2 color-opacity" [style.backgroundColor]="eventColor.TARDE"></div>
        <div class="ml-2"><p class="mt-3">Vespertino Agendado</p></div>
      </div>
    </div>      
    <div class="col-md-8 float-right">
      <div class="card">
        <div class="mt-3">
          <app-calendar [options]="calendarOptions"></app-calendar>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="agendamentoId" class="row float-right mr-1 mt-2">
    <button id="btnVoltar" class="btn btn-light ml-2" type="button" routerLink="/calendario">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
      </svg>
      Voltar</button>
  </div>
  <div *ngIf="agendamentoId == false" class="d-flex justify-content-end align-items-center">
    <button id="btnVoltar" class="btn btn-light ml-2" type="button" routerLink="/agendamento">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
      </svg>
      Voltar/AgendarVisita</button>
      <button id="btnProximo" class="btn btn-light ml-2" type="button" (click)="voltarEvento()" >
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
      </svg>
      Voltar</button>
      <button id="btnProximo" class="btn btn-light ml-2 " type="button" (click)="avancarEvento()">
      Proximo
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
      </svg>
      </button>
</div>
