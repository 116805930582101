import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Aluno } from '../models/aluno';

@Injectable({
  providedIn: 'root'
})
export class AlunoService {

  private readonly BASE_URL:string = `${environment.apiBaseUrl}/aluno`;

  constructor(private http: HttpClient) { }


  findAllByAgendamento(idAgendamento:number, isOperadorEscola: boolean) : Observable<Aluno[]> {
    let params = new HttpParams();
      
    params = params.set('isOperadorEscola', JSON.stringify(isOperadorEscola));
      
    return this.http.get<Aluno[]>(`${this.BASE_URL}/list-by-agendamento/${idAgendamento}`, {params: params});
  }
  
  create(aluno: Aluno, validarPrazo: boolean): Observable<Aluno> {
    let params = new HttpParams();
      
    params = params.set('validarPrazo', JSON.stringify(validarPrazo));
    
    return this.http.post<Aluno>(`${this.BASE_URL}`, aluno, {params: params});
  }

  findById(id: number) : Observable<Aluno> {
    return this.http.get<Aluno>(`${this.BASE_URL}/${id}`);
  }

  update(id: number, aluno: Aluno, validarPrazo: boolean): Observable<any> {
    aluno.id = id;
    
    let params = new HttpParams();
      
    params = params.set('validarPrazo', JSON.stringify(validarPrazo));
    
    return this.http.put<any>(`${this.BASE_URL}`, aluno, {params: params});
  }

  delete(id: number, validarPrazo: boolean): Observable<any> {
    let params = new HttpParams();
      
    params = params.set('validarPrazo', JSON.stringify(validarPrazo));
    
    return this.http.delete<any>(`${this.BASE_URL}/${id}`, {params: params});
  }
}
