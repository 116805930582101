<form [formGroup]="formModeloTermoTexto" (ngSubmit)="submit()" name="form">
      <fieldset class="form-group border p-2">
        <div style="display: flex;">
            <p><strong>I - RESPONSABILIDADE DA CIDADE ESCOLA DE TRÂNSITO *:</strong></p>
            <div *ngIf="formModeloTermoTexto.get('responsabilidadeCidadeEscolaTransito').dirty &&
                 (!formModeloTermoTexto.get('responsabilidadeCidadeEscolaTransito').value || formModeloTermoTexto.get('responsabilidadeCidadeEscolaTransito').value.trim() === '')">
                <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
            </div>
        </div>
        <div style="display: flex;">
            <textarea id="responsabilidadeCidadeEscolaTransito"  formControlName="responsabilidadeCidadeEscolaTransito"  class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>        
    </fieldset>
     <fieldset class="form-group border p-2">
         <div style="display: flex;">
             <p><strong>II – RESPONSABILIDADE DA ESCOLA *:</strong></p>
             <div *ngIf="formModeloTermoTexto.get('responsabilidadeEscola').dirty &&
                  (!formModeloTermoTexto.get('responsabilidadeEscola').value || formModeloTermoTexto.get('responsabilidadeEscola').value.trim() === '')">
                 <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
             </div>
         </div>
        <div style="display: flex;">
            <textarea id="responsabilidadeEscola"  formControlName="responsabilidadeEscola" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>      
    </fieldset>
       <fieldset class="form-group border p-2">
           <div style="display: flex;">
               <p>
                   <strong>III – INFORMAÇÕES SOBRE A VISITA *:</strong>
               </p>
               <div *ngIf="formModeloTermoTexto.get('informacoesSobreVisita').dirty &&
                    (!formModeloTermoTexto.get('informacoesSobreVisita').value || formModeloTermoTexto.get('informacoesSobreVisita').value.trim() === '')">
                   <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
               </div>
           </div>
        <div style="display: flex;">
            <textarea id="informacoesSobreVisita"  formControlName="informacoesSobreVisita" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>
           <div style="display: flex;">
               <p>
                   <strong>b.1. Período Matutino *:</strong>
               </p>
               <div *ngIf="formModeloTermoTexto.get('infoPeriodoMatutino').dirty && 
                    (!formModeloTermoTexto.get('infoPeriodoMatutino').value || formModeloTermoTexto.get('infoPeriodoMatutino').value.trim() === '')">
                   <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
               </div>
           </div>
        
        <div style="display: flex;">
            <textarea id="informacoesSobreVisitaPeriodoMatutino"  formControlName="infoPeriodoMatutino" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>
           <div style="display: flex;">
               <p>
                   <strong>b.2. Período Vespertino *:</strong>
               </p>
               <div *ngIf="formModeloTermoTexto.get('infoPeriodoVespetino').dirty && 
                    (!formModeloTermoTexto.get('infoPeriodoVespetino').value || formModeloTermoTexto.get('infoPeriodoVespetino').value.trim() === '')">
                   <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
               </div>
           </div>
        
         <div style="display: flex;">
            <textarea  id="informacoesSobreVisitaPeriodoVespetino" formControlName="infoPeriodoVespetino" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>
        <div style="display: flex;">
            <p>
                <strong>c) Horário previsto de saída do Ônibus de Transporte de Escolares – DETRANZINHO *:</strong>
            </p>
            <div *ngIf="formModeloTermoTexto.get('infoHorarioSaidaDoOnibus').dirty && 
                 (!formModeloTermoTexto.get('infoHorarioSaidaDoOnibus').value || formModeloTermoTexto.get('infoHorarioSaidaDoOnibus').value.trim() === '')">
                <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
            </div>
        </div>
         <div style="display: flex;">
            <textarea id="informacoesSobreVisitaHorarioPrevistoSaidaOnibus" formControlName="infoHorarioSaidaDoOnibus" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>
           <div style="display: flex;">
               <p>
                   <strong>d) Séries Atendidas: *</strong>
               </p>
               <div *ngIf="formModeloTermoTexto.get('infoSeriesAtendidas').dirty && 
                    (!formModeloTermoTexto.get('infoSeriesAtendidas').value || formModeloTermoTexto.get('infoSeriesAtendidas').value.trim() === '')">
                   <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
               </div>
           </div>
         
         <div style="display: flex;">
            <textarea id="informacoesSobreVisitaSeriesAtendidas" formControlName="infoSeriesAtendidas" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>
           <div style="display: flex;">
               <p>
                   <strong>e) Turmas *:</strong>
               </p>
               <div *ngIf="formModeloTermoTexto.get('infoTurmas').dirty && 
                    (!formModeloTermoTexto.get('infoTurmas').value || formModeloTermoTexto.get('infoTurmas').value.trim() === '')">
                   <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
               </div>
           </div>
          
         <div style="display: flex;">
            <textarea id="informacoesSobreVisitaTurmas" formControlName="infoTurmas" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>
    </fieldset>
      <fieldset class="form-group border p-2">
          <div style="display: flex;">
              <p>
                  <strong>OBSERVAÇÃO *:</strong>
              </p>
              <div *ngIf="formModeloTermoTexto.get('observacao').dirty && 
                   (!formModeloTermoTexto.get('observacao').value || formModeloTermoTexto.get('observacao').value.trim() === '')">
                  <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
              </div>
          </div>
        
        <div style="display: flex;">
            <textarea id="observacao" formControlName="observacao" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>        
    </fieldset>
    <fieldset class="form-group border p-2">
        <div style="display: flex;">
            <p>
                <strong>MAIORES INFORMAÇÕES *:</strong>
            </p>
            <div *ngIf="formModeloTermoTexto.get('maioresInformacoes').dirty && 
                 (!formModeloTermoTexto.get('maioresInformacoes').value || formModeloTermoTexto.get('maioresInformacoes').value.trim() === '')">
                <span class="text-danger" style="margin-left: 10pt">{{messageError.campoObrigatorio}}</span>
            </div>
        </div>
        
        <div style="display: flex;">
            <textarea id="maioresInformacoes" formControlName="maioresInformacoes" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>        
    </fieldset>
   
    <fieldset class="form-group border p-2">
        <p>
            <strong>PARAGRAFO ADICIONAL:</strong>
        </p>
        <div style="display: flex;">
            <textarea id="input1" formControlName="input1" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>        
    </fieldset>
    <fieldset class="form-group border p-2">
        <p>
            <strong>PARAGRAFO ADICIONAL:</strong>
        </p>
        <div style="display: flex;">
            <textarea id="input2" formControlName="input2" class="form-control" placeholder="Digite seu texto aqui"
                      style="height: 100px; width: 900px;">
            </textarea>
        </div>        
    </fieldset>
    <div class="form-row">
        <div class="col-12">
            <button type="submit" class="btn btn-success ml-2 float-right" id="salvarTexto">
                <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor" xmlns="http://www.w3.org/2000/svg" >
                    <path fill-rule="evenodd" d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z"/>
                </svg>
                Salvar Texto
            </button>
            <div style="display: flex; justify-content: end;">
                <button  type="button" class="btn btn-outline-secondary ml-2" (click)="voltar()">
                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                      fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                  </svg>
                  Voltar
                </button>
            </div>    
            <button type="button" class="btn btn-warning ml-2 float-right" (click)="limparForm()" *ngIf="this.id">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
                Limpar
            </button>
        </div>
    </div>
</form>
