<form [formGroup]="formAcompanhante" (ngSubmit)="insert()">
  <fieldset class="form-group border p-2">
    <div class="form-row mb-3">
      <label for="acompanhante" class="col-md-2 col-form-label">{{tabType == tabEnum.Acompanhante? 'Acompanhante' : 'Profissional de Apoio'}}</label>
      <div class="col-md-6">
        <ng-select-autocomplete #acompanhanteAC
          [control]="formAcompanhante.get('acompanhanteId')"
          (resourceQuery$)="acompanhanteQuery($event)">
        </ng-select-autocomplete>
      </div>
      <div class="col-md-4 d-flex justify-content-end">
        <button type="button" class="btn btn-primary mr-2" (click)="novoAcompanhante()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
          </svg>
          Novo {{tabType == tabEnum.Acompanhante? 'Acompanhante' : 'Profissional de Apoio'}}
        </button>
      </div>
    </div>
    <div class="form-row mb-3">
      <label for="funcao" class="col-md-2 col-form-label">Função</label>
      <div class="col-md-6">
        <select id="situacao" class="custom-select" formControlName="dominioIdFuncao" required>
          <option value="" selected>(Selecione)</option>
          <option *ngFor="let funcao of funcoes$" [value]="funcao.id">{{funcao.descricao}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-row float-right">
        <button type="submit" class="btn btn-success" [disabled]="!formAcompanhante.valid">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
          Incluir
        </button>
      </div>
    </div>
  </fieldset>
</form>
<table class="table table-condensed table-hover">
  <thead>
    <tr>
      <th id="clAcompanhantes">{{tabType == tabEnum.Acompanhante? 'Acompanhante' : 'Profissional de Apoio'}}</th>
      <th id="clCPF">CPF</th>
      <th id="clFuncao">Função</th>
      <th id="clBotoes"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let acompanhante of acompanhantes">
      <td>{{acompanhante.nome}}</td>
      <td>{{acompanhante.cpf | cpf}}</td>
      <td>{{acompanhante.funcao}}</td>
      <td *ngIf="!dtnUserWrapper.isOperadorEscola()">
          <button *ngIf="acompanhante.ativado == 1" type="button" class="btn btn-outline-danger float-right" style="padding-bottom: unset;" placement="bottom" ngbTooltip="Desativar" (click)="confirm(acompanhante.id,acompanhante.ativado)">
            <p>Desativar</p>
        </button>
          <button *ngIf="acompanhante.ativado == 0" type="button" class="btn btn-outline-success float-right" style="padding-bottom: unset;" placement="bottom" ngbTooltip="Ativar" (click)="confirm(acompanhante.id,acompanhante.ativado)">
            <p>Ativar</p>
        </button>  
      </td>
    </tr>
    <tr *ngIf="acompanhantes.length == 0">
      <td colspan="4" align="center">
        Nenhum registro encontrado.
      </td>
    </tr>
  </tbody>
</table>

<div class="col-12">
  <button type="button" class="btn btn-light mr-1 float-right" placement="bottom" ngbTooltip="Próximo" (click)="nextTab()">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
    </svg>
    Próximo
  </button>
  <button id="btnVoltar" class="btn btn-light" type="button" routerLink="/turma" *ngIf="tabType == turmaTabEnum.Acompanhante">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path fill-rule="evenodd"
          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
    </svg>
    Voltar para listagem
  </button>
  <button id="btnVoltar" class="btn btn-light" type="button" (click)="backTab()" *ngIf="tabType == turmaTabEnum.ProfissionalApoio">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path fill-rule="evenodd"
          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
    </svg>
    Voltar
  </button>
</div>
