import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() page: number;
  @Input() totalSize: number;
  @Input() pageSize: number;
  @Output() pageChange: EventEmitter<number>;


  constructor() {
    this.pageChange = new EventEmitter<number>();
  }

  ngOnInit(): void {
  }
  change() {
    this.pageChange.emit(this.page);
  }

}
