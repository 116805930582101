import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscolaService } from './escola.service';
import { EnderecoEscolaService} from './endereco-escola.service';
import { DominioService } from './dominio.service';
import { ModeloDiagnosticoService } from './modelo-diagnostico.service';
import { ModalUiService } from './modal-ui.service';
import { ModeloDiagnosticoAlternativaService } from './modelo-diagnostico-alternativa.service';
import { ModeloDiagnosticoVariavelService } from './modelo-diagnostico-variavel.service';
import { CalendarioService } from './calendario.service';
import { MessageService } from './message.service';
import { CalendarioStateService } from './calendario-state.service';
import { AgendamentoService } from './agendamento.service';
import { TurmaService } from './turma.service';
import { ServiceLocator } from './locator.service';
import { ModeloTermoCompromissoService } from './modelo-termo.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    EscolaService,
    EnderecoEscolaService,
    DominioService,
    ModeloDiagnosticoService,
    ModeloDiagnosticoVariavelService,
    ModalUiService,
    ModeloDiagnosticoAlternativaService,
    CalendarioStateService,
    CalendarioService,
    AgendamentoService,
    TurmaService,
    MessageService,
    ServiceLocator,
    ModeloTermoCompromissoService
  ]
})
export class ServicesModule { }
