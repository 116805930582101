import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EnderecoFormComponent } from '../escola/endereco-form/endereco-form.component';
import { EscolaListComponent } from '../escola/escola-list/escola-list.component';
import { RelatoriosFormComponent } from "./relatorios-form/relatorios-form.component";
;
const routes: Routes = [
    {
        path: 'relatorios',
        children: [
            { path: '', component: RelatoriosFormComponent }





        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RelatoriosRoutingModule {

}
