<div class="jumbotron" style="text-align: center;">
  <h3>Impressão Autorização</h3>
</div>
<form [formGroup]="formFiltro" (ngSubmit)="search()">
  <table class="table table-condensed table-hover">
    <thead>
      <th id="clDataAutorizacao">Data Autorização
        <input type="date" id="dataAutorizacao" class="form-control col-md-11" formControlName="dataAgendada" />
      </th>
      <th id="clTurma">Turma
        <input type="text" id="numeroTurma" class="form-control" maxlength="9" formControlName="numeroTurma" />
      </th>
      <th id="clPeriodo">Período
        <select id="periodo" class="custom-select" formControlName="dominioIdPeriodo">
          <option value="" selected>Selecione</option>
          <option *ngFor="let dominio of dominioPeriodos" [value]="dominio.id">{{dominio.descricao}}</option>
        </select>
      </th>
      <th id="clEscola">Escola
        <div class="form-control border-0">{{dtnUserWrapper.isOperadorEscola() ? dtnUserWrapper.escola.nome : '-'}}</div>
      </th>
      <th id="clTotalVisitantes">Visitantes
        <div class="form-control border-0">-</div>
      </th>
      <th id="clBotoes">
        <button type="submit" class="btn btn-outline-secondary float-right">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
              <path fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
          </svg>
        </button>
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let agendamento of agendamentos">
        <td>{{agendamento.dataAgendada | date: "dd/MM/yyyy"}}</td>
        <td>{{agendamento.numeroTurma}}</td>
        <td>{{agendamento.periodo}}</td>
        <td>{{agendamento.escola}}</td>
        <td>{{agendamento.numeroVisitantes}}</td>
        <td>
          <button type="button" class="btn btn-outline-secondary float-right" ngbTooltip="Imprimir" (click)="print(agendamento.agendamentoId)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
            </svg>
          </button>
        </td>
      </tr>
      <tr *ngIf="agendamentos.length == 0">
        <td colspan="6" align="center">
          Nenhum registro encontrado.
        </td>
      </tr>
    </tbody>
  </table>
</form>
<app-pagination *ngIf="agendamentos.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
  (pageChange)="search()"></app-pagination>
