export class MessageError{
    
    campoObrigatorio:string;
    campoInvalido:string
    cpfInvalido:string;
    cnpjInvalido:string;
    celularInvalido:string;
    telefoneInvalido:string;
    cepInvalido: string;
    emailInvalido: string;

    constructor(){
        this.campoObrigatorio = "Campo Obrigatório não preenchido.";
        this.campoInvalido = "Valor inválido";
        this.cpfInvalido = "CPF inválido";
        this.cnpjInvalido = "CNPJ inválido";
        this.celularInvalido = "Celular inválido";
        this.telefoneInvalido = "Telefone inválido";
        this.cepInvalido = "CEP inválido";
        this.emailInvalido = "E-mail inválido";
    }

    public customCampoCaracteres(tamanhoMaximo: number): string{
        return `Tamanho máximo permitido ${tamanhoMaximo} caracter${tamanhoMaximo > 1 ? 'es' : ''}` 
    }
}