import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import InteractionPlugin from '@fullcalendar/interaction';
import DayGridPlugin from '@fullcalendar/daygrid';
import { CalendarComponent } from './calendar/calendar.component';
import { NgSelectAutocompleteComponent } from './ng-select-autocomplete/ng-select-autocomplete.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicComponentDirective } from './dynamic-component/dynamic-component.directive';

FullCalendarModule.registerPlugins([
  InteractionPlugin,
  DayGridPlugin
]);

@NgModule({
  declarations: [NavbarComponent, HomeComponent, ModalComponent, PaginationComponent, CalendarComponent, NgSelectAutocompleteComponent, DynamicComponentDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    FullCalendarModule,
    NgSelectModule
  ],
  exports:[
    NavbarComponent,
    HomeComponent,
    ModalComponent,
    PaginationComponent,
    CalendarComponent,
    NgSelectAutocompleteComponent,
    DynamicComponentDirective
  ],
  providers:[
  ]
})
export class TemplateModule { }
