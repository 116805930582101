import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModeloTermoTexto } from '../models/modelo-termo-texto';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
  providedIn: 'root'
})
export class ModeloTermoTextoService {
    
    private readonly BASE_URL = `${environment.apiBaseUrl}/modelo-termo`;
    
    constructor(private http: HttpClient) { }
    
    find(modeloTermo: ModeloTermoTexto, page:number, pageSize:number) : Observable<ReponsePaginationListDTO<ModeloTermoTexto>> {

        let httpParams = new HttpParams();
        Object.keys(modeloTermo).forEach(
          key => modeloTermo[key] && (httpParams = httpParams.append(key, modeloTermo[key]))
          );
        httpParams = httpParams.append("page", page.toString());
        httpParams = httpParams.append("pageSize", pageSize.toString());
        return this.http.get<ReponsePaginationListDTO<ModeloTermoTexto>>(this.BASE_URL, {
          params: httpParams
        });

    }

    findById(id:number) : Observable<ModeloTermoTexto>{
      return this.http.get<ModeloTermoTexto>(`${this.BASE_URL}/${id}`);
    }

    save(modeloTermo: ModeloTermoTexto) : Observable<ModeloTermoTexto>{
      console.log(modeloTermo)
      return this.http.post<ModeloTermoTexto>(this.BASE_URL, modeloTermo);
    }

    update(id:number, modeloTermo: ModeloTermoTexto) : Observable<any>{
      return this.http.put(`${this.BASE_URL}/${id}`, modeloTermo);
    }

    remove(id: number) : Observable<any> {
      return this.http.delete(`${this.BASE_URL}/${id}`);
    }
}