<form [formGroup]="formDatas" (ngSubmit)="submit()">
  <fieldset class="form-group border p-2">
    <div class="form-row">
      <div class="col-12">
        <div class="form-row mb-3">
          <label for="data" class="col-md-2 col-form-label">Data</label>
          <div class="col-md-3">
            <input type="date" class="form-control" id="data" placeholder="Data"
              formControlName="data" [min]="mes + '-01'" [max]="ultimoDiaDoMes()" required />
          </div>
        </div>
        <div class="form-row mb-3">
          <label for="evento" class="col-md-2 col-form-label">Evento</label>
          <div class="col-md-3">
            <select id="evento" class="custom-select" formControlName="dominioIdEvento" required>
              <option value="" selected>--</option>
              <option *ngFor="let dominio of dominios" [value]="dominio.id">{{dominio.descricao}}</option>
            </select>
          </div>
        </div>
        <div class="form-row float-right">
          <button type="submit" [disabled]="!formDatas.valid" class="btn btn-success mr-2">
            <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
            </svg>
            Salvar</button>
        </div>
      </div>
    </div>
  </fieldset>
</form>
<form [formGroup]="formFiltro" (ngSubmit)="buscar()">
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th id="clData">
          Data
          <input type="date" class="form-control col-md-9" id="data"
            formControlName="data" [min]="mes + '-01'" [max]="ultimoDiaDoMes()" />
        </th>
        <th id="clEvento">
          Evento
          <select id="evento" class="custom-select" formControlName="dominioIdEvento">
            <option value="" selected>--</option>
            <option *ngFor="let dominio of dominios" [value]="dominio.id">{{dominio.descricao}}</option>
          </select>
        </th>
        <th id="clBotoes">
          <button type="submit" class="btn btn-outline-secondary float-right">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                  d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
              <path fill-rule="evenodd"
                  d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
          </svg>
      </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let evento of eventos">
        <td>{{evento.data | date: "dd/MM/yyyy"}}</td>
        <td>{{evento.dominioDescricao}}</td>
        <td>
        <button type="button" class="btn btn-outline-danger float-right" placement="bottom" ngbTooltip="Excluir" (click)="excluir(evento.id)">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
        <button type="button" class="btn btn-outline-secondary float-right mr-1" placement="bottom" ngbTooltip="Alterar" (click)="alterar(evento.id)">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z">
                </path>
            </svg>
        </button>
        </td>
      </tr>
      <tr *ngIf="eventos.length == 0">
        <td colspan="3" align="center">
          Nenhum registro encontrado.
        </td>
      </tr>
    </tbody>
  </table>
  <app-pagination *ngIf="eventos.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
    (pageChange)="buscar()"></app-pagination>
  <div class="row d-flex justify-content-center">
    <button type="button" class="btn btn-success mb-3" [disabled]="eventos.length == 0" routerLink="visualizar/{{agendaId}}">
      <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-calendar-date" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
        <path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
      </svg><span class="ml-2">Visualizar Calendário</span>
    </button>
  </div>
</form>

