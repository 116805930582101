import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicesModule } from 'src/app/services/services.module';
import { TemplateModule } from 'src/app/template/template.module';
import { CalendarioRoutingModule } from './calendario-routing.module';
import { CalendarioTabCalendarioComponent } from './calendario-tab-calendario/calendario-tab-calendario.component';
import { CalendarioFormComponent } from './calendario-form/calendario-form.component';
import { CalendarioTabDatasComponent } from './calendario-tab-datas/calendario-tab-datas.component';
import { CalendarioViewComponent } from './calendario-view/calendario-view.component';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {CalendarioAtualizacaoFormComponent} from './calendario-atualizacao/calendario-atualizacao-form.component'

@NgModule({
    declarations: [
        CalendarioViewComponent,
        CalendarioTabCalendarioComponent,
        CalendarioTabDatasComponent,
        CalendarioFormComponent,
        CalendarioViewComponent,
        CalendarioAtualizacaoFormComponent
        
        
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        CalendarioRoutingModule,
        ServicesModule,
        TemplateModule,
        MatDatepickerModule, 
        MatNativeDateModule,
        MatFormFieldModule
    ],
    providers: []
})
export class CalendarioModule {

}
