<form [formGroup]="formHistory" (ngSubmit)="buscar()">
    <div class="row mt-3">
        <div class="col-md-12">
            <table class="table table-condensed table-hover">
                <thead>
                    <tr>
                        <th style="width: 47%;" class="align-middle">
                            Número
                            <input type="number" class="form-control col-md-9" id="numero"
                                (keypress)="utilService.numberOnly($event)" formControlName="numero">
                        </th>
                        <th style="width: 47%;">
                            Data
                            <input type="date" class="form-control col-md-12" formControlName="data" />
                        </th>
                        <th style="width: 6%;">
                            <button type="submit" class="btn btn-outline-secondary" onclick="this.blur()"
                                [disabled]="!formHistory.valid">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                    <path fill-rule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                </svg>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let md of listDiagnostico">
                    
                        <tr>
                            <td>{{md.id}}</td>
                            <td>{{utilService.formatDate(md.dataInclusao)}}</td>
                            <td>
                                <button type="button" class="btn btn-outline-secondary" placement="bottom" ngbTooltip="Visualizar" (click)="showHistory(md.id)">
                                    <svg *ngIf="idShow != md.id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                    </svg>
                                    <svg *ngIf="idShow == md.id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                      </svg>
                                </button>
                            </td>
                        </tr>
                        <tr class="hidden-table" [hidden]="idShow != md.id">
                            <td colspan="3">
                                <app-diagnostico-form [paramIdDiagnostico]="md.id"></app-diagnostico-form>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="listDiagnostico.length == 0">
                        <td colspan="6" align="center">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-pagination *ngIf="listDiagnostico.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="buscar()"></app-pagination>
        </div>
    </div>
</form>

<button type="button" class="btn btn-outline-secondary mb-5" routerLink="/escola/list">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle" fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
    </svg>
    Voltar
</button>