import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import ptLocale from '@fullcalendar/core/locales/pt-br';

@Component({
  selector: 'app-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit, AfterViewInit {

  private _options: CalendarOptions = {
    headerToolbar: {
      left: 'prev today next',
      center: 'title',
      right: ''
    },
    locales: [ ptLocale ],
    locale: 'pt-br'
  };

  @ViewChild('calendar')
  private fullcalendarComponent: FullCalendarComponent;

  calendarApi: Calendar;

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.calendarApi = this.fullcalendarComponent.getApi();
  }

  @Input() set options(value: CalendarOptions) {
    this._options = value;

    if(!this._options.initialView)
      this._options.initialView = 'dayGridMonth';

    if(!this._options.headerToolbar) {
      this._options.headerToolbar = {
        left: 'prev today next',
        center: 'title',
        right: ''
      }
    }

    this._options.locales = [ ptLocale ];
    this._options.locale = 'pt-br';
  }

  get options(): CalendarOptions {
    return this._options;
  }

}
