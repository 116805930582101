import { DominioIdDescricaoDTO } from '../utils/dominio-id-descricao.dto';

export class Escola {

    id: number;
    cnpj: string;
    tipo: string;
    situacao: string;
    nome: string;
    dominioSituacao: any;
    codigo: number;

    constructor() {}

    public setId(id): void {
        this.id = id;
    }

    public setCnpj(cnpj): void {
        this.cnpj = cnpj;
    }

    public setNome(nome): void {
        this.nome = nome;
    }

    public getId(): number {
        return this.id;
    }

    public getCnpj(): string {
        return this.cnpj;
    }

    public getNome(): string {
        return this.nome;
    }
    
    public getCodigo(): number {
        return this.codigo;
    }

    public setCodigo(codigo): void {
        this.codigo = codigo;
    }
}