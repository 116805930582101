import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Agendamento } from "src/app/models/agendamento";
import { AgendamentoDisponibilidade } from "src/app/models/agendamento-disponibilidade";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { MessageService } from "src/app/services/message.service";
import { UtilityService } from 'src/app/services/utility.service';
import { DominioIdPeriodo } from "src/app/utils/dominio-enum";
import { MessageError } from "src/app/utils/message-error";


@Component({
    selector: 'app-agendamento-form',
    templateUrl: './agendamento-form.component.html',
    styleUrls: ['./agendamento-form.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AgendamentoFormComponent implements OnInit {
    periodoEnum = DominioIdPeriodo;
    errorMessage: boolean = false;
    formAgendamento: FormGroup;
    get form() { return this.formAgendamento.controls; };

    messageError: MessageError;

    @Input() evento: AgendamentoDisponibilidade;
    @Input() escolaId: number;
    
    constructor(public modalAtivaService: NgbActiveModal,
        private fb: FormBuilder,
        private agendamentoService: AgendamentoService,
        private messageService: MessageService,
        private router: Router,
        private utils: UtilityService) {
        this.messageError = new MessageError();
    }

    ngOnInit(): void {
        this.formAgendamento = this.fb.group({
            turno: ['', Validators.required],
            ano1: [''],
            ano2: [''],
            ano3: [''],
            ano4: [''],
            ano5: ['']
        });
    }

    confirm() {
        if (this.formAgendamento.invalid || this.serieHasError()) {
            this.errorMessage = true;
            return;
        }

        let periodoMensagem = this.formAgendamento.value.turno == DominioIdPeriodo.Matutino ? "Matutino" : "Vespertino";
        
        if(periodoMensagem == "Matutino"){
             periodoMensagem = "Vespertino"
           }else{
               periodoMensagem = "Matutino"
               }
        
        this.messageService.confirmacao(`Você está agendando o dia ${new Date(this.evento.data).toLocaleDateString('pt-BR', { timeZone: 'GMT' })} no período ${periodoMensagem}. \nVocê tem certeza?`,
            () => { this.save(); }, () => { });
    }

    save() {
        this.agendamentoService.save(this.montaAgendamento())
            .subscribe(result => {
                this.messageService.tratarMensagemSucesso("Data agendada com sucesso.", true, () => {
                    this.modalAtivaService.dismiss();
                    this.router.navigate(['/agendamento']);
                });
            }, error => {
                this.messageService.tratarMensagemErro("Não foi possível efetuar o agendamento.", false, error, () => { });
            });
    }

    serieHasError(): boolean {
        if (!this.form.ano1.value && !this.form.ano2.value && !this.form.ano3.value &&
            !this.form.ano4.value && !this.form.ano5.value) {
            return true;
        }

        return false;
    }

    turnoIsUnavailable(turno: DominioIdPeriodo): boolean {
        if (this.evento && !this.evento.periodo.includes(turno))
            return true;

        return null;
    }

    montaAgendamento(): Agendamento {
        
        if(this.formAgendamento.value.turno == 28){
            this.formAgendamento.value.turno = 29
            }else{
                this.formAgendamento.value.turno = 28
                }
        
        let agendamento: Agendamento = {
            id: 0,
            agendaDataId: this.evento.agendaDataId,
            codigoEscola: this.escolaId,
            dominioIdPeriodo: this.formAgendamento.value.turno,
            turmas: [],
            usuario: this.utils.getDtnUserWrapper().getNomeUsuario(),
            cpfUsuario: this.utils.getDtnUserWrapper().getLogin()
        };

        Object.keys(this.formAgendamento.value).forEach(
            key => {
                if (key.includes("ano") && this.formAgendamento.value[key])
                    agendamento.turmas.push(+key.replace("ano", ""));
            });

        return agendamento;
    }
}
