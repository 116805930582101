import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { NgSelectConfig } from "@ng-select/ng-select";
import { concat, Observable, of, Subject } from "rxjs";
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { KeyValuePair } from "src/app/utils/key-value-pair";

@Component({
  selector: 'ng-select-autocomplete',
  templateUrl: 'ng-select-autocomplete.component.html',
  styleUrls: ['./ng-select-autocomplete.component.css']
})
export class NgSelectAutocompleteComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() placeholder: string;

  @Output() resourceQuery$ = new EventEmitter<String>();

  items$: Observable<KeyValuePair<number, string>[]>;
  input$ = new Subject<string>();

  isLoading = false;

  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Nenhum registro encontrado.';
    this.config.loadingText = 'Carregando...';
    this.config.typeToSearchText = 'Digite 3 ou mais caracteres';

    this.control = new FormControl();
  }

  ngOnInit(): void {
    this.load();
  }

  hidrate(data: KeyValuePair<number, string>[]) {
    this.items$ = concat(of(data), this.inputPipe());
    this.isLoading = false;
  }

  private load() {
    this.items$ = concat(of([]), this.inputPipe());
  }

  trackFn(item: KeyValuePair<number, string>) {
    return item.key;
  }

  private inputPipe() {
    return this.input$.pipe(
      distinctUntilChanged(),
      tap(() => this.isLoading = true),
      switchMap(input => this.propagateQuery(input)));
  }

  private propagateQuery(input: String): Observable<any> {
    this.resourceQuery$.emit(input);
    return of([]);
  };
}
