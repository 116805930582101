export enum DominioIdTipoAlternativa {
  OBJETIVA = 12,
  MULTIPLA_ESCOLHA = 13,
  VERDADEIRO_OU_FALSO = 14
};

export enum DominioIDModeloDiagnosticoSituacao {
  EM_ABERTO = 8,
  AGUARDANDO_APROVACAO = 9,
  APROVADA = 10,
  REPROVADA = 11
}

export enum DominioIdCadastroCalendario {
  DISPONIVEL = 15,
  INDISPONIVEL = 16
}

export enum DominioIdEventoCalendario {
  NAO_DISPONIVEL = 0,
  DISPONIVEL = 17,
  INDISPONIVEL = 18,
  PLANEJAMENTO = 19,
  FERIADO_RECESSO = 20,
  AGENDADA = 21,
  REUNIAO = 22,
  FERIAS_RECESSO = 23,
  MANHA = 28,
  TARDE = 29
}
export enum DominioIdEscolaSituacao {
  PRE_CADASTRADA = 1,
  PENDENTE = 2,
  ATUALIZADA = 3
}

export enum DominioIdPeriodo {
  Matutino = 28,
  Vespertino = 29
}

export enum DominioIdPeriodoTodos {
  Todos = 0,
  Matutino = 28,
  Vespertino = 29
}

export enum AgendamentoSituacao {
  INICIADO = 30,
  AGENDADO = 31,
  FINALIZADO = 32,
  CANCELADO = 33
}

export enum DominioIdFuncao {
  Professor = 34,
  Coordenador = 35,
  Monitor_de_sala = 36,
  Outro = 37,
  Profissional_de_Apoio = 38
}

export enum AlunoReponsavelSituacao {
  Cadastrado = 39,
  Presente = 40,
  Ausente = 41
}

export enum DominioIdEscolaTipo {
  Municipal = 4,
  Estadual = 5,
  Privada	 = 6,
  Especial = 7
}

export enum DominioIDModeloTermoSituacao {
  EM_ABERTO = 8,
  AGUARDANDO_APROVACAO = 9,
  APROVADA = 10,
  REPROVADA = 11
}
