<div class="jumbotron" style="text-align: center;">
    <h3>Checklist da Turma</h3>
</div>
<app-turma-checklist [idAgendamento]="agendamentoId" [disableAll]="false"></app-turma-checklist>
<button class="btn btn-light" type="button" routerLink="/turma">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path fill-rule="evenodd"
          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
    </svg>
    Voltar
  </button>