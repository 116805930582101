import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModeloDiagnosticoAlternativa } from '../models/modelo-diagnostico-alternativa';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
  providedIn: 'root'
})
export class ModeloDiagnosticoAlternativaService {

  private readonly BASE_URL = `${environment.apiBaseUrl}/modelo-diagnostico-variavel-alternativa`;
  
  constructor(private http: HttpClient) { }

  find(idQuestao: number) : Observable<ModeloDiagnosticoAlternativa[]> {   
    return this.http.get<ModeloDiagnosticoAlternativa[]>(`${this.BASE_URL}/by-mod-diag-variavel-id/${idQuestao}`);
  }

   findById(id:number) : Observable<ModeloDiagnosticoAlternativa>{
    return this.http.get<ModeloDiagnosticoAlternativa>(`${this.BASE_URL}/${id}`);
  }

  save(modeloAlternativa: ModeloDiagnosticoAlternativa) : Observable<ModeloDiagnosticoAlternativa>{
    console.log(modeloAlternativa)
    return this.http.post<ModeloDiagnosticoAlternativa>(this.BASE_URL, modeloAlternativa);
  }

  update(id:number, modeloAlternativa: ModeloDiagnosticoAlternativa) : Observable<any>{
    return this.http.put(`${this.BASE_URL}/${id}`, modeloAlternativa);
  }

  remove(id: number) : Observable<any> {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }
}
