<div class="jumbotron" style="text-align: center;">
    <h3>Modelo Termo de Compromisso</h3>
</div>

<ngb-tabset [destroyOnHide]="false">
    <ngb-tab title="Dados Modelo">
        <ng-template ngbTabContent>
            <form [formGroup]="formModeloTermoDadosCreate" (ngSubmit)="submit()">
                <fieldset class="form-group border p-2">
                    <div class="form-row align-items-center">
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="numero" class="col-md-2 col-form-label">Número</label>
                                <div class="col-md-3">
                                    <input type="number" class="form-control col-md-9" id="numero"
                                        formControlName="numero">
                                </div>
                                <label for="data" class="col-md-2 col-form-label">Data envio aprovação</label>
                                <div class="col-md-3">
                                    <input type="date" class="form-control col-md-9" formControlName="data"
                                        *ngIf="data.value" />
                                    <input type="text" class="form-control col-md-9" *ngIf="!data.value" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row align-items-center">
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="descricao" class="col-md-2 col-form-label">Descrição *</label>
                                <div class="col-md-9">
                                    <input type="text" class="form-control col-md-12" id="descricao" maxlength="200"
                                        formControlName="descricao">
                                    <div
                                        *ngIf="formCreateControls.descricao.invalid && (formCreateControls.descricao.dirty || formCreateControls.descricao.touched)">
                                        <small *ngIf="formCreateControls.descricao.errors?.required"
                                            class="text-danger">
                                            {{messageError.campoObrigatorio}}</small>
                                        <small *ngIf="formCreateControls.descricao.errors?.maxlength"
                                            class="text-danger">
                                            {{messageError.customCampoCaracteres(200)}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row align-items-center" *ngIf="this.situacao=='REPROVADA'">
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="motivoReprovacao" class="col-md-2 col-form-label">Motivo Reprovação</label>
                                <div class="col-md-9">
                                    <textarea class="form-control col-md-12" id="motivoReprovacao" 
                                              formControlName="motivoReprovacao" style="height: 100px; width: 700px;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="button" class="btn btn-warning ml-2 float-right" (click)="limparForm()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path
                                    d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>
                            Limpar
                        </button>
                        <button type="submit" [disabled]="!formModeloTermoDadosCreate.valid" *ngIf="!id" onclick="this.blur()"
                            class="btn btn-success ml-1 float-right">
                            <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Incluir
                        </button>
                        <button type="submit" [disabled]="!formModeloTermoDadosCreate.valid" *ngIf="id" onclick="this.blur()"
                            class="btn btn-success ml-2 float-right">
                            <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
                            </svg>
                            Salvar
                        </button>
                        <button type="button" class="btn btn-info float-right" (click)="enviarParaAprovacao()"
                            *ngIf="this.id && this.situacao=='EM_ABERTO'">
                            <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-arrow-bar-up"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                            </svg>
                            Enviar para Aprovação
                        </button>
                    </div>
                </fieldset>

            </form>

            <app-modelo-termo-list #modeloTermoList (fnAlterar)="carregarParaAlterar($event)" (fnVisualizar)="carregarParaVisualizar($event)">
            </app-modelo-termo-list>

        </ng-template>
    </ngb-tab>
    <ngb-tab title="Modelo Texto" [disabled]="!id">
        <ng-template ngbTabContent>
            <app-modelo-termo-texto [idModeloTermo]="id"></app-modelo-termo-texto>
        </ng-template>
    </ngb-tab>
</ngb-tabset>
