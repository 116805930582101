import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DiagnosticoEscolaService } from 'src/app/services/diagnostico-escola.service';
import { DiagnosticoEscola } from 'src/app/models/diagnostico-escola'
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
    selector: 'app-diagnostico-historico-form',
    templateUrl: './diagnostico-historico-form.component.html',
    styleUrls: ['./diagnostico-historico-form.component.css']
})
export class DiagnosticoHistoricoFormComponent implements OnInit {

    @Input()
    idEscola: number;

    formHistory: FormGroup;
    page: number;
    pageSize: number = 10;
    totalSize: number;
    listDiagnostico: DiagnosticoEscola[];

    idShow: number;

    constructor(private diagnosticoEscolaService: DiagnosticoEscolaService,
        private modalService: ModalUiService,
        private fb: FormBuilder,
        public utilService: UtilityService) { }

    ngOnInit(): void {
        this.listDiagnostico = new Array();
        this.formHistory = this.fb.group({
            numero: ['', Validators.min(1)],
            data: ['']
        });
        this.page = 1;
        this.totalSize = 0;

        this.buscar();
    }

    buscar() {

        const formData = this.formHistory.value;
        formData.idEscola = this.idEscola;

        this.diagnosticoEscolaService.findHistory(formData, this.page - 1, this.pageSize).subscribe(response => {
            this.listDiagnostico = response.list;
            this.totalSize = response.totalSize;
            console.log(this.listDiagnostico)
        }, error => {
            if (error.status == 0) {
                this.modalService.open({
                    type: EnumModalType.ERROR,
                    message: 'Ocorreu um erro ao listar o modelo diagnóstico',
                    buttons: [EnumModalButtons.OK]
                });
            }
            this.listDiagnostico = new Array();
        });
    }

    showHistory(id: number) {
        if (this.idShow == id)
            this.idShow = null;
        else
            this.idShow = id;
    }
}
