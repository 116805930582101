<form [formGroup]="formModeloDiagnosticoQuestoes" (ngSubmit)="submit()">
    <fieldset class="form-group border p-2">
        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="ordem" class="col-md-4 col-form-label">Ordem *</label>
                    <div class="col-md-3">
                        <input type="text" class="form-control col-md-9" formControlName="ordem" id="ordem">
                        <div
                            *ngIf="questoesForm.ordem.invalid && (questoesForm.ordem.dirty || questoesForm.ordem.touched)">
                            <small *ngIf="questoesForm.ordem.errors?.required" class="text-danger">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="questoesForm.ordem.errors?.maxlength" class="text-danger">
                                {{messageError.customCampoCaracteres(10)}}</small>
                        </div>
                    </div>
                    <label for="tipoAlternativa" class="col-md-2 col-form-label">Tipo Alternativa *</label>
                    
                    <div class="col-md-3">
                        
                        <select id="tipoAlternativa" class="custom-select col-md-9"
                            formControlName="dominioIdTipoAlternativa" (change)="changeTipoAlternativa()">                        
                            <option value="" selected>--</option>
                            <option *ngFor="let dominio of listDominioTipoAlternativa" value="{{dominio.key}}">
                                {{dominio.AliasValue}}</option>                                                      
                        </select>
                        
                        <div
                            *ngIf="questoesForm.dominioIdTipoAlternativa.invalid && (questoesForm.dominioIdTipoAlternativa.dirty || questoesForm.dominioIdTipoAlternativa.touched)">
                            <small *ngIf="questoesForm.dominioIdTipoAlternativa.errors?.required" class="text-danger">
                                {{messageError.campoObrigatorio}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="questao" class="col-md-4 col-form-label">Questão *</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control col-md-11" id="questao" maxlength="200"
                            formControlName="questao">
                        <div
                            *ngIf="questoesForm.questao.invalid && (questoesForm.questao.dirty || questoesForm.questao.touched)">
                            <small *ngIf="questoesForm.questao.errors?.required" class="text-danger">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="questoesForm.questao.errors?.maxlength" class="text-danger">
                                {{messageError.customCampoCaracteres(200)}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row align-items-center">
            <div class="col-12">
                <div class="form-group row">
                    <label for="quantidadeAlternativaCorreta" class="col-md-4 col-form-label">
                        Quantidade Máxima Alternativa(s) Correta(s)
                    </label>
                    <div class="col-md-3">
                        <input type="number" class="form-control col-md-9"
                            formControlName="quantidadeAlternativaCorreta" id="quantidadeAlternativaCorreta">
                        <div
                            *ngIf="questoesForm.quantidadeAlternativaCorreta.invalid && (questoesForm.quantidadeAlternativaCorreta.dirty || questoesForm.quantidadeAlternativaCorreta.touched)">
                            <small *ngIf="questoesForm.quantidadeAlternativaCorreta.errors?.required" class="text-danger">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="questoesForm.quantidadeAlternativaCorreta.errors?.min || questoesForm.quantidadeAlternativaCorreta.errors?.pattern" class="text-danger">
                                {{messageError.campoInvalido}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
    <div class="form-row">
        <div class="col-12">

            <button type="submit" [disabled]="!formModeloDiagnosticoQuestoes.valid" onclick="this.blur()"
                class="btn btn-success ml-2 float-right">
                <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
                </svg>
                Salvar Questão
            </button>
            <button type="button" class="btn btn-warning ml-2 float-right" (click)="limparForm()" *ngIf="this.id">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                    <path
                        d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
                Limpar
            </button>
        </div>
    </div>
</form>

<form [formGroup]="formModeloDiagnosticoVariavelDadosFind" (ngSubmit)="buscar()">
    <div class="row mt-3">
        <div class="col-md-12">
            <table class="table table-condensed table-hover">
                <thead>
                    <tr>
                        <th style="width: 10%;">
                            Ordem
                            <input type="text" class="form-control col-md-12" maxlength="10" formControlName="ordem">
                        </th>
                        <th>
                            Questão
                            <input type="text" class="form-control col-md-12" maxlength="200" formControlName="questao">
                        </th>
                        <th style="width: 6%;">
                            <button type="submit" class="btn btn-outline-secondary" onclick="this.blur()"
                                [disabled]="!formModeloDiagnosticoVariavelDadosFind.valid">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                    <path fill-rule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                </svg>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let questao of listQuestao">
                        <td>{{questao.ordem}}</td>
                        <td>{{questao.questao}}</td>
                        <td>
                            <div class="dropdown">
                                <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                    id="dropdownMenuActions" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuActions">
                                    <button type="button" class="btn btn-outline-secondary dropdown-item" (click)="abrirAlternativas(questao.id,questao.questao, questao.ordem)" [disabled]="questao.dominioIdTipoAlternativa.id == questaoObjetiva">
                                        <svg width="1.2em" height="1.2em" viewBox="0 0 16 16" class="bi bi-plus"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                        Adicionar alternativa
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button type="button" class="btn btn-outline-secondary dropdown-item"
                                        (click)="carregarParaAlterar(questao.id)">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z">
                                            </path>
                                        </svg>
                                        Editar
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button type="button" class="btn btn-outline-secondary dropdown-item"
                                        (click)="remover(questao.id)">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                        Remover
                                    </button>
                                </div>
                            </div>

                        </td>
                    </tr>
                    <tr *ngIf="listQuestao.length == 0">
                        <td colspan="3" align="center">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                </tbody>
            </table>
            <app-pagination *ngIf="listQuestao.length != 0" [totalSize]="totalSize" [(page)]="page"
                [pageSize]="pageSize" (pageChange)="buscar()"></app-pagination>
        </div>
    </div>
</form>