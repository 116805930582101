<div class="jumbotron" style="text-align: center;">
  <h3>Cadastrar Calendário</h3>
</div>
<div class="jumbotron" style="text-align: left;">
  <span>ATENÇÃO! As datas que não forem incluídas na listagem abaixo serão consideradas 'Não Disponíveis'</span>
</div>

<ngb-tabset #tabset="ngbTabset" [destroyOnHide]="false" [activeId]="tabAtiva" (tabChange)="onSelectTab($event.activeId)">
  <ngb-tab title="Calendário" id="calendario">
    <ng-template ngbTabContent>
      <app-calendario-tab-calendario (setAgendaMes)="setAgendaMes($event)"></app-calendario-tab-calendario>
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Datas" id="datas" [disabled]="!agendaMes">
    <ng-template ngbTabContent>
      <app-calendario-tab-datas [agendaId]="agendaMes?.id" [mes]="agendaMes?.data"></app-calendario-tab-datas>
    </ng-template>
  </ngb-tab>
</ngb-tabset>

