<div *ngIf="idModeloDiagnostico == null" class="alert alert-light text-center">
    <fieldset class="form-group border p-2">
        <fieldset class="form-group border p-2">
            <span>Não existe modelo de diagnóstico cadastrado</span>
        </fieldset>
    </fieldset>
</div>
<div *ngIf="idModeloDiagnostico != null">
    <form [formGroup]="formularioDiagnostico" (ngSubmit)="onSubmit()">
        <fieldset class="form-group border p-2">
            <fieldset class="form-group border p-2">
                <legend class="w-auto">Direção, coordenação pedagógica e corpo docente</legend>
                <div class="form-group row col-md-12">

                    <div class="form-group row col-md-6">
                        <label for="" class="col-form-label col-md-3">Diretor(a)</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="diretorNome">
                        </div>
                    </div>

                    <div class="form-group row col-md-4">
                        <label for="" class="col-form-label">Celular</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="diretorCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.diretorCelular = false" (focus)="focus.diretorCelular = true" >
                        </div>
                    </div>
                </div>
                <div class="form-group row col-md-12">

                    <div class="form-group row col-md-6">
                        <label for="" class="col-form-label col-md-3">Diretor(a) adjunto</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control col-md-12" formControlName="adjuntoNome">
                        </div>
                    </div>

                    <div class="form-group row col-md-4">
                        <label for="" class="col-form-label">Celular</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="adjuntoCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.adjuntoCelular = false" (focus)="focus.adjuntoCelular = true">
                        </div>
                    </div>
                </div>
                <div class="form-group row col-md-12">
                    <div class="form-group row col-md-6">
                        <label for="" class="col-form-label col-md-3">Supervisor(a)</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="supervisorNome">
                        </div>
                    </div>

                    <div class="form-group row col-md-4">
                        <label for="" class="col-form-label">Celular</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="supervisorCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.supervisorCelular = false" (focus)="focus.supervisorCelular = true">
                        </div>
                    </div>
                </div>
                <div class="form-group row col-md-12">

                    <div class="form-group row col-md-6">
                        <label for="" class="col-form-label col-md-3">Coordenador(a)*</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="coordenadorNome">
                            <div class="text-danger"
                                *ngIf="diagnostico.coordenadorNome.invalid && (diagnostico.coordenadorNome.dirty || diagnostico.coordenadorNome.touched)">
                                <small *ngIf="diagnostico.coordenadorNome.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row col-md-4">
                        <label for="" class="col-form-label">Celular*</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="coordenadorCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.coordenadorCelular = false" (focus)="focus.coordenadorCelular = true">
                            <div class="text-danger"
                                *ngIf="diagnostico.coordenadorCelular.invalid && (diagnostico.coordenadorCelular.dirty || diagnostico.coordenadorCelular.touched)">
                                <small *ngIf="diagnostico.coordenadorCelular.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                    <small *ngIf="diagnostico.coordenadorCelular.errors.celular && !focus.coordenadorCelular">
                                        {{messageError.celularInvalido}}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row col-md-12">

                    <div class="form-group row col-md-6">
                        <label for="" class="col-form-label col-md-3">Orientador(a)</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="orientadorNome">
                        </div>
                    </div>

                    <div class="form-group row col-md-4">
                        <label for="" class="col-form-label">Celular</label>
                        <div class="col-md-9">
                            <input type="text" class="form-control" formControlName="orientadorCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.orientadorCelular = false" (focus)="focus.orientadorCelular = true">
                        </div>
                    </div>
                </div>
                <div class="form-group row col-md-12">
                    <label for="" class="col-form-label">Quantidade de professores*</label>
                    <div class="col-md-6">
                        <input type="number" class="form-control col-md-3 d-inline-block" formControlName="quantidadeProfessores">
                        <label for="" class="form-label col-md-6">do 1º ao 5º ano</label>
                        <div class="text-danger"
                            *ngIf="diagnostico.quantidadeProfessores.invalid && (diagnostico.quantidadeProfessores.dirty || diagnostico.quantidadeProfessores.touched)">
                            <small *ngIf="diagnostico.quantidadeProfessores.errors.required">
                                {{messageError.campoObrigatorio}}</small>
                            <small *ngIf="diagnostico.quantidadeProfessores.errors?.min || diagnostico.quantidadeProfessores.errors?.pattern" class="text-danger">
                                {{messageError.campoInvalido}}</small>
                        </div>
                    </div>
                </div>

            </fieldset>
            <fieldset class="form-group border p-2">
                <legend class="w-auto">Tipologia da escola - grau de ensino e funcionamento - 1º ao 5º</legend>
                <fieldset class="form-group border p-2">
                    <legend class="w-auto">Matutino</legend>
                    <div class="form-group row">

                        <div class="col-md-3">
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">1º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">2º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">3º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">4º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">5º ano</label>
                        </div>

                    </div>
                    <div class="form-group row">

                        <div class="col-md-3">Quantidade de salas*</div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas1Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas1Matutino.invalid && (diagnostico.quantidadeSalas1Matutino.dirty || diagnostico.quantidadeSalas1Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas1Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas1Matutino.errors?.min || diagnostico.quantidadeSalas1Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas2Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas2Matutino.invalid && (diagnostico.quantidadeSalas2Matutino.dirty || diagnostico.quantidadeSalas2Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas2Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas2Matutino.errors?.min || diagnostico.quantidadeSalas2Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas3Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas3Matutino.invalid && (diagnostico.quantidadeSalas3Matutino.dirty || diagnostico.quantidadeSalas3Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas3Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas3Matutino.errors?.min || diagnostico.quantidadeSalas3Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas4Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas4Matutino.invalid && (diagnostico.quantidadeSalas4Matutino.dirty || diagnostico.quantidadeSalas4Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas4Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas4Matutino.errors?.min || diagnostico.quantidadeSalas4Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas5Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas5Matutino.invalid && (diagnostico.quantidadeSalas5Matutino.dirty || diagnostico.quantidadeSalas5Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas5Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas5Matutino.errors?.min || diagnostico.quantidadeSalas5Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>

                    </div>
                    <div class="form-group row">

                        <div class="col-md-3">Quantidade de alunos*</div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos1Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos1Matutino.invalid && (diagnostico.quantidadeAlunos1Matutino.dirty || diagnostico.quantidadeAlunos1Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos1Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos1Matutino.errors?.min || diagnostico.quantidadeAlunos1Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos2Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos2Matutino.invalid && (diagnostico.quantidadeAlunos2Matutino.dirty || diagnostico.quantidadeAlunos2Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos2Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos2Matutino.errors?.min || diagnostico.quantidadeAlunos2Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos3Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos3Matutino.invalid && (diagnostico.quantidadeAlunos3Matutino.dirty || diagnostico.quantidadeAlunos3Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos3Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos3Matutino.errors?.min || diagnostico.quantidadeAlunos3Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos4Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos4Matutino.invalid && (diagnostico.quantidadeAlunos4Matutino.dirty || diagnostico.quantidadeAlunos4Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos4Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos4Matutino.errors?.min || diagnostico.quantidadeAlunos4Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos5Matutino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos5Matutino.invalid && (diagnostico.quantidadeAlunos5Matutino.dirty || diagnostico.quantidadeAlunos5Matutino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos5Matutino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos5Matutino.errors?.min || diagnostico.quantidadeAlunos5Matutino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="form-group border p-2">
                    <legend class="w-auto">Vespertino</legend>
                    <div class="form-group row">

                        <div class="col-md-3">
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">1º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">2º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">3º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">4º ano</label>
                        </div>
                        <div class="col">
                            <label for="" class="col-form-label">5º ano</label>
                        </div>

                    </div>
                    <div class="form-group row">

                        <div class="col-md-3">Quantidade de salas*</div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas1Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas1Vespertino.invalid && (diagnostico.quantidadeSalas1Vespertino.dirty || diagnostico.quantidadeSalas1Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas1Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas1Vespertino.errors?.min || diagnostico.quantidadeSalas1Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas2Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas2Vespertino.invalid && (diagnostico.quantidadeSalas2Vespertino.dirty || diagnostico.quantidadeSalas2Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas2Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas2Vespertino.errors?.min || diagnostico.quantidadeSalas2Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas3Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas3Vespertino.invalid && (diagnostico.quantidadeSalas3Vespertino.dirty || diagnostico.quantidadeSalas3Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas3Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas3Vespertino.errors?.min || diagnostico.quantidadeSalas3Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas4Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas4Vespertino.invalid && (diagnostico.quantidadeSalas4Vespertino.dirty || diagnostico.quantidadeSalas4Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas4Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas4Vespertino.errors?.min || diagnostico.quantidadeSalas4Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeSalas5Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeSalas5Vespertino.invalid && (diagnostico.quantidadeSalas5Vespertino.dirty || diagnostico.quantidadeSalas5Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeSalas5Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeSalas5Vespertino.errors?.min || diagnostico.quantidadeSalas5Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>

                    </div>
                    <div class="form-group row">

                        <div class="col-md-3">Quantidade de alunos*</div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos1Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos1Vespertino.invalid && (diagnostico.quantidadeAlunos1Vespertino.dirty || diagnostico.quantidadeAlunos1Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos1Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos1Vespertino.errors?.min || diagnostico.quantidadeAlunos1Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos2Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos2Vespertino.invalid && (diagnostico.quantidadeAlunos2Vespertino.dirty || diagnostico.quantidadeAlunos2Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos2Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos2Vespertino.errors?.min || diagnostico.quantidadeAlunos2Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos3Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos3Vespertino.invalid && (diagnostico.quantidadeAlunos3Vespertino.dirty || diagnostico.quantidadeAlunos3Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos3Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos3Vespertino.errors?.min || diagnostico.quantidadeAlunos3Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos4Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos4Vespertino.invalid && (diagnostico.quantidadeAlunos4Vespertino.dirty || diagnostico.quantidadeAlunos4Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos4Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos4Vespertino.errors?.min || diagnostico.quantidadeAlunos4Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                        <div class="col">
                            <input type="number" class="form-control" formControlName="quantidadeAlunos5Vespertino">
                            <div class="text-danger"
                                *ngIf="diagnostico.quantidadeAlunos5Vespertino.invalid && (diagnostico.quantidadeAlunos5Vespertino.dirty || diagnostico.quantidadeAlunos5Vespertino.touched)">
                                <small *ngIf="diagnostico.quantidadeAlunos5Vespertino.errors.required">
                                    {{messageError.campoObrigatorio}}</small>
                                <small *ngIf="diagnostico.quantidadeAlunos5Vespertino.errors?.min || diagnostico.quantidadeAlunos5Vespertino.errors?.pattern" class="text-danger">
                                    {{messageError.campoInvalido}}</small>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </fieldset>
            <fieldset class="form-group border p-2">
                <legend class="w-auto">Contexto e realidade da escola</legend>
                <app-questionario-diagnostico-form-component [idModeloDiagnostico]="idModeloDiagnostico" [respostas]="respostas" [disableAll]="respostas != null || idEscola == null">
                </app-questionario-diagnostico-form-component>
            </fieldset>
        </fieldset>
        <div class="col-12 mb-5" *ngIf="idEscola">

            <button type="submit" class="btn btn-success float-right" [disabled]="id" onclick="this.blur()">
                <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
                </svg>
                Salvar
            </button>
            <button type="button" class="btn btn-outline-secondary" routerLink="/escola/list">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fill-rule="evenodd"
                        d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                </svg>
                Voltar
            </button>

        </div>
    </form>
</div>
