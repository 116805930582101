<form [formGroup]="formQuestionario">
    <div *ngFor="let questao of questionario" class="mb-2">
        <div class="form-group row pl-4">
            <label for="" class="form-label">
                {{questao.ordem + ') ' + questao.questao}}
            </label>
            <div class="col-md-12" *ngIf="questao.idDominioTipoAlternativa == dominioTipoAlternativa.OBJETIVA">
                <input type="text" class="form-control col-md-12" maxlength="200"
                    [formControlName]="'control_'+questao.idQuestao">
                <div
                    *ngIf="formQuestionario.get('control_'+questao.idQuestao).invalid && (formQuestionario.get('control_'+questao.idQuestao).dirty || formQuestionario.get('control_'+questao.idQuestao).touched)">
                    <small *ngIf="formQuestionario.get('control_'+questao.idQuestao).errors?.required"
                        class="text-danger">
                        {{messageError.campoObrigatorio}}</small>
                    <small *ngIf="formQuestionario.get('control_'+questao.idQuestao).errors?.maxlength"
                        class="text-danger">
                        {{messageError.customCampoCaracteres(200)}}</small>
                </div>
            </div>

            <div class="col-md-12" *ngIf="questao.idDominioTipoAlternativa == dominioTipoAlternativa.MULTIPLA_ESCOLHA">
                <div class="form-check" *ngFor="let alternativa of questao.alternativas; let i=index">
                    <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" [value]="alternativa.idAlternativa" [checked]="check('control_'+questao.idQuestao, alternativa.idAlternativa)"
                            (change)="onCheckChange('control_'+questao.idQuestao, $event)" [disabled]="disableAll">
                        {{alternativa.ordem + ') ' + alternativa.alternativa}}
                    </label>
                </div>
                <div
                    *ngIf="formQuestionario.get('control_'+questao.idQuestao).invalid && (formQuestionario.get('control_'+questao.idQuestao).dirty || formQuestionario.get('control_'+questao.idQuestao).touched)">
                    <small *ngIf="formQuestionario.get('control_'+questao.idQuestao).errors?.required"
                        class="text-danger">
                        {{messageError.campoObrigatorio}}
                    </small>
                    <small *ngIf="formQuestionario.get('control_'+questao.idQuestao).errors?.maximumError" class="text-danger">
                        Escolher no máximo {{formQuestionario.get('control_'+questao.idQuestao).errors?.maximum}} alternativas
                    </small>
                </div>
            </div>

            <div class="col-md-12"
                *ngIf="questao.idDominioTipoAlternativa == dominioTipoAlternativa.VERDADEIRO_OU_FALSO">
                <div class="custom-control custom-radio" *ngFor="let alternativa of questao.alternativas">

                    <input [formControlName]="'control_'+questao.idQuestao" type="radio" class="custom-control-input"
                        [value]="alternativa.idAlternativa" id="radio_{{questao.idQuestao}}_alternativa_{{alternativa.idAlternativa}}">
                        <label class="custom-control-label"
                        for="radio_{{questao.idQuestao}}_alternativa_{{alternativa.idAlternativa}}">
                        {{alternativa.ordem + ') ' + alternativa.alternativa}}
                    </label>
                </div>
                <div
                    *ngIf="formQuestionario.get('control_'+questao.idQuestao).invalid && (formQuestionario.get('control_'+questao.idQuestao).dirty || formQuestionario.get('control_'+questao.idQuestao).touched)">
                    <small *ngIf="formQuestionario.get('control_'+questao.idQuestao).errors?.required"
                        class="text-danger">
                        {{messageError.campoObrigatorio}}
                    </small>
                </div>
            </div>
        </div>

    </div>
</form>

<div *ngIf="erroAoBuscarCalendario" class="alert alert-light text-center">
    <span>{{erroAoBuscarCalendario}}</span>
</div>