import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloTermoCompromissoService } from 'src/app/services/modelo-termo.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { MessageError } from 'src/app/utils/message-error';
import { ModeloTermoListComponent } from '../modelo-termo-list/modelo-termo-list.component';
import { ModeloTermoTextoComponent } from '../modelo-termo-texto/modelo-termo-texto.component';

@Component({
  selector: 'app-modelo-termo-form',
  templateUrl: './modelo-termo-form.component.html',
  styleUrls: ['./modelo-termo-form.component.css']
})
export class ModeloTermoFormComponent implements OnInit {
    
  formModeloTermoDadosCreate: FormGroup;
  id: number;
  submitted: boolean = false;
  messageError: MessageError;
  situacao: string;
  
  @ViewChild(ModeloTermoListComponent) 
  private childModeloTermoListComponent: ModeloTermoListComponent;

  @ViewChild(ModeloTermoTextoComponent) 
  private childModeloTermoTextoComponent: ModeloTermoTextoComponent;
    
 constructor (
  private fb: FormBuilder,
  public util: UtilityService,
  private modeloTermoService: ModeloTermoCompromissoService,
  private router: Router,
  private activateRoute: ActivatedRoute,
  private modalService: ModalUiService
 ) {
 }
 
  ngOnInit(): void {

    this.formModeloTermoDadosCreate = this.fb.group({
      numero: new FormControl({ value: '', disabled: true }),
      data: new FormControl({ value: '', disabled: true }),
      descricao: new FormControl('', [Validators.maxLength(200), Validators.required]),
      motivoReprovacao: new FormControl({ value: '', disabled: true })
    });

    this.messageError = new MessageError();
  }

  submit() {
      
    this.formModeloTermoDadosCreate.value.usuario = this.util.getDtnUserWrapper().getNomeUsuario();

    if (this.id) {
      this.modeloTermoService.update(this.id, this.formModeloTermoDadosCreate.value)
        .subscribe(response => {
          this.tratarMensagemSucesso('alterado', this.id);
        },
          errorResponse => {
            this.tratarMensagemErro('alterar', errorResponse);
          })
    }
    else {
      this.modeloTermoService.save(this.formModeloTermoDadosCreate.value)
        .subscribe(response => {
          if (response) {
            this.tratarMensagemSucesso('cadastrado', response.numero);
          }
        },
          errorResponse => {
            this.tratarMensagemErro('cadastrar', errorResponse);
          })
    }
  }

  carregarParaAlterar(id: number) {
    if (id) {
      this.id = id;
      this.modeloTermoService.findByDados(id).subscribe(response => {
        this.formModeloTermoDadosCreate.patchValue({
          numero: id,
          data: response.dataEnvioAprovacao,
          descricao: response.descricao
        });
        
        this.buscarDadosChild(id);
        this.buscarDadosChildTexto(id, false);
        
        this.formModeloTermoDadosCreate.get('descricao').enable();
        
        var modelo = this.childModeloTermoListComponent.listMd.filter(x=> x.id == id).pop();
        
        this.situacao = modelo.dominioSituacao.descricao;
      }, errorResponse => {
        this.tratarMensagemErro('buscar', errorResponse);
        this.limparForm();
      })
    }
  }
  
  carregarParaVisualizar(id: number) {
    if (id) {
      this.id = id;
      this.modeloTermoService.findByDados(id).subscribe(response => {
        this.formModeloTermoDadosCreate.patchValue({
          numero: id,
          data: response.dataEnvioAprovacao,
          descricao: response.descricao,
          motivoReprovacao: response.motivoReprovacao
        });
        
        this.buscarDadosChild(id);
        this.buscarDadosChildTexto(id, true);
        
        this.formModeloTermoDadosCreate.get('numero').disable();
        this.formModeloTermoDadosCreate.get('data').disable();
        this.formModeloTermoDadosCreate.get('descricao').disable();
        this.formModeloTermoDadosCreate.get('motivoReprovacao').disable();
        
        var modelo = this.childModeloTermoListComponent.listMd.filter(x=> x.id == id).pop();
        
        this.situacao = modelo.dominioSituacao.descricao;
      }, errorResponse => {
        this.tratarMensagemErro('buscar', errorResponse);
        this.limparForm();
      })
    }
  }

  enviarParaAprovacao(): void {
    this.formModeloTermoDadosCreate.value.usuario = this.util.getDtnUserWrapper().getNomeUsuario();
    this.modeloTermoService.findById(this.id).subscribe(response => {
        if(response.infoHorarioSaidaDoOnibus != null && response.infoHorarioSaidaDoOnibus.trim().length > 0 
        && response.infoPeriodoMatutino != null && response.infoPeriodoMatutino.trim().length > 0 
        && response.infoPeriodoVespetino != null && response.infoPeriodoVespetino.trim().length > 0
        && response.infoSeriesAtendidas != null && response.infoSeriesAtendidas.trim().length > 0 
        && response.infoSobreVisita != null && response.infoSobreVisita.trim().length > 0 
        && response.infoTurmas != null && response.infoTurmas.trim().length > 0 
        && response.maioresInformacoes != null && response.maioresInformacoes.trim().length > 0
        && response.observacao != null && response.observacao.trim().length > 0 
        && response.responsabilidadeCidadeEscolaTransito != null && response.responsabilidadeCidadeEscolaTransito.trim().length > 0 
        && response.responsabilidadeEscola != null && response.responsabilidadeEscola.trim().length > 0){
            this.modeloTermoService.sendToApprove(this.id, this.formModeloTermoDadosCreate.value).subscribe(response => {
                this.tratarMensagemSucesso('enviado', this.id)
            },
            errorResponse => {
                this.tratarMensagemErro('enviar para aprovação', errorResponse)
            });
         }else{
                this.tratarMensagemErroPersonalizada('Não foi possivel enviar para aprovação. Campos no modelo texto vazios', this.id)
              }
        })
  }

  limparForm() {
    this.id = null;
    this.situacao = null;
    this.formModeloTermoDadosCreate.patchValue({
      numero: '',
      data: '',
      descricao: '',
      motivoReprovacao: ''
    });
    this.formModeloTermoDadosCreate.get('descricao').enable();
    this.cleanValidatorsError();
    this.buscarDadosChild();
  }

  private tratarMensagemSucesso(acao: string, id: number) {
    this.modalService.open({
      type: EnumModalType.SUCCESS,
      message: `Modelo Termo de Compromisso ${acao} com sucesso`,
      buttons: [EnumModalButtons.OK],
      okEvent: () => {
        this.limparForm();
        this.buscarDadosChild(id);
      }
    });
  }

  private tratarMensagemErro(acao: string, errorResponse: any) {
    switch (errorResponse.status) {
      case 404:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: errorResponse.error.errors.join('. '),
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.buscarDadosChild(); }
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: `Não foi possível ${acao} o modelo termo de compromisso`,
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.buscarDadosChild(); }
        });
        break;
    }
  }
  
    private tratarMensagemErroPersonalizada(acao: string, errorResponse: any) {
    switch (errorResponse.status) {
      case 404:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: errorResponse.error.errors.join('. '),
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.buscarDadosChild(); }
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: acao,
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.buscarDadosChild(); }
        });
        break;
    }
  }

  get data() { return this.formModeloTermoDadosCreate.get('data'); }

  get formCreateControls() { return this.formModeloTermoDadosCreate.controls; }

  private buscarDadosChild(id?: number) {
    this.childModeloTermoListComponent.buscar(id);
  }
  
  private buscarDadosChildTexto(id?: number, desativar?: boolean) {
    this.childModeloTermoTextoComponent.buscar(id, desativar);
  }

  private cleanValidatorsError() {
    this.formModeloTermoDadosCreate.markAsPristine();
    this.formModeloTermoDadosCreate.markAsUntouched();
  }
 
}
