import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CalendarOptions } from "@fullcalendar/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AgendamentoDisponibilidade } from "src/app/models/agendamento-disponibilidade";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { MessageService } from "src/app/services/message.service";
import { CalendarComponent } from "src/app/template/calendar/calendar.component";
import { EventDTO } from "src/app/utils/calendar-event.dto";
import { DominioIdEventoCalendario } from "src/app/utils/dominio-enum";
import { EventDefaultColorEnum } from "src/app/utils/event-default-color.enum";
import { AgendamentoFormComponent } from "../agendamento-form/agendamento-form.component";


@Component({
    selector: 'app-agendamento-calendario',
    templateUrl: './agendamento-calendario.component.html',
    styleUrls: ['./agendamento-calendario.component.css']
})
export class AgendamentoCalendarioComponent implements OnInit, AfterViewInit {
    canGetAvailability: boolean = false;
    eventColor = EventDefaultColorEnum;
    calendarOptions: CalendarOptions = {
        headerToolbar: { left: '', center: 'title', right: '' },

        selectable: true,
        selectOverlap: false,
        dateClick: this.abrirModalAgendamento.bind(this),
        events: this.carregaEventos.bind(this)
    };

    eventos: AgendamentoDisponibilidade[];
    escolaId: number;
    contador: number = 0;
    @ViewChild(CalendarComponent)
    private calendar: CalendarComponent;

    constructor(private ngbModalService: NgbModal,
        private agendamentoService: AgendamentoService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit(): void {
        this.escolaId = +this.route.snapshot.params['escolaId'];
    }

    ngAfterViewInit(): void {
        this.canGetAvailability = true;
        let now = new Date();
        now.setMonth(now.getMonth());
        if(now.getUTCDate() == 31){
            now.setMonth(now.getMonth() + 1);
            now.setDate(now.getDate() - 1)
            }
        this.calendar.calendarApi.gotoDate(now);
    }

    abrirModalAgendamento(info) {
        let evento = this.eventos ? this.eventos.find(e => e.data == info.dateStr) : null;
        if (this.podeAgendar(evento)) {
            const modal = this.ngbModalService.open(AgendamentoFormComponent, { backdrop: 'static' });
            modal.componentInstance.evento = evento;
            modal.componentInstance.escolaId = this.escolaId;
        }
    }

    carregaEventos(fetchinfo, successCallback, failureCallback) {
        if (this.canGetAvailability) {
            this.agendamentoService.availability()
                .subscribe(data => {
                    this.eventos = data;
                    successCallback(data.map(evento => {
                       if(evento.periodo.length == 1){
                           if(evento.periodo[0] == 29){
                                evento.periodo[0] = 28
                               }else{
                                    evento.periodo[0] = 29
                                }
                        let novoEvento: EventDTO = {
                            id: evento.agendaDataId,
                            start: evento.data,
                            display: 'background',
                            color: this.corDoEvento(evento.periodo[0])
                        };
                        return novoEvento;
                       }else{
                           let novoEvento: EventDTO = {
                            id: evento.agendaDataId,
                            start: evento.data,
                            display: 'background',
                            color: this.corDoEvento(evento.dominioIdCalendarioEvento)
                        };
                            return novoEvento;
                           }
                    }))
                }, err => {
                    this.messageService.tratarMensagemErro(err, true, err, () => {
                        this.router.navigate(['/agendamento']);
                    });
                    failureCallback(err);
                });
        }
    }

    podeAgendar(evento: AgendamentoDisponibilidade): boolean {
        if (evento) {
            if (evento.dominioIdCalendarioEvento != DominioIdEventoCalendario.DISPONIVEL) {
                this.messageService.aviso("Não é possível efetuar agendamento na data selecionada.");
                return false;
            }

            if (evento.periodo.length == 0) {
                this.messageService.aviso("A data selecionada não possuí mais períodos disponíveis para agendamento.");
                return false;
            }
        }

        return true;
    }


     avancarEvento(){        
        let now = new Date();
         if(now.getUTCDate() == 31){
             now.setDate(now.getDate()  + 1)
            }
        let now2 = new Date();
        let mesV;
        this.contador += 1
        now.setMonth(now.getMonth() + this.contador);   
        if(now.getFullYear() == now2.getFullYear()){
            mesV = now.getMonth() + 1;
            let mesEncontrado = false;
            this.eventos.forEach( e =>{ 
                if(Number(e.data.substring(5, 7)) == mesV){
                    console.log(Number(e.data.substring(5, 7)));
                    mesEncontrado = true;
                    }
         })

          if(mesEncontrado == false){
                let validacao = false
                if(now.getFullYear() == now2.getFullYear()){
                    this.contador += 1
                    const umMesDepois = new Date(now);
                    umMesDepois.setMonth(umMesDepois.getMonth() + 1);



                    console.log("ummes" + umMesDepois)
                    const mesT = umMesDepois.getMonth() + 1
                    console.log("mesT" + mesT)
                    this.eventos.forEach( e =>{
                    if(Number(e.data.substring(5, 7)) == mesT){
                        console.log("Entrei aqui")
                        this.calendar.calendarApi.gotoDate(umMesDepois);
                        validacao = true
                    }})
                      if(validacao == false){
                        this.contador -= 2
                        }

                }
              }else{
                      if(now.getFullYear() == now2.getFullYear()){
                        this.calendar.calendarApi.gotoDate(now);
         }}
        }else{this.contador -= 1}
    }
    
    voltarEvento(){
        let now = new Date();
        let verificacao = this.contador + now.getMonth();
        let mesEncontrado = false;
        let mesV; 
        
        if(verificacao > now.getMonth()){
            this.contador -= 1
            now.setMonth(now.getMonth() + this.contador);
            mesV = now.getMonth() + 1;
            this.eventos.forEach( e =>{ 
            if(Number(e.data.substring(5, 7)) == mesV){
                console.log(Number(e.data.substring(5, 7)))
                mesEncontrado = true;
                }
            })
      
            
         if(mesEncontrado == false){
            this.contador -= 1
            const umMesDepois = new Date(now);
            umMesDepois.setMonth(umMesDepois.getMonth() - 1);
            this.calendar.calendarApi.gotoDate(umMesDepois);
          }else{
                              this.calendar.calendarApi.gotoDate(now);

              }
        }
    }

    corDoEvento(dominioIdEvento: number): string {
        switch (dominioIdEvento) {
            case DominioIdEventoCalendario.NAO_DISPONIVEL:
                return EventDefaultColorEnum.NAO_DISPONIVEL;
            case DominioIdEventoCalendario.AGENDADA:
                return EventDefaultColorEnum.AGENDADA;
            case DominioIdEventoCalendario.DISPONIVEL:
                return EventDefaultColorEnum.DISPONIVEL;
            case DominioIdEventoCalendario.FERIADO_RECESSO:
                return EventDefaultColorEnum.FERIADO;
            case DominioIdEventoCalendario.FERIAS_RECESSO:
                return EventDefaultColorEnum.FERIAS_RECESSO;
            case DominioIdEventoCalendario.INDISPONIVEL:
                return EventDefaultColorEnum.INDISPONIVEL;
            case DominioIdEventoCalendario.PLANEJAMENTO:
                return EventDefaultColorEnum.PLANEJAMENTO;
            case DominioIdEventoCalendario.REUNIAO:
                return EventDefaultColorEnum.REUNIAO;
            case DominioIdEventoCalendario.MANHA:
                return EventDefaultColorEnum.MANHA;
            case DominioIdEventoCalendario.TARDE:
                return EventDefaultColorEnum.TARDE;
        }

        return '';
    }
}
