export enum EventDefaultColorEnum {
  NAO_DISPONIVEL = '#98999C',
  DISPONIVEL = '#FFFFFF',
  INDISPONIVEL = '#E03C55',
  FERIAS_RECESSO = '#FAB54B',
  AGENDADA = '#428DE0',
  PLANEJAMENTO = '#5EBD6B',
  FERIADO = '#8C2F00',
  REUNIAO = '#B569FA',
  MANHA = '#f4fc03',
  TARDE = '#00AAAA'
}
