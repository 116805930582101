<h1 mat-dialog-title>Motivo da Reprovação</h1>
<div mat-dialog-content>
  <p>Digite o motivo da reprovação</p>
  <mat-form-field>
      <textarea class="form-control" [(ngModel)]="data.motivoReprovacao"
                      style="height: 100px; width: 500px;">
            </textarea>
<!--      <input class="form-control" id="inputMotivo" matInput type="text" [(ngModel)]="data.motivoReprovacao" 
             style="height: 100px; width: 900px;">-->
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" class="btn btn-warning float-left">Cancelar</button>
  <button mat-button [mat-dialog-close]="data.motivoReprovacao" class="btn btn-danger float-right" 
          [disabled]="!data.motivoReprovacao" cdkFocusInitial style="margin-left: 5pt;">Reprovar</button>
</div>