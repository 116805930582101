<div class="jumbotron" style="text-align: center;">
    <h3>Escola</h3>
</div>

<form [formGroup]="formFind" (ngSubmit)="page = 1; submit()">
    <div class="row mt-3">
        <div class="col-md-12">
            <table class="table table-condensed table-hover">
                <thead>
                    <tr>
                        <th style="width: 21%;" class="align-middle">
                            CNPJ
                            <input type="text" class="form-control col-md-12" id="filtroCnpj" [textMask]="{mask: MASKS.cnpj.textMask }" formControlName="cnpj">
                        </th>
                        <th>
                            Nome
                            <input type="text" class="form-control col-md-12" id="filtroNomeEscola" formControlName="nome">
                        </th>
                        <th style="width: 16%;" class="align-middle">
                            Situação
                            <select id="filtroSituacaoEscola" class="custom-select" formControlName="dominioIdSituacao">
                                <option value="" selected>--</option>
                                <option *ngFor="let dominio of listDominioEscolaSituacao" value="{{dominio.key}}">
                                    {{dominio.value}}</option>
                            </select>
                        </th>
                        <th style="width: 6%;">
                            <button type="submit" class="btn btn-outline-secondary" onclick="this.blur()" [disabled]="!formFind.valid">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                                    <path fill-rule="evenodd"
                                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                                </svg>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let esc of listEscola">
                        <td>{{esc.cnpj | cnpj}}</td>
                        <td>{{esc.nome}}</td>
                        
                        <td>{{esc.dominioSituacao.descricao}}</td>
                        <td>
                            <button type="button" class="btn btn-outline-secondary"
                                routerLink="/escola/endereco/{{esc.id}}">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z">
                                    </path>
                                </svg>
                            </button>
                        </td>
                    </tr>
                    <tr *ngIf="listEscola.length == 0">
                        <td colspan="4" align="center">
                            Nenhum registro encontrado.
                        </td>
                    </tr>
                </tbody>
            </table>
            <ngb-pagination class="d-flex justify-content-center" *ngIf="listEscola.length != 0"
                [collectionSize]="totalSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="submit()"
                [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>
                </ng-template>
                <ng-template ngbPaginationNext>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </ng-template>
                <ng-template ngbPaginationFirst>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-bar-left" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
                    </svg>
                </ng-template>
                <ng-template ngbPaginationLast>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-bar-right"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
                    </svg>
                </ng-template>
            </ngb-pagination>
        </div>
    </div>
</form>

<ngb-toast class="bg-danger text-light" *ngIf="showToast" [delay]="5000" [autohide]="true" (hide)="showToast = false"
    [class.ngb-toasts]="true">
    <ng-template ngbToastHeader>
        <strong>Atenção</strong>
    </ng-template>
    <div>
        Erro ao conectar com o servidor
    </div>
</ngb-toast>