import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, of } from "rxjs";
import { Acompanhante } from "src/app/models/acompanhante";
import { Dominio } from "src/app/models/dominio";
import { DominioService } from "src/app/services/dominio.service";
import { MessageService } from "src/app/services/message.service";
import { TurmaService } from "src/app/services/turma.service";
import { UtilityService } from 'src/app/services/utility.service';
import { NgSelectAutocompleteComponent } from "src/app/template/ng-select-autocomplete/ng-select-autocomplete.component";
import { DominioIdFuncao } from "src/app/utils/dominio-enum";
import { TurmaTabEnum } from "src/app/utils/turma-tab.enum";
import { TurmaResponsavelComponent } from "../turma-responsavel/turma-responsavel.component";
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';

@Component({
  selector: 'app-acompanhante-profissional',
  templateUrl: './turma-acompanhante-profissional.component.html',
  styleUrls: ['./turma-acompanhante-profissional.component.css']
})
export class TurmaAcompanhanteProfissionalComponent implements OnInit {
  public tabEnum = TurmaTabEnum;
  formAcompanhante: FormGroup;

  acompanhantes: Acompanhante[];

  totalSize: number;
  pageSize: number;
  page: number;
  texto: string = 'Desativar';
  dtnUserWrapper: DtnAuthUserWrapper;

  @ViewChild('acompanhanteAC')
  acompanhanteAC: NgSelectAutocompleteComponent;
  funcoes$: Dominio[];

  @Input() tabType: TurmaTabEnum;
  @Input() agendamentoId: number;
  @Output() accessNextTab = new EventEmitter<TurmaTabEnum>();


  constructor(private fb: FormBuilder,
    private dominioService: DominioService,
    private turmaService: TurmaService,
    private ngbModal: NgbModal,
    private messageService: MessageService,
    private utils: UtilityService) {
    this.page = 1;
    this.totalSize = 0;
    this.pageSize = 10;
    this.dtnUserWrapper = utils.getDtnUserWrapper();

    this.acompanhantes = new Array<Acompanhante>();
  }

  ngOnInit(): void {
    this.formAcompanhante = this.fb.group({
      acompanhanteId: ['', Validators.required],
      dominioIdFuncao: ['', Validators.required]
    });

    this.funcoes();
    this.search();
  }

  novoAcompanhante() {
    const modal = this.ngbModal.open(TurmaResponsavelComponent, { backdrop: 'static' });
    modal.componentInstance.agendamentoId = this.agendamentoId;
    modal.componentInstance.tabType = this.tabType;
    modal.result.then((result) => {
      if (result?.id) {
        const arrayKeyValue = new Array();
        arrayKeyValue.push({
          key: result.id,
          value: result.nome
        })
        this.acompanhanteAC.hidrate(arrayKeyValue);
        this.formAcompanhante.patchValue({
          acompanhanteId: result.id
        });
      }
    })
  }

  search() {
    this.turmaService.list(this.agendamentoId, this.tabType)
      .subscribe(data => {
        this.acompanhantes = data;
        this.totalSize = data.length;
      }, errorResponse => {
        this.messageService.tratarMensagemErro("Não foi possível recurperar os acompanhantes.", false, errorResponse, () => { });
      });
  }

  insert() {
    if (this.formAcompanhante.valid) {
      this.turmaService.insert(this.agendamentoId,
        this.formAcompanhante.value.acompanhanteId,
        this.formAcompanhante.value.dominioIdFuncao,
        this.utils.getDtnUserWrapper().getNomeUsuario())
        .subscribe(data => {
          this.formAcompanhante.reset();
          this.search();
        }, errorResponse => {
          this.messageService.tratarMensagemErro("Não foi possível adicionar o registro.", false, errorResponse, () => { });
        });
    }
  }

  confirm(acompanhanteId: number, ativoId: number) {
    let menssagem = "";
    
    if(ativoId == 1){
        ativoId = 0
        menssagem = "Desativar"
    }else if(ativoId == 0){
            ativoId = 1
            menssagem = "Ativar"
        }
    this.messageService.confirmacao("Tem certeza que deseja " + menssagem  + " esse acompanhante?",
      () => { this.remove(acompanhanteId, ativoId); }, () => { });
  }

  remove(acompanhanteId: number, ativoId: number) {
    this.turmaService.remove(acompanhanteId, ativoId)
      .subscribe(data => {
      }, err => {
          this.search();
      });
  }

  nextTab() {

    if(this.tabType == TurmaTabEnum.Acompanhante && this.acompanhantes.length < 1){
      this.messageService.aviso('Insira pelo menos um acompanhante');
      return;
    }


    this.accessNextTab.emit(this.tabType);
  }
  backTab() : void {
    this.accessNextTab.emit();
  }
  acompanhanteQuery(nome: string) {
    this.turmaService.listResponsible(nome, this.agendamentoId)
      .subscribe(result => {
        this.acompanhanteAC.hidrate(result);
      }, errorResponse => {
        this.acompanhanteAC.hidrate([]);
      });
  }

  funcoes() {
    this.dominioService.getDominioResponsavelFuncao()
      .subscribe(result => {
        if (this.tabType == TurmaTabEnum.Acompanhante)
          this.funcoes$ = result.filter(d => d.id != DominioIdFuncao.Profissional_de_Apoio);
        else
          this.funcoes$ = result.filter(d => d.id == DominioIdFuncao.Profissional_de_Apoio);
      });
  }

  get turmaTabEnum(){
    return TurmaTabEnum;
  }
}
