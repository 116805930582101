  <form [formGroup]="formAgendamento" (ngSubmit)="confirm()">
    <div class="modal-body">
      <div class="form-row">
        <span class="col-md-12">Selecione o Turno para agendamento:</span>
      </div>
      <div class="form-row form-group">
        <div class="col-md-12">
          <div class="form-check form-check-inline">
              <input type="radio" id="matutino" name="turno" class="form-check-input" [value]="periodoEnum.Vespertino" formControlName="turno"
              [attr.disabled]="turnoIsUnavailable(periodoEnum.Vespertino)" />
              <label class="form-check-label" for="matutino">Matutino</label>
          </div>
          <div class="form-check form-check-inline">
              <input type="radio" id="vespertino" name="turno" class="form-check-input" [value]="periodoEnum.Matutino" formControlName="turno"
              [attr.disabled]="turnoIsUnavailable(periodoEnum.Matutino)" />
              <label class="form-check-label" for="vespertino">Vespertino</label>
          </div>
          <div class="text-danger" *ngIf="form.turno.invalid && form.turno.touched && errorMessage">
            <small *ngIf="form.turno.errors.required">
                {{messageError.campoObrigatorio}}</small>
          </div>
        </div>
      </div>
      <div class="form-row">
        <span class="col-md-12">
          Selecione a(s) Série(s) para agendamento:
        </span>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-check">
            <input type="checkbox" id="ano1" name="ano1" class="form-check-input" formControlName="ano1" />
            <label class="form-check-label" for="ano1">1º ano</label>
          </div>
          <div class="form-check">
            <input type="checkbox" id="ano2" name="ano2" class="form-check-input" formControlName="ano2" />
            <label class="form-check-label" for="ano2">2º ano</label>
          </div>
          <div class="form-check">
            <input type="checkbox" id="ano3" name="ano3" class="form-check-input" formControlName="ano3" />
            <label class="form-check-label" for="ano3">3º ano</label>
          </div>
          <div class="form-check">
            <input type="checkbox" id="ano4" name="ano4" class="form-check-input" formControlName="ano4" />
            <label class="form-check-label" for="ano4">4º ano</label>
          </div>
          <div class="form-check">
            <input type="checkbox" id="ano5" name="ano5" class="form-check-input" formControlName="ano5" />
            <label class="form-check-label" for="ano5">5º ano</label>
          </div>
          <div class="text-danger" *ngIf="formAgendamento.touched && errorMessage">
            <small *ngIf="serieHasError()">{{messageError.campoObrigatorio}}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-row float-right mr-1 mt-2">
        <button id="btnSalvar" class="btn btn-success" type="submit">
          <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
          </svg>
          Salvar
        </button>
      </div>
      <button id="btnVoltar" class="btn btn-light ml-2" type="button" (click)="modalAtivaService.close('Close click')">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                  fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path fill-rule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
        </svg>
        Voltar
      </button>
    </div>
  </form>
