<div class="jumbotron" style="text-align: center;">
  <h3>Turma</h3>
</div>
<div *ngIf="turma">
  <ngb-tabset #tabset="ngbTabset">
    <ngb-tab title="Geral" id="geral">
      <ng-template ngbTabContent>
          <fieldset class="form-group border p-2">
            <div class="form-row mt-2">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Nº Turma:</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" id="numero" [disabled]="true" [value]="turma.numero" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Data Visita:</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control" id="dataVisita" [disabled]="true" [value]="turma.dataVisita" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Período:</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" id="periodo" [disabled]="true" [value]="turma.periodo" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Ano:</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" id="ano" [disabled]="true" [value]="getAnoDescription()" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group row">
                  <label class="col-md-2 col-form-label">Situação Turma:</label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" id="situacao" [disabled]="true" [value]="turma.situacao" />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="CheckList" id="checklist">
      <ng-template ngbTabContent>
        <app-turma-checklist [idAgendamento]="agendamentoId" [disableAll]="true"></app-turma-checklist>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
<div *ngIf="turma" class="row">
  <button id="btnVoltar" class="btn btn-light ml-3" type="button" routerLink="/turma">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path fill-rule="evenodd"
          d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
    </svg>
    Voltar
  </button>
</div>
