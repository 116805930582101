import { Component, ComponentRef, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Dominio } from "src/app/models/dominio";
import { DominioService } from "src/app/services/dominio.service";
import { MessageService } from "src/app/services/message.service";
import { UtilityService } from "src/app/services/utility.service";
import { DynamicComponentDirective } from "src/app/template/dynamic-component/dynamic-component.directive";
import { IDynamicComponentResolver } from "src/app/template/dynamic-component/dynamic-component.interface";
import { AgrupamentoRelatorioEnum } from "src/app/utils/agrupamento-relatorio.enum";
import { DominioIdDescricaoDTO } from "src/app/utils/dominio-id-descricao.dto";
import { KeyValuePair } from "src/app/utils/key-value-pair";
import { MessageError } from "src/app/utils/message-error";

@Component({
    selector: 'app-relatorios',
    templateUrl: './relatorios-form.component.html',
    styleUrls: ['./relatorios-form.component.css']
})
export class RelatoriosFormComponent implements OnInit, IDynamicComponentResolver, AfterViewInit {

    reportComponent: DynamicComponentDirective;

    agrupamentoRelatorioEnum = AgrupamentoRelatorioEnum;
    formFiltro: FormGroup;

    tiposRelatorio: Dominio[];
    periodos: Dominio[];
    tiposEscola: DominioIdDescricaoDTO[];
    messageError: MessageError;

    isSubmitted: boolean = false;

    series: KeyValuePair<number, string>[] = [
        { key: 0, value: 'Todos' },
        { key: 1, value: '1º Ano' },
        { key: 2, value: '2º Ano' },
        { key: 3, value: '3º Ano' },
        { key: 4, value: '4º Ano' },
        { key: 5, value: '5º Ano' }
    ];
    meses: KeyValuePair<number, string>[] = [
        { key: 1, value: 'Janeiro' },
        { key: 2, value: 'Fevereiro' },
        { key: 3, value: 'Março' },
        { key: 4, value: 'Abril' },
        { key: 5, value: 'Maio' },
        { key: 6, value: 'Junho' },
        { key: 7, value: 'Julho' },
        { key: 8, value: 'Agosto' },
        { key: 9, value: 'Setembro' },
        { key: 10, value: 'Outubro' },
        { key: 11, value: 'Novembro' },
        { key: 12, value: 'Dezembro' }
    ];

    constructor(private fb: FormBuilder,
        private dominioService: DominioService,
        private utilityService: UtilityService,
        private messageService: MessageService) {
        this.messageError = new MessageError();
    }

    @ViewChild(DynamicComponentDirective)
    set appReportComponent(reportComponent: DynamicComponentDirective) {
        console.log('appReportComponent', reportComponent);
        this.reportComponent = reportComponent;
    }

    ngAfterViewInit(): void {
        console.log('AfterViewInit');
    }
    

    ngOnInit(): void {
        this.initForm(false);

        this.dominioService.getDominioEscolaTipo().subscribe(response => {
            this.tiposEscola = response;
        }, error => {
            console.log('RelatoriosFormComponent.ngOnInit(): erro: ', error);
            this.messageService.tratarMensagemErro('Não foi possível recuperar os tipos de escola', true, error, () => { });
        });

        this.dominioService.getDominioPeriodo().subscribe(response => {
            this.periodos = response;
        }, error => {
            console.log('RelatoriosFormComponent.ngOnInit(): erro: ', error);
            this.messageService.tratarMensagemErro('Não foi possível recuperar os períodos', true, error, () => { });
        });

        this.dominioService.getDominioCalendarioSituacao().subscribe(response => {
            this.tiposRelatorio = response;
        }, error => {
            console.log('RelatoriosFormComponent.ngOnInit(): erro: ', error);
            this.messageService.tratarMensagemErro('Não foi possível recuperar as situações do calendário', true, error, () => { });
        });
    }

    submit() {
        this.isSubmitted = true;
        this.formFiltro.markAllAsTouched();
        if (this.formFiltro.valid && ((this.mesIsRequired && this.mesHasValue) || !this.mesIsRequired)) {
            const viewContainerRef = this.reportComponent.viewContainerRef;
            this.utilityService
                .loadComponent(viewContainerRef, this.componentResolver(), true)
                .then(response => {
                    if (!this.formFiltro.value.ano)
                        this.formFiltro.value.ano = this.formFiltro.controls.ano.value;
                    (response as ComponentRef<any>).instance.parametros = this.formFiltro.value;
                });
        }
    }

    enableMesAno(agrupamento: AgrupamentoRelatorioEnum) {

        if (agrupamento == AgrupamentoRelatorioEnum.Mensal) {
            this.formFiltro.controls['mes'].enable();
//            this.formFiltro.controls['ano'].disable();
//            this.formFiltro.patchValue({ ano: new Date().getFullYear() });
            this.formFiltro.controls['mes'].setValidators([Validators.required]);
            this.formFiltro.updateValueAndValidity();
        }
        if (agrupamento == AgrupamentoRelatorioEnum.Anual) {
            this.formFiltro.controls['mes'].disable();
            this.formFiltro.controls['ano'].enable();
            this.formFiltro.patchValue({ mes: '' });
            this.formFiltro.controls['mes'].clearValidators();
            this.formFiltro.updateValueAndValidity();
        }
    }

    initForm(clearComponent: boolean) {
        this.formFiltro = this.fb.group({
            tipoRelatorio: ['', [Validators.required]],
            agrupamentoRelatorio: [AgrupamentoRelatorioEnum.Anual, [Validators.required]],
            mes: [''],
            ano: [new Date().getFullYear()],
            periodo: [''],
            serie: [0],
            tipoEscola: ['']
        });

        this.formFiltro.controls.mes.disable()

        if (clearComponent) this.reportComponent.viewContainerRef.clear();
    }

    limpar() {
        this.formFiltro.patchValue({
            tipoRelatorio: '',
            agrupamentoRelatorio: AgrupamentoRelatorioEnum.Anual,
            mes: '',
            ano: new Date().getFullYear(),
            periodo: '',
            serie: 0,
            tipoEscola: ''
        });
        this.enableMesAno(AgrupamentoRelatorioEnum.Anual);
        this.formFiltro.updateValueAndValidity();
        this.reportComponent.viewContainerRef.clear();
        this.isSubmitted = false;
    }

    get mesIsRequired() {
        return this.formFiltro.controls.agrupamentoRelatorio.value == AgrupamentoRelatorioEnum.Mensal;
    }

    get mesHasValue() {
        return this.formFiltro.controls.mes.value;
    }

    async componentResolver() {
        const { EscolasAtendidasComponent } = await import('../escolas-atendidas/escolas-atendidas.component');
        return EscolasAtendidasComponent;
    }
}
