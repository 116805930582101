import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnderecoEscola } from 'src/app/models/enderecoEscola';


@Injectable({
  providedIn: 'root'
})
export class EnderecoEscolaService {

  constructor(private http:HttpClient) { }

  getEnderecoEscola(id:Number):Observable<EnderecoEscola>{    
    return this.http.get<EnderecoEscola>(`${environment.apiBaseUrl}/endereco-escola/find-by-id-escola/${id}`);    
    
  }

  create(enderecoEscola: EnderecoEscola) : Observable<EnderecoEscola>{
    return this.http.post<EnderecoEscola>(`${environment.apiBaseUrl}/endereco-escola`,enderecoEscola);
  }
  update(enderecoEscola: EnderecoEscola) : Observable<EnderecoEscola>{
    return this.http.put<EnderecoEscola>(`${environment.apiBaseUrl}/endereco-escola/${enderecoEscola.id}`,enderecoEscola)   
  }  
}
