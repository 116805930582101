import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MASKS, NgBrazilValidators } from "ng-brazil";
import { Responsavel } from "src/app/models/responsavel";
import { MessageService } from "src/app/services/message.service";
import { TurmaService } from "src/app/services/turma.service";
import { UtilityService } from "src/app/services/utility.service";
import { MessageError } from "src/app/utils/message-error";
import { TurmaTabEnum } from "src/app/utils/turma-tab.enum";

@Component({
  selector: 'app-turma-responsavel',
  templateUrl: './turma-responsavel.component.html',
  styleUrls: ['./turma-responsavel.component.css']
})
export class TurmaResponsavelComponent implements OnInit {
  public MASKS = MASKS;
  public tabEnum = TurmaTabEnum;

  @Input() agendamentoId: number;
  @Input() tabType: TurmaTabEnum;

  formResponsavel: FormGroup;
  get controls() { return this.formResponsavel.controls; }

  messageError: MessageError;

  constructor(private turmaService: TurmaService,
    private fb: FormBuilder,
    public modalAtivaService: NgbActiveModal,
    private messageService: MessageService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.messageError = new MessageError();
    this.formResponsavel = this.fb.group({
      nome: ['', Validators.required],
      cpf: ['', [Validators.required, NgBrazilValidators.cpf]]
    })
  }

  salvar() {
    if(this.formResponsavel.valid) {
      this.turmaService.addResponsible(this.getResponsavel())
        .subscribe(data => {
          this.modalAtivaService.close(data);
        }, errorResponse => {
          this.messageService.tratarMensagemErro("Não foi possível cadastrar o responsável.", false, errorResponse, () => {});
        });
    }
  }

  getResponsavel(): Responsavel {
    let responsavel = new Responsavel();
    responsavel.idAgendamento = this.agendamentoId;
    responsavel.nome = this.formResponsavel.value.nome;
    responsavel.cpf = this.utilityService.getOnlyNumber(this.formResponsavel.value.cpf);
    responsavel.usuario = this.utilityService.getDtnUserWrapper().getNomeUsuario();

    return responsavel;
  }
}
