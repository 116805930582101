import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { QuestionarioDiagnosticoEscola } from 'src/app/models/questionario-diagnostico-escola';
import { DominioIdTipoAlternativa } from 'src/app/utils/dominio-enum';
import { MessageError } from 'src/app/utils/message-error';
import { RespostaQuestionarioEscola } from 'src/app/models/resposta-questionario-escola';
import { ModeloDiagnosticoService } from 'src/app/services/modelo-diagnostico.service';

@Component({
  selector: 'app-questionario-diagnostico-form-component',
  templateUrl: './questionario-diagnostico-form-component.component.html',
  styleUrls: ['./questionario-diagnostico-form-component.component.css']
})
export class QuestionarioDiagnosticoFormComponentComponent implements OnInit {

  private readonly PREFIXO_FORM_CONTROL: string = 'control_';

  @Input() idModeloDiagnostico: number;
  @Input() respostas: RespostaQuestionarioEscola[];
  @Input() disableAll: boolean;

  formQuestionario: FormGroup;
  questionario: QuestionarioDiagnosticoEscola[];
  erroAoBuscarCalendario: string;
  messageError: MessageError;

  constructor(
    private fb: FormBuilder,
    private modeloDiagnosticoService: ModeloDiagnosticoService
  ) { }

  ngOnInit(): void {
  
    this.messageError = new MessageError();
    this.erroAoBuscarCalendario = null;

    this.formQuestionario = this.fb.group({});

    this.modeloDiagnosticoService.getQuestionario(this.idModeloDiagnostico).subscribe(response => {
      this.questionario = response;

      this.questionario.forEach(x => {
        const resposta = this.respostas?.filter(r => r.idQuestao == x.idQuestao)[0];
        
        switch (x.idDominioTipoAlternativa) {
          case DominioIdTipoAlternativa.OBJETIVA:
            this.formQuestionario.addControl(this.PREFIXO_FORM_CONTROL + x.idQuestao, new FormControl({value: resposta?.respostaObjetiva, disabled: this.disableAll}, [Validators.required, Validators.maxLength(200)]))
            break;
          case DominioIdTipoAlternativa.MULTIPLA_ESCOLHA:

            const formArray: FormArray = new FormArray([], [this.minSelectedCheckboxes(1, this.PREFIXO_FORM_CONTROL + x.idQuestao), this.maxSelectedCheckboxes(x.quantidadeMaxAlternativas, this.PREFIXO_FORM_CONTROL + x.idQuestao)]);
            
            if(resposta) {
              for (let i = 0; i < resposta.respostaMultiplaEscolha.length; i++) {
                formArray.push(new FormControl({value: +resposta.respostaMultiplaEscolha[i], disabled: this.disableAll})); 
              }
            }

            this.formQuestionario.addControl(this.PREFIXO_FORM_CONTROL + x.idQuestao, formArray);
            break;
          case DominioIdTipoAlternativa.VERDADEIRO_OU_FALSO:
            this.formQuestionario.addControl(this.PREFIXO_FORM_CONTROL + x.idQuestao, new FormControl({value: resposta?.respostaVerdadeiroFalso, disabled: this.disableAll}, Validators.required))
            break;
        }
      });
      
    }, errorResponse => {
      if (errorResponse.status == 404 || errorResponse.status == 422)
        this.erroAoBuscarCalendario = errorResponse.error.errors ?
          errorResponse.error.errors.join('. ') : errorResponse.error.error;
      else
        this.erroAoBuscarCalendario = 'Erro ao buscar questionário';
    });
  }

  getFormValues(): RespostaQuestionarioEscola[] {
    if (!this.formQuestionario.valid) {
      this.formQuestionario.markAllAsTouched();
      return null;
    }

    const respostaQuestionario: RespostaQuestionarioEscola[] = new Array();

    this.questionario.forEach(x => {

      const resposta = new RespostaQuestionarioEscola();
      resposta.idQuestao = x.idQuestao;
      switch (x.idDominioTipoAlternativa) {
        case DominioIdTipoAlternativa.OBJETIVA:
          resposta.respostaObjetiva = this.formQuestionario.value[this.PREFIXO_FORM_CONTROL + x.idQuestao]
          break;
        case DominioIdTipoAlternativa.MULTIPLA_ESCOLHA:
          resposta.respostaMultiplaEscolha = this.formQuestionario.value[this.PREFIXO_FORM_CONTROL + x.idQuestao]
          break;
        case DominioIdTipoAlternativa.VERDADEIRO_OU_FALSO:
          resposta.respostaVerdadeiroFalso = this.formQuestionario.value[this.PREFIXO_FORM_CONTROL + x.idQuestao]
          break;
      }
      respostaQuestionario.push(resposta);
    });

    return respostaQuestionario;
  }

  onCheckChange(idcontrol, event) {
    const formArray: FormArray = this.formQuestionario.get(idcontrol) as FormArray;

    if (event.target.checked) {
      formArray.push(new FormControl(+event.target.value));
      formArray.markAllAsTouched();
    }
    else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          formArray.markAllAsTouched();
          return;
        }

        i++;
      });
    }
  }

  check(idControl: string, value: number): boolean {

    const formArray: FormArray = this.formQuestionario.get(idControl) as FormArray;
    for (let entry of formArray.controls.values()) {
      if(entry.value == value)
        return true;
    }

    return false;
  }

  private minSelectedCheckboxes(min: number, control: string): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  private maxSelectedCheckboxes(max: number, control: string): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => 1)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the maximum, return the error message
      return totalSelected > max ? { maximumError: true, maximum: max } : null;
    };

    return validator;
  }

  get dominioTipoAlternativa() {
    return DominioIdTipoAlternativa;
  }
}
