import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModeloDiagnostico } from 'src/app/models/modelo-diagnostico';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloDiagnosticoService } from 'src/app/services/modelo-diagnostico.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { DominioIDModeloDiagnosticoSituacao } from 'src/app/utils/dominio-enum';

@Component({
  selector: 'app-modelo-diagnostico-aprovacao-list',
  templateUrl: './modelo-diagnostico-aprovacao-list.component.html',
  styleUrls: ['./modelo-diagnostico-aprovacao-list.component.css']
})
export class ModeloDiagnosticoAprovacaoListComponent implements OnInit {
  formFiltro: FormGroup;

  page: number;
  pageSize: number;
  totalSize: number;

  modelosDiagnostico: ModeloDiagnostico[];

  constructor(
    private formBuilder: FormBuilder,
    private modeloDiagnosticoService: ModeloDiagnosticoService,
    private modalService: ModalUiService)
  { }

  ngOnInit(): void {
    this.modelosDiagnostico = new Array();
    this.page = 1;
    this.totalSize = 0;
    this.pageSize = 8;

    this.formFiltro = this.formBuilder.group({
      numero: [''],
      dataEnvioAprovacao: [''],
      descricao: ['', Validators.maxLength(200)],
      dominioIdSituacao: ['']
    });
    this.buscar();
  }

  buscar() {
    this.formFiltro.patchValue({dominioIdSituacao: DominioIDModeloDiagnosticoSituacao.AGUARDANDO_APROVACAO});
    this.modeloDiagnosticoService
        .find(this.formFiltro.value, (this.page - 1), this.pageSize)
        .subscribe(response => {
          this.modelosDiagnostico = response.list;
          this.totalSize = response.totalSize;
        }, error => {
          if(error.status == 0) {
            this.modalService.open({
              type: EnumModalType.ERROR,
              message: 'Ocorreu um erro ao listar os modelos pendentes de aprovação',
              buttons: [EnumModalButtons.OK]
            });
          }

          this.modelosDiagnostico = new Array();
        });
  }

  visualizar(id?: number) {}
}
