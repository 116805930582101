import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageError } from 'src/app/utils/message-error';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloDiagnosticoAlternativaService } from 'src/app/services/modelo-diagnostico-alternativa.service';
import { ModeloDiagnosticoAlternativa } from 'src/app/models/modelo-diagnostico-alternativa';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-modelo-diagnostico-alternativa',
  templateUrl: './modelo-diagnostico-alternativa.component.html',
  styleUrls: ['./modelo-diagnostico-alternativa.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModeloDiagnosticoAlternativaComponent implements OnInit {  

  closeResult: string;
  alternativaId: number;
  @Input() questaoId: number;
  @Input() ordem: string;
  @Input() questao: string;
  messageError: MessageError

  listAlternativas: ModeloDiagnosticoAlternativa[] = new Array();
  formAlternativa: FormGroup;  


  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private modalService: ModalUiService,
    private alternativaService: ModeloDiagnosticoAlternativaService,
    private utils: UtilityService) {
  }

  ngOnInit(): void {
    this.messageError = new MessageError();

    this.findAlternativaByQuestaoId();
    this.formAlternativa = this.fb.group({
      id: [null],
      idModeloDiagnosticoVariavel: [this.questaoId],
      ordem: [null, [Validators.required, Validators.maxLength(10)]],
      alternativa: [null, [Validators.required, Validators.maxLength(200)]],

    });    
  }

  private cleanValidatorsError() {
    this.formAlternativa.markAsUntouched();
    this.formAlternativa.markAsPristine();
    
  }

  submit(): void {
    
    if (!this.formAlternativa.valid) {      
      return;
    }
    
    this.formAlternativa.value.usuario = this.utils.getDtnUserWrapper().getNomeUsuario();
    
    if (this.formAlternativa.controls.id.value == null) {
      this.alternativaService.save(this.formAlternativa.value).subscribe(response => {
        if (response)
          this.tratarMensagemSucesso('cadastrado');
      },
        error => {
          this.tratarMensagemErro('cadastrar', error);
        })
    }
    else {
      this.alternativaService.update(this.formAlternativa.controls.id.value, this.formAlternativa.value).subscribe(response => {
        
          this.tratarMensagemSucesso('alterado');
      },
        error => {
          this.tratarMensagemErro('alterar', error);
        })
    }
  }

  findAlternativaByQuestaoId(): void {

    this.alternativaService.find(this.questaoId).subscribe(response => {
      this.listAlternativas = response;      
    }, error => {
      if (error.status == 0) {
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: 'Ocorreu um erro ao listar questão do modelo diagnóstico',
          buttons: [EnumModalButtons.OK]
        });
      }
      this.listAlternativas = new Array();
    });
  }

  carregarParaAlterar(alternativaId: number) {
    if (alternativaId) {
      this.alternativaId = alternativaId;
      this.alternativaService.findById(alternativaId).subscribe(response => {
        this.formAlternativa.patchValue({
          id: response.id,
          // idModeloDiagnostico: response.idModeloDiagnostico,
          ordem: response.ordem,
          alternativa: response.alternativa,
        });

      }, errorResponse => {
        this.tratarMensagemErro('buscar', errorResponse);
        this.limparForm();
      }, () => this.findAlternativaByQuestaoId())
    }
  }

  remover(alternativaId: number): void {
    this.modalService.open({
      buttons: [EnumModalButtons.YES, EnumModalButtons.NO],
      message: "Deseja realmente excluir a alternativa?",
      type: EnumModalType.ALERT,
      yesEvent: () =>
        this.alternativaService.remove(alternativaId).subscribe(
          response => this.tratarMensagemSucesso("foi removida"),
          errorResponse => this.tratarMensagemErro("remover", errorResponse))
    })
  }


  private tratarMensagemSucesso(acao: string) {
    this.modalService.open({
      type: EnumModalType.SUCCESS,
      message: `Alternativa do modelo de questões ${acao} com sucesso`,
      buttons: [EnumModalButtons.OK],
      okEvent: () => {
        this.limparForm();
        this.findAlternativaByQuestaoId();
      }
    });
  }

  private tratarMensagemErro(acao: string, errorResponse: any) {
    switch (errorResponse.status) {
      case 400:
      case 404:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: errorResponse.error.errors.join('. '),
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.findAlternativaByQuestaoId(); }
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: `Não foi possível ${acao} alternativa do modelo de questões`,
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.findAlternativaByQuestaoId(); }
        });
        break;
    }
  }

  limparForm() {
    this.alternativaId = null;    
    this.formAlternativa.patchValue({
      ordem: '',
      alternativa: null,
      id: null,
      idModeloDiagnosticoVariavel: this.questaoId
    });
    this.cleanValidatorsError();
  }

  get f() { return this.formAlternativa.controls; }

}
