import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TurmaChecklistViewComponent } from "./turma-checklist-view/turma-checklist-view.component";
import { TurmaFormComponent } from "./turma-form/turma-form.component";
import { TurmaListComponent } from "./turma-list/turma-list.component";
import { TurmaViewComponent } from "./turma-view/turma-view.component";


const routes = [
  { path: 'turma',
    children: [
      { path: '', redirectTo: '/turma/list', pathMatch: 'full' },
      { path: 'list', component: TurmaListComponent },
      { path: 'criar/:agendamentoId', component: TurmaFormComponent },
      { path: 'visualizar/:agendamentoId', component: TurmaViewComponent },
      { path: 'checklist/:agendamentoId', component: TurmaChecklistViewComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TurmaRoutingModule {

}
