import { Component, Input, OnInit } from '@angular/core';
import { Acompanhante } from 'src/app/models/acompanhante';
import { Aluno } from 'src/app/models/aluno';
import { MessageService } from 'src/app/services/message.service';
import { TurmaService } from 'src/app/services/turma.service';
import { AlunoService } from 'src/app/services/aluno.service';
import { TurmaTabEnum } from 'src/app/utils/turma-tab.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlunoReponsavelSituacao, AgendamentoSituacao } from 'src/app/utils/dominio-enum';
import { KeyValuePair } from 'src/app/utils/key-value-pair';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-turma-checklist',
  templateUrl: './turma-checklist.component.html',
  styleUrls: ['./turma-checklist.component.css']
})
export class TurmaChecklistComponent implements OnInit {

  @Input() idAgendamento: number;
  @Input() disableAll: boolean;

  formChecklist: FormGroup;

  listReponsavel: Acompanhante[] = new Array();
  listAluno: Aluno[] = new Array();

  constructor(private turmaService: TurmaService,
    private alunoService: AlunoService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private router: Router,
    private utils: UtilityService) { }

  ngOnInit(): void {

    this.formChecklist = this.fb.group({
      'responsavel': this.fb.group({}),
      'aluno': this.fb.group({})
    });

    this.turmaService.find(this.idAgendamento)
      .subscribe(data => {
        if(data.dominioIdSituacao == AgendamentoSituacao.CANCELADO || data.dominioIdSituacao == AgendamentoSituacao.FINALIZADO)
          this.disableAll = true;
      }, errorResponse => {
        this.messageService.tratarMensagemErro(
          'Não foi possível obter as informações da turma.',
          true,
          errorResponse,
          () => {
            this.router.navigate(['/turma']);
          })
      });

    this.turmaService.list(this.idAgendamento).subscribe(response => {
      this.listReponsavel = response;

      const formResponsavel: FormGroup = this.formResponsavel;
      for (let i = 0; i < this.listReponsavel.length; i++) {
        let dominioValue = this.listReponsavel[i]?.dominioIdSituacao;
        if (dominioValue == this.dominioAlunoReponsavelSituacao.Cadastrado) dominioValue = null;
        formResponsavel.addControl(`responsavel_${this.listReponsavel[i].id}_presente`, new FormControl({ value: dominioValue, disabled: this.disableAll }, Validators.required))
      }

      this.alunoService.findAllByAgendamento(this.idAgendamento, this.utils.getDtnUserWrapper().isOperadorEscola()).subscribe(response => {
        this.listAluno = response;
        const formAluno: FormGroup = this.formAluno;
        for (let i = 0; i < this.listAluno.length; i++) {
          let dominioValue = this.listAluno[i]?.dominioIdSituacao;
          if (dominioValue == this.dominioAlunoReponsavelSituacao.Cadastrado) dominioValue = null;
          formAluno.addControl(`aluno_${this.listAluno[i].id}_presente`, new FormControl({ value: dominioValue, disabled: this.disableAll }, Validators.required))
        }
      }, errorResult => this.messageService.tratarMensagemErro('Erro ao buscar os alunos do agendamento', true, errorResult, () => { this.router.navigate(["/turma"]) }));
    }, errorResult => this.messageService.tratarMensagemErro('Erro ao buscar os reponsáveis do agendamento', true, errorResult, () => { this.router.navigate(["/turma"]) })
    );
  }

  submit(): void {

    if (this.formChecklist.invalid) {

      this.messageService.aviso('Favor preencher todos os campos');
      return;
    }
    
    const usuario: string = this.utils.getDtnUserWrapper().getNomeUsuario();

    const arrayAluno: KeyValuePair<number, number>[] = new Array(),
      arrayResponsavel: KeyValuePair<number, number>[] = new Array();

    const formArrayAlunos = this.formChecklist.value.aluno,
      formArrayResponsavel = this.formChecklist.value.responsavel;

    for (let i = 0; i < this.listAluno.length; i++) {
      const idAluno = this.listAluno[i].id;
      arrayAluno.push({
        key: idAluno,
        value: formArrayAlunos[`aluno_${idAluno}_presente`]
      })
    }

    for (let i = 0; i < this.listReponsavel.length; i++) {
      const idResponsavel = this.listReponsavel[i].id;
      arrayResponsavel.push({
        key: idResponsavel,
        value: formArrayResponsavel[`responsavel_${idResponsavel}_presente`]
      })
    }

    const totalAlunoPresente: number = arrayAluno.filter(a => a.value == +this.dominioAlunoReponsavelSituacao.Presente).length,
      totalResponsavelPresente: number = arrayResponsavel.filter(a => a.value == +this.dominioAlunoReponsavelSituacao.Presente).length;
    this.messageService.confirmacao(`
    <b>Reponsavel</b><br/>
    Presente: ${totalResponsavelPresente}<br/>
    Ausente: ${this.listReponsavel.length - totalResponsavelPresente}<br/><br/>
    <b>Alunos</b><br/>
    Presente: ${totalAlunoPresente}<br/>
    Ausente: ${this.listAluno.length - totalAlunoPresente}`, () => {
      this.turmaService.saveChecklist(this.idAgendamento, arrayAluno, arrayResponsavel, usuario).subscribe(response => {
        this.messageService.tratarMensagemSucesso('Checklist salvo com sucesso', true, () => { this.router.navigate(['/turma']) })
      },
        errorResponse => this.messageService.tratarMensagemErro('Erro ao salvar checklist', true, errorResponse, () => { }))
    }, () => { })
  }

  print(): void {
    this.turmaService.printChecklist(this.idAgendamento).subscribe((response) => {
      let blob = new Blob([response], { type: 'application/pdf'});

      let downloadURL = window.URL.createObjectURL(blob);

      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = "CheckListTurma.pdf";
      link.click();
    }, errorResponse => {
        console.log(errorResponse);
      this.messageService.tratarMensagemErro('Não foi possível gerar a impressão', true, errorResponse, () => {});
    });
  }

  get dominioAlunoReponsavelSituacao() {
    return AlunoReponsavelSituacao;
  }

  private get formResponsavel(): FormGroup {
    return this.formChecklist.controls['responsavel'] as FormGroup;
  }
  private get formAluno(): FormGroup {
    return this.formChecklist.controls['aluno'] as FormGroup;
  }
}
