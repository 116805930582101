import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AgendamentoTurma } from "src/app/models/agendamento-turma";
import { Dominio } from "src/app/models/dominio";
import { Escola } from "src/app/models/escola";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { DominioService } from "src/app/services/dominio.service";
import { EscolaService } from "src/app/services/escola.service";
import { MessageService } from "src/app/services/message.service";
import { TurmaService } from "src/app/services/turma.service";
import { DominioIdDescricaoDTO } from "src/app/utils/dominio-id-descricao.dto";
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-turma-list',
  templateUrl: './turma-list.component.html',
  styleUrls: ['./turma-list.component.css']
})
export class TurmaListComponent implements OnInit {
  formFiltro: FormGroup;

  agendamentos: AgendamentoTurma[];
  escolas: Escola[];
  situacoesAgendamento: Dominio[];
  dtnUserWrapper: DtnAuthUserWrapper;
  isOperadorEscola: boolean;

  totalSize: number;
  page: number;
  pageSize: number;
  
  agendamentosList: AgendamentoTurma[];

  constructor(private fb: FormBuilder,
    private messageService: MessageService,
    private agendamentoService: AgendamentoService,
    private escolaService: EscolaService,
    private turmaService: TurmaService,
    private dominioService: DominioService,
    private utils: UtilityService) {

    this.agendamentos = new Array();
    this.totalSize = 0;
    this.page = 1;
    this.pageSize = 8;
    this.dtnUserWrapper = utils.getDtnUserWrapper();
    this.isOperadorEscola = this.dtnUserWrapper.isOperadorEscola();
    
  }

  ngOnInit(): void {
    this.formFiltro = this.fb.group({
      escolaId: [{value: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? this.dtnUserWrapper.getInstituicaoEscola().getId() : '', disabled: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola()}],
      dominioIdSituacaoAgendamento: [''],
      dataAgendada: [''],
      numeroTurma: ['']
    });

    this.escolaService.find({isOperadorEscola: this.isOperadorEscola ? '1': '0', codigo: this.dtnUserWrapper.getCodigoEscola()}, 0, 100)
      .subscribe(e => {
        this.escolas = e.list;
      });

    this.dominioService.getDominioAgendamentoSituacao()
      .subscribe(situacoes => {
        this.situacoesAgendamento = situacoes;
      });

    this.search();
  }

  search() {
      const filters = this.formFiltro.getRawValue();
      console.log('TurmaListComponent.search()', filters);
    this.agendamentoService.listAgendamentoTurma(filters)
    .subscribe(data => {
      this.agendamentosList = data.list;
      this.agendamentos = this.agendamentosList.slice(0, this.pageSize);
      this.totalSize = data.totalSize;
    }, errorResponse => {
      this.messageService.tratarMensagemErro("Não foi possível pesquisar os agendamentos.", false, errorResponse, () => {});
    });
  }

  remove(agendamentoId: number) {
    let agendamento = this.agendamentos.filter(a => a.agendamentoId == agendamentoId)[0];
    this.messageService.confirmacao(`Tem certeza que deseja apagar a turma do agendamento do dia
      ${new Date(agendamento.dataAgendada).toLocaleDateString('pt-BR', { timeZone: 'GMT'})}?`,
      () => {
        this.turmaService.deleteTurma(agendamentoId, this.utils.getDtnUserWrapper().getNomeUsuario()).subscribe(result => {
          this.messageService.tratarMensagemSucesso("Turma apagada com sucesso.", true, () => {this.search()});
        }, errorResponse => {
          this.messageService.tratarMensagemErro("Não foi possível apagar a turma.", true, errorResponse, () => {});
        });
      }, () => {});
  }
  
  pageChange() {
    this.agendamentos = this.agendamentosList.slice((this.page-1)*this.pageSize, this.page*this.pageSize-1);
  }
  
  orderBy(event) {
    
    switch (event.target.value) {
        case 'escolaAsc':
            this.agendamentosList.sort(this.sortByEscolaAsc);
            
            break;
            
        case 'escolaDesc':
            this.agendamentosList.sort(this.sortByEscolaDesc);
            
            break;
        
        case 'dataAsc':
            this.agendamentosList.sort(this.sortByDataAsc);
            
            break;
            
        case 'dataDesc':
            this.agendamentosList.sort(this.sortByDataDesc);
            
            break;
        
        default: 
            break;
    }
    
    this.agendamentos = this.agendamentosList.slice((this.page-1)*this.pageSize, this.page*this.pageSize-1);
  }
  
  sortByEscolaAsc(a: AgendamentoTurma, b:AgendamentoTurma){
    var aEscola = a.escola.toLowerCase();
    var bEscola = b.escola.toLowerCase(); 
    return ((aEscola < bEscola) ? -1 : ((aEscola > bEscola) ? 1 : 0));
   }
   
   sortByEscolaDesc(a: AgendamentoTurma, b:AgendamentoTurma){
    var aEscola = a.escola.toLowerCase();
    var bEscola = b.escola.toLowerCase(); 
    return ((aEscola > bEscola) ? -1 : ((aEscola < bEscola) ? 1 : 0));
   }
   
   sortByDataAsc(a: AgendamentoTurma, b:AgendamentoTurma){
    var aData = a.dataAgendada;
    var bData = b.dataAgendada; 
    return ((aData < bData) ? -1 : ((aData > bData) ? 1 : 0));
   }
   
   sortByDataDesc(a: AgendamentoTurma, b:AgendamentoTurma){
    var aData = a.dataAgendada;
    var bData = b.dataAgendada; 
    return ((aData > bData) ? -1 : ((aData < bData) ? 1 : 0));
   }
}
