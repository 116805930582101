import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';

export const environment = {
    production: false,
    apiBaseUrl: document.referrer + 'detranzinho-api/api',
    apiBaseHost: document.referrer
};

console.log('environment: ', environment);

export const GLOBAL = {
    dtnuser: '',
    wrapper: null
}