<div class="jumbotron" style="text-align: center;">
  <h3>Agendamento</h3>
</div>


<fieldset class="form-group border p-2">
  <div class="form-row mt-3">
    <div class="col-md-10">
      <div class="form-row mb-3">
        <label for="escola" class="col-md-1 col-form-label">Escola</label>
        <div class="col-md-10">
          <select id="escolaSelecionada" class="custom-select" [(ngModel)]="escolaSelecionada" [disabled]=dtnUserWrapper.isOperadorEscola()>
            <option [value]="0" selected>--</option>
            <option *ngFor="let escola of escolas" [value]="escola.codigo">{{escola.nome}}</option>
          </select>
        </div>
      </div>
    </div>
      <div class="col-md-2">
      <button *ngIf="dtnUserWrapper.isOperadorEscola()" type="submit" class="btn btn-success" [disabled]="escolaSelecionada == 0" routerLink="termo-compromisso/{{escolaSelecionada}}">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
        Incluir
      </button>
    </div>
  </div>
</fieldset>
<form [formGroup]="filtro" (ngSubmit)="buscar()">
  <table class="table table-condensed table-hover">
    <thead>
      <tr>
        <th id="clEscola">
          Escola
          <select id="escolaId" class="custom-select" formControlName="escolaId">
            <option value="" selected>Selecione</option>
            <option *ngFor="let escola of escolas" value="{{escola.id}}">{{escola.nome}}</option>
          </select>
        </th>
        <th id="clDataAgendada">
          Data Agendada
          <input type="date" class="form-control" id="dataAgendada" placeholder="Data Agendada"
            formControlName="dataAgendada" />
        </th>
        <th id="clPeriodo">
          Período
          <div class="form-control border-0">-</div>
        </th>
        <th id="clPrazo">
          Prazo
          <div class="form-control border-0">-</div>
        </th>
        <th id="clSituacao">
          Situação
          <select class="custom-select" formControlName="situacao">
            <option value="" selected>--</option>
            <option *ngFor="let d of listDominioAgendamentoSituacao" [value]="d.id">{{d.descricao}}</option>
          </select>
        </th>
        <th id="clBotoes">
          <button type="submit" class="btn btn-outline-secondary float-right">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                <path fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
            </svg>
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let agendamento of agendamentos">
        <td>{{agendamento.nomeEscola}}</td>
        <td>{{agendamento.dataAgendada | date:'dd/MM/yyy'}}</td>
        <td>{{agendamento.periodoDescricao}}</td>
        <td>{{agendamento.prazo}}</td>
        <td>{{agendamento.situacaoDescricao}}</td>
        <td>
          <div class="dropdown">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <button type="button" class="btn btn-outline-secondary dropdown-item" [disabled]="dtnUserWrapper.isOperadorEscola() && !podeCancelar(agendamento)" placement="bottom" (click)="confirmarCancelamento(agendamento)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg> Cancelar
              </button>
              <button type="button" class="btn btn-outline-secondary dropdown-item" placement="bottom" routerLink="visualizar/{{agendamento.agendamentoId}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                </svg> Visualizar
              </button>
               <button *ngIf="!dtnUserWrapper.isOperadorEscola()" type="button" class="btn btn-outline-secondary dropdown-item" placement="bottom"  (click)="buscarData(agendamento)" >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                    <path d="M 2.0277227722772277 10.986138613861385  L 2.875247524752475 10.986138613861385  L 3.596039603960396 10.265346534653466  L 1.7346534653465344 8.403960396039604  L 1.0138613861386139 9.124752475247526  L 1.0138613861386139 9.972277227722772  L 2.0277227722772277 9.972277227722772  L 2.0277227722772277 10.986138613861385  Z M 6.9623762376237615 3.77029702970297  C 6.9993399339934 3.7333333333333334  7.017821782178219 3.688448844884488  7.017821782178219 3.635643564356436  C 7.017821782178219 3.5194719471947193  6.959735973597359 3.461386138613861  6.8435643564356425 3.461386138613861  C 6.790759075907591 3.461386138613861  6.745874587458747 3.4798679867986793  6.7089108910891095 3.5168316831683173  L 2.4158415841584158 7.809900990099011  C 2.378877887788779 7.8468646864686455  2.3603960396039603 7.89174917491749  2.3603960396039603 7.9445544554455445  C 2.3603960396039603 8.06072607260726  2.4184818481848187 8.118811881188119  2.5346534653465347 8.118811881188119  C 2.5874587458745877 8.118811881188119  2.6323432343234323 8.1003300330033  2.669306930693069 8.063366336633663  L 6.9623762376237615 3.77029702970297  Z M 0 8.704950495049506  L 6.590099009900991 2.1148514851485154  L 9.885148514851485 5.409900990099009  L 3.2950495049504953 12  L 0 12  L 0 8.704950495049506  Z M 11.706930693069307 2.154455445544554  C 11.902310231023103 2.3603960396039607  12 2.6006600660066006  12 2.875247524752475  C 12 3.155115511551155  11.902310231023103 3.3927392739273916  11.706930693069307 3.5881188118811886  L 10.39207920792079 4.902970297029703  L 7.097029702970297 1.6079207920792085  L 8.411881188118812 0.30099009900990037  C 8.601980198019803 0.10033003300329923  8.83960396039604 0  9.124752475247526 0  C 9.404620462046207 0  9.644884488448845 0.10033003300329923  9.845544554455445 0.30099009900990037  L 11.706930693069307 2.154455445544554  Z " />
                </svg> Alterar Data
              </button>  
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="agendamentos.length == 0">
        <td colspan="6" align="center">
          Nenhum registro encontrado.
        </td>
      </tr>
    </tbody>
  </table>
</form>
<app-pagination *ngIf="agendamentos.length != 0" [totalSize]="totalSize" [(page)]="page" [pageSize]="pageSize"
  (pageChange)="buscar()"></app-pagination>
