
import { FormArray, ValidatorFn } from '@angular/forms';
import { environment, GLOBAL } from 'src/environments/environment';
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';
import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    getOnlyNumber(num: string) {
        return num.replace(/\D+/g, '');
    }

    replaceFieldsOnlyNumber(obj: any, fields: string[]) {
        for (const f of fields) {
            if(obj[f]){
                obj[f] = this.getOnlyNumber(obj[f])
            }
        }
        return obj;
    }

    formatDate(date: Date): string {
        const newDate = new Date(date);
        return ("0" + (newDate.getDate() + 1)).substr(-2) + "/" + ("0" + (newDate.getMonth() + 1)).substr(-2) + "/" + newDate.getFullYear();
    }

    minSelectedCheckboxes(min: number, control: string): ValidatorFn {
        const validator: ValidatorFn = (formArray: FormArray) => {
            const totalSelected = formArray.controls
                // get a list of checkbox values (boolean)
                .map(control => control.value)
                // total up the number of checked checkboxes
                .reduce((prev, next) => next ? prev + next : prev, 0);
            // if the total is not greater than the minimum, return the error message
            return totalSelected >= min ? null : { required: true };
        };

        return validator;
    }

    maxSelectedCheckboxes(max: number, control: string): ValidatorFn {
        const validator: ValidatorFn = (formArray: FormArray) => {
            const totalSelected = formArray.controls
                // get a list of checkbox values (boolean)
                .map(control => 1)
                // total up the number of checked checkboxes
                .reduce((prev, next) => next ? prev + next : prev, 0);
            // if the total is not greater than the maximum, return the error message
            return totalSelected > max ? { maximumError: true, maximum: max } : null;
        };

        return validator;
    }

    getUid(): string {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('uid');
        return token;
    }

    getGlobalUid(): string {
        return GLOBAL.dtnuser;
    }

    getDtnUserWrapper(): DtnAuthUserWrapper {
        return GLOBAL.wrapper;
    }
    /** Carrega um component dinâmicamente utilizando o ComponentFactory e o ContainerReference. * @param viewContainerRef Referência do container obtida através da diretiva appDynamicComponent. * @param componentResolverCallback Callback obrigatório para o método async que retorna o Component desejado. * @param clearContainerView Parâmetro não obrigatório que limpa os Components já carregados. */
    async loadComponent(viewContainerRef: ViewContainerRef, componentResolverCallback: Promise<any>, clearContainerView: boolean = false) {
        if (clearContainerView) viewContainerRef.clear(); let component: any = await componentResolverCallback; return viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component));





    }
}



    