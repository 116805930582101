import { Injectable } from '@angular/core';
import { EnumModalButtons, EnumModalType } from '../template/modal/enum-modal';
import { ModalUiService } from './modal-ui.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private modalService: ModalUiService)
  { }

  tratarMensagemSucesso(message: string, hideClose: boolean, okEvent: VoidFunction) {
    this.modalService.open({
      type: EnumModalType.SUCCESS,
      message: message,
      hideClose: hideClose,
      buttons: [EnumModalButtons.OK],
      okEvent: okEvent
    });
  }

  tratarMensagemErro(mensagemDefault: string, hideClose: boolean,
    httpResponse: any, okEvent: VoidFunction) {

      let message = httpResponse.error.errors?
      httpResponse.error.errors.join('. ') : httpResponse.error.error;
      message = message ? message : mensagemDefault;

    switch (httpResponse.status) {
      case 400:
      case 404:
      case 409:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: message,
          hideClose: hideClose,
          buttons: [EnumModalButtons.OK],
          okEvent: okEvent
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: mensagemDefault,
          hideClose: hideClose,
          buttons: [EnumModalButtons.OK],
          okEvent: okEvent
        });
        break;
    }
  }

    tratarMensagemErro2(hideClose: boolean, httpResponse: any, okEvent: VoidFunction) {
        var response = JSON.parse(httpResponse);
        let message = response.errors ? response.errors.join('. ') : response.error.error;
        message = message ? message : 'Transação não pôde ser concluída!';

        switch (httpResponse.status) {
            case 400:
            case 404:
            case 409:
            case 422:
                this.modalService.open({
                    type: EnumModalType.ALERT,
                    message: message,
                    hideClose: hideClose,
                    buttons: [EnumModalButtons.OK],
                    okEvent: okEvent
                });
                break;
            default:
                this.modalService.open({
                    type: EnumModalType.ERROR,
                    message: message,
                    hideClose: hideClose,
                    buttons: [EnumModalButtons.OK],
                    okEvent: okEvent
                });
            break;
        }
    }

    tratarMensagemErro3(hideClose: boolean, message: string, okEvent: VoidFunction) {
        this.modalService.open({
            type: EnumModalType.ERROR,
            message: message,
            hideClose: hideClose,
            buttons: [EnumModalButtons.OK],
            okEvent: okEvent
        });
    }

  aviso(mensagemDefault: string, okEvent?: VoidFunction) {
    this.modalService.open({
          type: EnumModalType.ALERT,
          message: mensagemDefault,
          hideClose: true,
          buttons: [EnumModalButtons.OK],
          okEvent: okEvent
        });
  }

  confirmacao(mensagem: string, yesEvent: VoidFunction, noEvent: VoidFunction) {
    this.modalService.open({
      type: EnumModalType.ALERT,
      message: mensagem,
      hideClose: true,
      buttons: [EnumModalButtons.YES, EnumModalButtons.NO],
      yesEvent: yesEvent,
      noEvent: noEvent
    });
  }
}