import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DiagnosticoEscola } from 'src/app/models/diagnostico-escola';
import { DiagnosticoEscolaHistorico } from '../models/diagnostico-escola-historico';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticoEscolaService {

  private readonly BASE_URL: string = `${environment.apiBaseUrl}/diagnostico-escola`;

  constructor(private http: HttpClient) { }

  getDiagnosticoEscola(id: Number): Observable<DiagnosticoEscola> {
    return this.http.get<DiagnosticoEscola>(`${this.BASE_URL}/${id}`);

  }

  findDiagnosticoByIdEscola(id: number): Observable<DiagnosticoEscola> {
    return this.http.get<DiagnosticoEscola>(`${this.BASE_URL}/find-by-id-escola/${id}`);
  }

  getIdModeloDiagnostico(): Observable<number> {
    return this.http.get<number>(`${this.BASE_URL}/get-last-id-modelo-diagnostico`)
  }


  create(diagnosticoEscola: DiagnosticoEscola): Observable<DiagnosticoEscola> {
    return this.http.post<DiagnosticoEscola>(`${this.BASE_URL}`, diagnosticoEscola);
  }
  update(diagnosticoEscola: DiagnosticoEscola): Observable<DiagnosticoEscola> {
    return this.http.put<DiagnosticoEscola>(`${this.BASE_URL}/${diagnosticoEscola.id}`, diagnosticoEscola)
  }
  print(idEscola: number) {
    return this.http.get(`${this.BASE_URL}/print/pdf/${idEscola}`, { responseType: 'blob' });
  }

  findHistory(form: DiagnosticoEscolaHistorico, page: number, pageSize: number): Observable<ReponsePaginationListDTO<DiagnosticoEscola>> {
    let httpParams = new HttpParams();
    Object.keys(form).forEach(
      key => form[key] && (httpParams = httpParams.append(key, form[key]))
    );
    httpParams = httpParams.append("page", page.toString());
    httpParams = httpParams.append("pageSize", pageSize.toString());
    return this.http.get<ReponsePaginationListDTO<DiagnosticoEscola>>(`${this.BASE_URL}/history`, {
      params: httpParams
    });
  }
}
