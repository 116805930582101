/*
  Especifíca qual o tipo de dado o Observable está trabalhando
  Para entender os diferentes tipos de data consulte
  https://github.com/valor-software/ng2-charts
*/
export enum ChartDataTypeEnum {
  //Array simples
  SingleData,

  //Array multi dimensional
  MultiData,

  //Lista de objetos com SingleData e Labels
  DataSet
}
