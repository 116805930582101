import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Acompanhante } from "../models/acompanhante";
import { InformacoesTurma } from "../models/informacoes-turma";
import { Responsavel } from "../models/responsavel";
import { KeyValuePair } from "../utils/key-value-pair";
import { ReponsePaginationListDTO } from "../utils/response-pagination-list.dto";
import { TurmaTabEnum } from "../utils/turma-tab.enum";


@Injectable({
  providedIn: 'root'
})
export class TurmaService {

  private readonly TURMA_BASE_URL = `${environment.apiBaseUrl}/turma`;
  private readonly RESPONSAVEL_BASE_URL = `${environment.apiBaseUrl}/responsavel`

  constructor(private http: HttpClient) { }

  list(agendamentoId: number, tabType?: TurmaTabEnum): Observable<Acompanhante[]> {

    let resource: string = '';
    switch (+tabType) {
      case TurmaTabEnum.Acompanhante:
        resource =  'list-acompanhante';
        break;
      case TurmaTabEnum.ProfissionalApoio:
        resource =  'list-profissional-apoio';
        break;
        default:
        resource =  'all';
        break;
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.append("agendamentoId", agendamentoId.toString());
    return this.http.get<Acompanhante[]>(`${this.RESPONSAVEL_BASE_URL}/${resource}`, { params: httpParams });
  }

  insert(agendamentoId: number, responsavelId: number, dominioIdFuncao: number, usuario: string): Observable<any> {
    return this.http.post(`${this.RESPONSAVEL_BASE_URL}/add-turma`, {
      idAgendamento: agendamentoId,
      idResponsavel: responsavelId,
      idDominioFuncao: dominioIdFuncao, 
      usuario: usuario
    });
  }

  remove(acompanhanteId: number, ativoId: number): Observable<any> {
    return this.http.put(`${this.RESPONSAVEL_BASE_URL}/atualizar/${acompanhanteId}/${ativoId}`, null);
  }

  addResponsible(responsavel: Responsavel): Observable<Responsavel> {
    return this.http.post<Responsavel>(`${this.RESPONSAVEL_BASE_URL}`, responsavel);
  }

  listResponsible(nome: string, agendamentoId: number): Observable<KeyValuePair<number, string>[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("nome", nome? nome.toString() : '');
    httpParams = httpParams.append("agendamentoId", agendamentoId.toString());

    return this.http.get<KeyValuePair<number, string>[]>(`${this.RESPONSAVEL_BASE_URL}`, { params: httpParams });
  }


  close(id: number, usuario: string) : Observable<any> {
    return this.http.patch<any>(`${this.TURMA_BASE_URL}/${id}/fechar`, {usuario: usuario});
  }

  find(agendamentoId: number) : Observable<InformacoesTurma> {
    return this.http.get<InformacoesTurma>(`${this.TURMA_BASE_URL}/informacoes/${agendamentoId}`);
  }

  saveChecklist(idTurma: number, arrayAluno: KeyValuePair<number, number>[],  arrayResponsavel: KeyValuePair<number, number>[], usuario: string) : Observable<any> {

    const formData = {
      "idTurma": idTurma,
      "alunos": arrayAluno,
      "responsavels": arrayResponsavel,
      "usuario": usuario
    }

    return this.http.patch<Responsavel>(`${this.TURMA_BASE_URL}/checklist`, formData);
  }

  printChecklist(agendamentoId: number) {
    return this.http.get(`${this.TURMA_BASE_URL}/${agendamentoId}/print-checklist/pdf`, { responseType: 'blob' });
  }

  deleteTurma(agendamentoId: number, usuario: string){
    return this.http.put(`${this.TURMA_BASE_URL}/${agendamentoId}/delete`, {usuario: usuario});
  }
}
