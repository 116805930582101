import { ChartDataSets, ChartOptions, ChartPoint } from "chart.js";
import { Color, Label, MultiDataSet, SingleDataSet } from "ng2-charts";
import { Observable } from "rxjs";
import { ChartDataTypeEnum } from "./chart-data-type.enum";
import { ChartTypeEnum } from "./chart-type.enum";

/*
  Wrapper das propriedades utilizadas no chart.js
  Como utilizar as propriedades e suas aplicações podem ser vistas em:
  https://github.com/valor-software/ng2-charts
*/
export class ChartConfig {
  singledata: SingleDataSet;
  multidata: MultiDataSet;
  datasets: ChartDataSets[];
  labels: Label[];
  type: ChartTypeEnum;
  colors: Color[];
  legend: boolean;
  options: ChartOptions;
  plugins: any[];

  constructor() {
    this.options = {
      responsive: true,
      scales: {
        xAxes: [{}], yAxes: [{
          ticks: { suggestedMin: 0 }
        }]
      },
      legend: {
        position: 'bottom'
      }
    };
    this.singledata = [];
    this.multidata = [];
    this.datasets = [{data : [], label: '' }];
  }

  noData: boolean;
  error: any;
  set data$(tuple: [Observable<any[]>, ChartDataTypeEnum]) {
    tuple[0].subscribe(response => {
      this.error = null;
      this.noData = false;
      switch(tuple[1]) {
        case ChartDataTypeEnum.SingleData:
          this.singledata = response as number[];
          break;
        case ChartDataTypeEnum.MultiData:
          this.multidata = response as SingleDataSet[];
          break;
        case ChartDataTypeEnum.DataSet:
          this.datasets = response as ChartDataSets[];
          break;
      }
    }, error => {
      this.error = error;
      this.noData = true;
      this.singledata = [];
      this.multidata = [];
      this.datasets = [{data : [], label: '', hidden: true, backgroundColor: '#ffffff' }];
      this.colors = [];
    });
  }
}
