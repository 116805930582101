import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModeloDiagnosticoRoutingModule } from './modelo-diagnostico-routing.module';
import { ModeloDiagnosticoFormComponent } from './modelo-diagnostico-form/modelo-diagnostico-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ModeloDiagnosticoListComponent } from './modelo-diagnostico-list/modelo-diagnostico-list.component';
import { ServicesModule } from 'src/app/services/services.module';
import { TemplateModule } from 'src/app/template/template.module';
import { ModeloDiagnosticoQuestoesComponent } from './modelo-diagnostico-questoes/modelo-diagnostico-questoes.component';
import { ModeloDiagnosticoAlternativaComponent } from './modelo-diagnostico-alternativa/modelo-diagnostico-alternativa.component';
import { ModeloDiagnosticoAprovacaoListComponent } from './modelo-diagnostico-aprovacao-list/modelo-diagnostico-aprovacao-list.component';
import { ModeloDiagnosticoAprovacaoComponent } from './modelo-diagnostico-aprovacao/modelo-diagnostico-aprovacao.component';
import {EscolaModule} from 'src/app/modules/escola/escola.module';


@NgModule({
  declarations: [
    ModeloDiagnosticoFormComponent,
    ModeloDiagnosticoListComponent,
    ModeloDiagnosticoQuestoesComponent,
    ModeloDiagnosticoAlternativaComponent,
    ModeloDiagnosticoAprovacaoListComponent,
    ModeloDiagnosticoAprovacaoComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    ModeloDiagnosticoRoutingModule,
    ServicesModule,
    TemplateModule,
    EscolaModule
  ],
  providers: [
    UtilityService
  ]
})
export class ModeloDiagnosticoModule { }
