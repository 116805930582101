import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AgendamentoTurma } from "src/app/models/agendamento-turma";
import { Dominio } from "src/app/models/dominio";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { DominioService } from "src/app/services/dominio.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: 'app-etiqueta',
  templateUrl: './etiqueta.component.html',
  styleUrls: [ './etiqueta.component.css' ]
})
export class EtiquetaComponent implements OnInit {
  formFiltro: FormGroup;

  dominioPeriodos: Dominio[];
  agendamentos: AgendamentoTurma[];

  page: number;
  pageSize: number;
  totalSize: number;

  constructor(private agendamentoService: AgendamentoService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private dominioService: DominioService) {
    this.agendamentos = [];

    this.page = 1;
    this.pageSize = 8;
    this.totalSize = 0;
  }
  ngOnInit(): void {
    this.formFiltro = this.fb.group({
      dataAgendada: [''],
      numeroTurma: [''],
      dominioIdPeriodo: ['']
    });

    this.dominioService.getDominioPeriodo()
      .subscribe(data => {
        this.dominioPeriodos = data;
      });
    this.search();
  }

  search() {
    this.agendamentoService.listEtiquetaImpressao(this.formFiltro.value, (this.page - 1), this.pageSize)
      .subscribe(data => {
        this.agendamentos = data.list;
        this.totalSize = data.totalSize;
      }, err => {
        this.messageService.tratarMensagemErro(
          'Não foi possível recuperar os agendamentos para impressão das etiquetas.',
          false, err, () => {});
      })
  }

  print(agendamentoId: number) {
    this.agendamentoService.printEtiquetas(agendamentoId)
      .subscribe((response) => {
        let blob = new Blob([response], { type: 'application/pdf' });
        let downloadURL = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Etiquetas.pdf";
        link.click();
      }, (errorResponse) => {
        this.messageService.tratarMensagemErro(
          "Não foi possível gerar a impressão",
          true,
          errorResponse,
          () => {});
      });
  }
}
