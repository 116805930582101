import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ServicesModule } from "src/app/services/services.module";
import { TemplateModule } from "src/app/template/template.module";
import { AgendamentoCalendarioComponent } from "./agendamento-calendario/agendamento-calendario.component";
import { AgendamentoFormComponent } from "./agendamento-form/agendamento-form.component";
import { AgendamentoListComponent } from "./agendamento-list/agendamento-list.component";
import { AgendamentoRoutingModule } from "./agendamento-routing.module";
import { AgendamentoViewComponent } from "./agendamento-view/agendamento-view.component";
import { TermoCompromissoComponent } from './termo-compromisso/termo-compromisso.component';
import { ManualComponent } from './manual/manual.component';


@NgModule({
    declarations: [
        AgendamentoListComponent,
        TermoCompromissoComponent,
        AgendamentoCalendarioComponent,
        AgendamentoFormComponent,
        AgendamentoViewComponent,
        ManualComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        FormsModule,
        AgendamentoRoutingModule,
        ServicesModule,
        TemplateModule
    ],
    providers: [AgendamentoListComponent]
})
export class AgendamentoModule { }
