import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DominioIdDescricaoDTO } from 'src/app/utils/dominio-id-descricao.dto';
import { DominioService } from 'src/app/services/dominio.service';
import { ModeloDiagnosticoQuestao } from 'src/app/models/modelo-diagnostico-questao';
import { ModeloDiagnosticoVariavelService } from 'src/app/services/modelo-diagnostico-variavel.service';
import {
  EnumModalButtons,
  EnumModalType,
} from 'src/app/template/modal/enum-modal';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { MessageError } from 'src/app/utils/message-error';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModeloDiagnosticoAlternativaComponent } from 'src/app/modules/modelo-diagnostico/modelo-diagnostico-alternativa/modelo-diagnostico-alternativa.component';
import { DominioIdTipoAlternativa } from 'src/app/utils/dominio-enum';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-modelo-diagnostico-questoes',
  templateUrl: './modelo-diagnostico-questoes.component.html',
  styleUrls: ['./modelo-diagnostico-questoes.component.css'],
})
export class ModeloDiagnosticoQuestoesComponent implements OnInit {
  formModeloDiagnosticoQuestoes: FormGroup;
  id: number;
  page: number;
  pageSize: number = 10;
  totalSize: number;
  formModeloDiagnosticoVariavelDadosFind: FormGroup;
  messageError: MessageError;
  @Input() idModeloDiagnostico: number;
  submitted = false;

  listDominioTipoAlternativa: DominioIdDescricaoDTO[];
  listQuestao: ModeloDiagnosticoQuestao[] = new Array();

  constructor(
    private fb: FormBuilder,
    private dominioService: DominioService,
    private modalService: ModalUiService,
    private modeloDiagnosticoVariavelService: ModeloDiagnosticoVariavelService,
    private modalAlternativaService: NgbModal,
    private utils: UtilityService
  ) {}

  ngOnInit(): void {
    this.formModeloDiagnosticoQuestoes = this.fb.group({
      ordem: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
      ]),
      dominioIdTipoAlternativa: new FormControl('', Validators.required),
      questao: new FormControl('', [
        Validators.required,
        Validators.maxLength(200),
      ]),
      quantidadeAlternativaCorreta: new FormControl(''),
    });

    this.formModeloDiagnosticoVariavelDadosFind = this.fb.group({
      ordem: new FormControl('', Validators.maxLength(10)),
      questao: new FormControl('', Validators.maxLength(200)),
    });

    this.dominioService
      .getDominioModeloDiagnostivoTipoAlternativa()
      .subscribe((response) => {
        this.listDominioTipoAlternativa = response;
        this.listDominioTipoAlternativa.forEach((t) => {
          const value = t.value;

          switch (value) {
            case 'OBJETIVA':
              t.AliasValue = 'Objetiva';
              break;
            case 'MULTIPLA_ESCOLHA':
              t.AliasValue = 'Múltipla Escolha';
              break;
            case 'VERDADEIRO_OU_FALSO':
              t.AliasValue = 'Verdadeiro ou Falso';
              break;
            default:
              break;
          }
        });
      });

    this.page = 1;
    this.totalSize = 0;
    this.messageError = new MessageError();

    this.changeTipoAlternativa();
  }

  submit(): void {
    const jsonQuestao: ModeloDiagnosticoQuestao =
      this.formModeloDiagnosticoQuestoes.value;
    jsonQuestao.idModeloDiagnostico = this.idModeloDiagnostico;
    jsonQuestao.usuario = this.utils.getDtnUserWrapper().getNomeUsuario();

    if (this.id) {
      this.modeloDiagnosticoVariavelService
        .update(this.id, jsonQuestao)
        .subscribe(
          (response) => {
            this.tratarMensagemSucesso('alterado', this.id);
          },
          (errorResponse) => {
            this.tratarMensagemErro('alterar', errorResponse);
          }
        );
    } else {
      this.modeloDiagnosticoVariavelService.save(jsonQuestao).subscribe(
        (response) => {
          if (response) {
            this.tratarMensagemSucesso('cadastrado', response.id);
          }
        },
        (errorResponse) => {
          this.tratarMensagemErro('cadastrar', errorResponse);
        }
      );
    }
  }

  abrirAlternativas(id: number, questao: string, ordem: string) {
    const modalRef = this.modalAlternativaService.open(
      ModeloDiagnosticoAlternativaComponent,
      { backdrop: 'static', size: 'lg' }
    );
    modalRef.componentInstance.questaoId = id;
    modalRef.componentInstance.questao = questao;
    modalRef.componentInstance.ordem = ordem;
  }

  carregarParaAlterar(id: number) {
    if (id) {
      this.id = id;
      this.modeloDiagnosticoVariavelService.findById(id).subscribe(
        (response) => {
          this.formModeloDiagnosticoQuestoes.patchValue({
            ordem: response.ordem,
            dominioIdTipoAlternativa: response.dominioIdTipoAlternativa,
            questao: response.questao,
            quantidadeAlternativaCorreta: response.quantidadeAlternativaCorreta,
          });
        },
        (errorResponse) => {
          this.tratarMensagemErro('buscar', errorResponse);
          this.limparForm();
        },
        () => this.changeTipoAlternativa()
      );
    }
  }

  buscar(id?: number): void {
    this.limparForm();

    const modeloDiagnosticoQuestao: ModeloDiagnosticoQuestao = id
      ? { id: id }
      : this.formModeloDiagnosticoVariavelDadosFind.value;
    modeloDiagnosticoQuestao.idModeloDiagnostico = this.idModeloDiagnostico;
    this.modeloDiagnosticoVariavelService
      .find(modeloDiagnosticoQuestao, this.page - 1, this.pageSize)
      .subscribe(
        (response) => {
          this.listQuestao = response.list;
          this.totalSize = response.totalSize;
        },
        (error) => {
          if (error.status == 0) {
            this.modalService.open({
              type: EnumModalType.ERROR,
              message:
                'Ocorreu um erro ao listar questão do modelo diagnóstico',
              buttons: [EnumModalButtons.OK],
            });
          }
          this.listQuestao = new Array();
        }
      );
  }

  remover(id: number): void {
    this.modalService.open({
      buttons: [EnumModalButtons.YES, EnumModalButtons.NO],
      message:
        'Deseja realmente excluir a questão e, se houver, suas alternativas?',
      type: EnumModalType.ALERT,
      yesEvent: () =>
        this.modeloDiagnosticoVariavelService.remove(id).subscribe(
          (response) => this.tratarMensagemSucesso('foi removida', null),
          (errorResponse) => this.tratarMensagemErro('remover', errorResponse)
        ),
    });
  }

  changeTipoAlternativa(): void {
    const tipoAlternatvaSelecionado: DominioIdTipoAlternativa =
      this.questoesForm['dominioIdTipoAlternativa'].value;

    const defaultValidators: ValidatorFn[] = [
      Validators.min(1),
      Validators.pattern('^[0-9]*'),
    ];

    this.questoesForm['quantidadeAlternativaCorreta'].clearValidators();
    this.questoesForm['quantidadeAlternativaCorreta'].enable();

    switch (+tipoAlternatvaSelecionado) {
      case DominioIdTipoAlternativa.OBJETIVA:
      case DominioIdTipoAlternativa.VERDADEIRO_OU_FALSO:
        this.questoesForm['quantidadeAlternativaCorreta'].setValue('');
        this.questoesForm['quantidadeAlternativaCorreta'].disable();
        break;
      case DominioIdTipoAlternativa.MULTIPLA_ESCOLHA:
        this.questoesForm['quantidadeAlternativaCorreta'].setValidators([
          ...defaultValidators,
          Validators.required,
        ]);
        break;
      default:
        this.questoesForm['quantidadeAlternativaCorreta'].setValidators(
          defaultValidators
        );
        break;
    }

    this.questoesForm['quantidadeAlternativaCorreta'].updateValueAndValidity();
  }

  limparForm() {
    this.id = null;
    this.formModeloDiagnosticoQuestoes.patchValue({
      ordem: '',
      dominioIdTipoAlternativa: '',
      questao: '',
      quantidadeAlternativaCorreta: '',
    });
    this.cleanValidatorsError();
  }

  private tratarMensagemSucesso(acao: string, id: number) {
    this.modalService.open({
      type: EnumModalType.SUCCESS,
      message: `Questão do modelo diagnóstico ${acao} com sucesso`,
      buttons: [EnumModalButtons.OK],
      okEvent: () => {
        this.limparForm();
        this.buscar(id);
      },
    });
  }

  private tratarMensagemErro(acao: string, errorResponse: any) {
    switch (errorResponse.status) {
      case 400:
      case 404:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: errorResponse.error.errors.join('. '),
          buttons: [EnumModalButtons.OK],
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: `Não foi possível ${acao} questão do modelo diagnóstico`,
          buttons: [EnumModalButtons.OK],
          okEvent: () => {
            this.buscar();
          },
        });
        break;
    }
  }

  get questoesForm() {
    return this.formModeloDiagnosticoQuestoes.controls;
  }

  get questaoObjetiva() {
    return DominioIdTipoAlternativa.OBJETIVA.valueOf();
  }

  private cleanValidatorsError() {
    this.formModeloDiagnosticoQuestoes.markAsPristine();
    this.formModeloDiagnosticoQuestoes.markAsUntouched();
  }
}
