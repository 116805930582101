<div class="row">
  <div class="col-md-12 text-right">
    <button class="btn btn-outline-info mb-2" type="button" (click)="print()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
        <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
      </svg>
      Imprimir Relatório
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="jumbotron" style="text-align: center;">
      <h4>Relatório de Quantidade de Escolas Atendidas</h4>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <h5>Parâmetros do Relatório</h5>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <table class="table table-striped table-condensed">
      <thead>
        <th>Relatório</th>
        <th>Mês</th>
        <th>Período</th>
        <th>Série</th>
        <th>Tipo de Escola</th>
      </thead>
      <tbody>
        <tr>
          <td>{{agrupamentoRelatorio[parametros.agrupamentoRelatorio]}}</td>
          <td>{{(parametros.mes ? meses[parametros.mes] : 'Todos')}}</td>
          <td>{{(parametros.periodo ? periodo[parametros.periodo] : 'Todos')}}</td>
          <td>{{getParamDescription(parametros.serie, series)}}</td>
          <td>{{getParamDescription(parametros.tipoEscola, tipoEscola)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <table class="table table-striped table-hover table-condensed">
      <thead>
        <th>Tipo de dados</th>
        <th>Municipal</th>
        <th>Estadual</th>
        <th>Privada</th>
        <th>Especial</th>
        <th>Total</th>
      </thead>
      <tbody>
        <tr *ngFor="let dado of dados$ | async">
          <td>{{dado.tipoDeDados}}</td>
          <td>{{getCellData(dado.valores, tipoEscola.Municipal)}}</td>
          <td>{{getCellData(dado.valores, tipoEscola.Estadual)}}</td>
          <td>{{getCellData(dado.valores, tipoEscola.Privada)}}</td>
          <td>{{getCellData(dado.valores, tipoEscola.Especial)}}</td>
          <td>{{dado.total}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="jumbotron" style="text-align: center;">
      <h3>Gráfico do Relatório</h3>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div id="chartContainer" class="d-flex" *ngIf="parametros.agrupamentoRelatorio == agrupamentoRelatorio.Anual">
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical" [(activeId)]="abaAtiva">
        <li ngbNavItem *ngFor="let aba of abas" [ngbNavItem]="aba.key">
          <a ngbNavLink>{{grupoMeses[aba.key]}}</a>
          <ng-template ngbNavContent>
            <app-escolas-atendidas-chart
              [mes]="parametros.mes"
              [ano]="parametros.ano"
              [data]="aba.value"
              [tipoMes]="aba.key"></app-escolas-atendidas-chart>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="col-md-10"></div>
    </div>
    <div id="chartContainer" *ngIf="parametros.agrupamentoRelatorio == agrupamentoRelatorio.Mensal && abas[0]"> 
      <app-escolas-atendidas-chart
        [mes]="parametros.mes"
        [ano]="parametros.ano"
        [data]="abas[0]?.value"
        [tipoMes]="abas[0]?.key">
      </app-escolas-atendidas-chart>
    </div>
    <h3 *ngIf="!abas[0]" class="d-flex justify-content-center align-items-center mb-5">Sem Dados</h3>
  </div>
</div>
