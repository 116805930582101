<div class="container">
    <div class="jumbotron" style="text-align: center;">
        <h3>Detranzinho - Cadastro Endereço</h3>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="text-right" >
                <button class="btn btn-outline-info btn-sm mr-2" (click)="print()" [disabled]="!escolaAtualizada">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                        <path d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
                        <path fill-rule="evenodd" d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
                        <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                    </svg>
                    Imprimir Diagnóstico
                </button>
                <button class="btn btn-outline-info btn-sm" (click)="printBlank()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                        <path d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
                        <path fill-rule="evenodd" d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
                        <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                    </svg>
                    Imprimir em branco
                </button>
            </div>
        </div>
    </div>
    <ngb-tabset [destroyOnHide]="false" class="mt-5">
        <ngb-tab title="Endereço/Contato">
            <ng-template ngbTabContent>
                <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
                    <fieldset class="form-group border p-2">
                        <fieldset class="form-group border p-2">
                            <legend class="w-auto">Endereço</legend>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="escola" class="col-md-2 col-form-label">Escola</label>
                                        <div class="col-md-9">
                                            <input  type="text" class="form-control col-md-12"
                                                formControlName="escola">
                                            <div class="text-danger" *ngIf="f.escola.invalid && (f.escola.dirty || f.escola.touched)">
                                                <small *ngIf="f.escola.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="cnpj" class="col-md-2 col-form-label">CNPJ</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-12" formControlName="cnpj" [textMask]="{mask: MASKS.cnpj.textMask }">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="situacao" class="col-md-2 col-form-label">Situação</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-12"
                                                formControlName="situacao">
                                            <div class="text-danger" *ngIf="f.situacao.invalid && (f.situacao.dirty || f.situacao.touched)">
                                                <small *ngIf="f.situacao.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="tipo" class="col-md-2 col-form-label">Tipo*</label>
                                        <div class="col-md-3">
                                            <select class="custom-select" formControlName="dominioIdTipo">
                                                <option value="" selected>--</option>
                                                <option *ngFor="let dominio of listDominioEscolaTipo" value="{{dominio.key}}">{{dominio.value}}</option>
                                            </select>
                                            <div class="text-danger" *ngIf="f.dominioIdTipo.invalid && (f.dominioIdTipo.dirty || f.dominioIdTipo.touched)">
                                                <small *ngIf="f.dominioIdTipo.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="cep" class="col-md-2 col-form-label">CEP*</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-12" formControlName="cep" [textMask]="{mask: MASKS.cep.textMask }" (blur)="focus.cep = false" (focus)="focus.cep = true">
                                            <div class="text-danger" *ngIf="f.cep.invalid && (f.cep.dirty || f.cep.touched)">
                                                <small *ngIf="f.cep.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                                <small *ngIf="f.cep.errors.minlength">
                                                    {{messageError.campoInvalido}}</small>
                                                <small *ngIf="f.cep.errors.cep && !focus.cep">
                                                    {{messageError.cepInvalido}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="enderco" class="col-md-2 col-form-label">Endereço*</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control col-md-12" formControlName="endereco">
                                            <div class="text-danger" *ngIf="f.endereco.invalid && (f.endereco.dirty || f.endereco.touched)">
                                                <small *ngIf="f.endereco.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="numero" class="col-md-2 col-form-label">Número*</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-12" formControlName="numero">
                                            <div class="text-danger" *ngIf="f.numero.invalid && (f.numero.dirty || f.numero.touched)">
                                                <small *ngIf="f.numero.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="bairro" class="col-md-2 col-form-label">Bairro*</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control col-md-12" formControlName="bairro">
                                            <div class="text-danger" *ngIf="f.bairro.invalid && (f.bairro.dirty || f.bairro.touched)">
                                                <small *ngIf="f.bairro.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="complemento" class="col-md-2 col-form-label">Complemento</label>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control col-md-12"
                                                formControlName="complemento">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="uf" class="col-md-2 col-form-label">UF*</label>
                                        <div class="col-md-3">
                                            <select id="ufSelect" class="custom-select" formControlName="uf" >
                                                <option *ngFor="let dominio of listUF" value="{{dominio.key}}">
                                                    {{dominio.value}}</option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="municipio" class="col-md-2 col-form-label">Município*</label>
                                        <div class="col-md-3">
                                            <select id="municipioSelect" class="custom-select"
                                                formControlName="municipio" >
                                                <option *ngFor="let cidade of listMunicipio" value="{{cidade.key}}">
                                                    {{cidade.value}}</option>
                                            </select>
                                            <div class="text-danger" *ngIf="f.municipio.invalid && (f.municipio.dirty || f.municipio.touched)">
                                                <small *ngIf="f.municipio.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="form-group border p-2">
                            <legend class="w-auto">Contato</legend>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="diretorNome" class="col-md-2 col-form-label">Nome Diretor</label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control col-md-12"
                                                formControlName="diretorNome">
                                        </div>
                                        <label for="diretorCelular" class="col-md-3 col-form-label">Celular Diretor</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-10"
                                                formControlName="diretorCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.diretorCelular = false" (focus)="focus.diretorCelular = true">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="coordenadorNome"
                                            class="col-md-2 col-form-label">Nome Coordenador*</label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control col-md-12"
                                                formControlName="coordenadorNome">
                                            <div class="text-danger" *ngIf="f.coordenadorNome.invalid && (f.coordenadorNome.dirty || f.coordenadorNome.touched)">
                                                <small *ngIf="f.coordenadorNome.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                            </div>
                                        </div>
                                        <label for="coordenadorCelular" class="col-md-3 col-form-label">Celular Coordenador*</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-10"
                                                formControlName="coordenadorCelular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.coordenadorCelular = false" (focus)="focus.coordenadorCelular = true">
                                            <div class="text-danger"
                                                *ngIf="f.coordenadorCelular.invalid && (f.coordenadorCelular.dirty || f.coordenadorCelular.touched)">
                                                <small *ngIf="f.coordenadorCelular.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                                <small *ngIf="f.coordenadorCelular.errors.celular && !focus.coordenadorCelular">
                                                    {{messageError.celularInvalido}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="telefone" class="col-md-2 col-form-label">Telefone Escola*</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-10" formControlName="telefone" #telefone [textMask]="{mask: MASKS.telefone.textMaskFunction(telefone.value) }" (blur)="focus.telefone = false" (focus)="focus.telefone = true">
                                            <div class="text-danger" *ngIf="f.telefone.invalid && (f.telefone.dirty || f.telefone.touched)">
                                                <small *ngIf="f.telefone.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                                <small *ngIf="f.telefone.errors.telefone && !focus.telefone">
                                                    {{messageError.telefoneInvalido}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="celular" class="col-md-2 col-form-label">Celular Escola*</label>
                                        <div class="col-md-3">
                                            <input type="text" class="form-control col-md-10" formControlName="celular" [textMask]="{mask: MASKS.celular.textMask }" (blur)="focus.celular = false" (focus)="focus.celular = true">
                                            <div class="text-danger" *ngIf="f.celular.invalid && (f.celular.dirty || f.celular.touched)">
                                                <small *ngIf="f.celular.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                                <small *ngIf="f.celular.errors.celular && !focus.celular">
                                                    {{messageError.celularInvalido}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row align-items-center">
                                <div class="col-12">
                                    <div class="form-group row">
                                        <label for="email" class="col-md-2 col-form-label">E-mail Escola*</label>
                                        <div class="col-md-4">
                                            <input type="text" class="form-control col-md-12" formControlName="email" (blur)="focus.email = false" (focus)="focus.email = true">
                                            <div class="text-danger" *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                                                <small *ngIf="f.email.errors.required">
                                                    {{messageError.campoObrigatorio}}</small>
                                                <small *ngIf="f.email.errors.email && !focus.email">
                                                    {{messageError.emailInvalido}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </fieldset>
                    <div class="col-12 mb-5">

                        <button [disabled]="!formulario.valid" type="submit" class="btn btn-success float-right" onclick="this.blur()">
                            <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
                            </svg>
                            Salvar
                        </button>
                        <button  type="button" class="btn btn-outline-secondary" routerLink="/escola/list">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path fill-rule="evenodd"
                                    d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                            Voltar
                        </button>

                    </div>
                </form>
            </ng-template>
        </ngb-tab>
        <ngb-tab [disabled]="!this.f.id.value">
            <ng-template ngbTabTitle>Diagnóstico</ng-template>
            <ng-template ngbTabContent>
                <app-diagnostico-form></app-diagnostico-form>
            </ng-template>
        </ngb-tab>
        <ngb-tab [disabled]="!this.f.id.value">
            <ng-template ngbTabTitle>Termo de Compromisso</ng-template>
            <ng-template ngbTabContent>
                <app-termo-compromisso-form></app-termo-compromisso-form>
            </ng-template>
        </ngb-tab>
        <ngb-tab [disabled]="!this.f.id.value">
            <ng-template ngbTabTitle>Histórico Diagnóstico</ng-template>
            <ng-template ngbTabContent>
                <app-diagnostico-historico-form [idEscola]="idEscola"></app-diagnostico-historico-form>
            </ng-template>
        </ngb-tab>
        
    </ngb-tabset>


</div>