import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EscolaListComponent } from './escola-list/escola-list.component';
import { EnderecoFormComponent } from './endereco-form/endereco-form.component';

const routes: Routes = [
  { path: 'escola', 
  children: [
    { path: '', redirectTo: '/escola/list', pathMatch: 'full' },
    { path: 'list', component: EscolaListComponent },
    { path: 'endereco', component: EnderecoFormComponent },
    { path: 'endereco/:idEscola', component: EnderecoFormComponent }
  ]}, 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EscolaRoutingModule { }
