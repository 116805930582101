<div class="jumbotron" style="text-align: center;">
  <h3>Aprovar Modelo Diagnóstico</h3>
</div>

<app-diagnostico-form [paramIdModeloDiagnostico]="this.modeloId"></app-diagnostico-form>

<div class="col-12 mb-5">

  <button id="btnAprovar" class="btn btn-success ml-4 float-right" type="button" (click)="aprovar()">
    <svg width="1.6em" height="1.6em" viewBox="0 0 16 16" class="bi bi-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
    </svg>
    Aprovar
  </button>
  <button id="btnReprovar" class="btn btn-danger float-right" type="button" (click)="reprovar()">
    <svg width="1.6em" height="1.7em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>
    Reprovar
  </button>
    <button  type="button" class="btn btn-outline-secondary" routerLink="../">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle"
          fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path fill-rule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
      </svg>
      Voltar
  </button>
</div>
