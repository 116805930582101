import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { callbackify } from 'util';
import { EnumModalButtons, EnumModalType } from '../template/modal/enum-modal';
import { ModalParameters } from '../template/modal/modal-parameters';
import { ModalComponent } from '../template/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalUiService {

  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  open(param: ModalParameters) {

    this.modalRef = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true
    })
    this.modalRef.componentInstance.type = param.type;
    this.modalRef.componentInstance.message = param.message;
    this.modalRef.componentInstance.buttons = param.buttons;
    this.modalRef.componentInstance.hideClose = param.hideClose;
    this.modalRef.result.then(result => {

      const click: EnumModalButtons = EnumModalButtons[result as keyof typeof EnumModalButtons];
      switch (click) {
        case EnumModalButtons.OK:
          if (param.okEvent) param.okEvent();
          break;
        case EnumModalButtons.YES:
          if (param.yesEvent) param.yesEvent();
          break;
        case EnumModalButtons.NO:
          if (param.noEvent) param.noEvent();
          break;
        case EnumModalButtons.CANCEL:
          if (param.cancelEvent) param.cancelEvent();
          break;
      }

    });
  }
}
