export enum EnumModalType{
    ALERT,
    ERROR,
    SUCCESS
}

export enum EnumModalButtons {
    OK,
    CANCEL,
    YES,
    NO
}