<div class="row mt-2">
    <div class="col-12">
        <div class="text-right">
            <button class="btn btn-outline-info btn-sm mr-2" (click)="print()" onclick="this.blur()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer"
                    viewBox="0 0 16 16">
                    <path
                        d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z" />
                    <path fill-rule="evenodd"
                        d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z" />
                    <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                </svg>
                Imprimir Checklist
            </button>
        </div>
    </div>
</div>
<div class="alert alert-dark text-center mt-3" role="alert">
    Responsável
</div>
<form [formGroup]="formChecklist" (submit)="submit()">
    <table class="table table-condensed table-hover table-striped" formGroupName="responsavel">
        <thead>
            <tr>
                <th style="width: 5%;">#</th>
                <th>Nome</th>
                <th style="width: 16%;">Funçao</th>
                <th style="width: 5%;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let responsavel of listReponsavel; let i = index">
                <td>{{i+1}}</td>
                <td>{{responsavel.nome}}</td>
                <td>{{responsavel.funcao}}</td>
                <td>
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" *ngIf="!disableAll"
                        [formControlName]="'responsavel_'+responsavel.id+'_presente'">
                        <label ngbButtonLabel class="btn-outline-danger">
                            <input ngbButton type="radio" [value]="dominioAlunoReponsavelSituacao.Ausente"
                                onclick="this.blur()"> Ausente
                        </label>
                        <label ngbButtonLabel class="btn-outline-success">
                            <input ngbButton type="radio" [value]="dominioAlunoReponsavelSituacao.Presente"
                                onclick="this.blur()"> Presente
                        </label>
                    </div>
                    <div *ngIf="disableAll">
                        <label ngbButtonLabel class="alert-success" *ngIf="responsavel.dominioIdSituacao == dominioAlunoReponsavelSituacao.Presente">Presente</label>
                        <label ngbButtonLabel class="alert-danger" *ngIf="responsavel.dominioIdSituacao == dominioAlunoReponsavelSituacao.Ausente">Ausente</label>
                    </div>
                </td>
            </tr>
            <tr *ngIf="listReponsavel.length == 0">
                <td colspan="4" align="center">
                    Nenhum registro encontrado.
                </td>
            </tr>
        </tbody>
    </table>
    <div class="alert alert-dark text-center" role="alert">
        Aluno
    </div>
    <table class="table table-condensed table-hover table-striped" formGroupName="aluno">
        <thead>
            <tr>
                <th style="width: 5%;">#</th>
                <th>Nome</th>
                <th style="width: 8%;">Série</th>
                <th style="width: 8%;">Turma</th>
                <th style="width: 5%;"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let aluno of listAluno; let i = index">
                <td>{{i+1}}</td>
                <td>{{aluno.nome}}</td>
                <td>{{aluno.serie}}</td>
                <td>{{aluno.turmaSerie}}</td>
                <td>
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" *ngIf="!disableAll"
                        [formControlName]="'aluno_'+aluno.id+'_presente'">
                        <label ngbButtonLabel class="btn-outline-danger">
                            <input ngbButton type="radio" [value]="dominioAlunoReponsavelSituacao.Ausente"
                                onclick="this.blur()"> Ausente
                        </label>
                        <label ngbButtonLabel class="btn-outline-success">
                            <input ngbButton type="radio" [value]="dominioAlunoReponsavelSituacao.Presente"
                                onclick="this.blur()"> Presente
                        </label>
                    </div>
                    <div *ngIf="disableAll">
                        <label ngbButtonLabel class="alert-success" *ngIf="aluno.dominioIdSituacao == dominioAlunoReponsavelSituacao.Presente">Presente</label>
                        <label ngbButtonLabel class="alert-danger" *ngIf="aluno.dominioIdSituacao == dominioAlunoReponsavelSituacao.Ausente">Ausente</label>
                    </div>
                </td>
            </tr>
            <tr *ngIf="listAluno.length == 0">
                <td colspan="4" align="center">
                    Nenhum registro encontrado.
                </td>
            </tr>
        </tbody>
    </table>


    <button type="submit" onclick="this.blur()" class="btn btn-success ml-2 mb-5 float-right" *ngIf="!disableAll" [disabled]="formChecklist.invalid">
        <svg height="1em" viewBox="0 0 512.007 512.007" width="1em" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="m511.927 126.537c-.279-2.828-1.38-5.666-3.315-8.027-.747-.913 6.893 6.786-114.006-114.113-2.882-2.882-6.794-4.395-10.612-4.394-9.096 0-329.933 0-338.995 0-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 .001-364.186.041-339.316-.072-340.466zm-166.927-96.534v98c0 8.271-6.729 15-15 15h-19v-113zm-64 0v113h-139c-8.271 0-15-6.729-15-15v-98zm64 291h-218v-19c0-8.271 6.729-15 15-15h188c8.271 0 15 6.729 15 15zm-218 161v-131h218v131zm355-15c0 8.271-6.729 15-15 15h-92c0-19.555 0-157.708 0-180 0-24.813-20.187-45-45-45h-188c-24.813 0-45 20.187-45 45v180h-52c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h52v98c0 24.813 20.187 45 45 45h188c24.813 0 45-20.187 45-45v-98h2.787l104.213 104.214z" />
        </svg>
        Salvar
    </button>

</form>