<div class="row">
  <div class="col-md-12">
    <h3 class="d-flex justify-content-center align-items-center">{{titulo}}</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div id="chart">
      <canvas baseChart
        [datasets]="chart.datasets"
        [labels]="chart.labels"
        [options]="chart.options"
        [chartType]="chart.type"
        [colors]="chart.colors"
        [legend]="chart.legend"
        *ngIf="!chart.noData">
      </canvas>
      <h3 *ngIf="chart.noData" class="d-flex justify-content-center align-items-center">Sem Dados</h3>
    </div>
  </div>
</div>
