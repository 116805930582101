import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { NgBrazil } from 'ng-brazil';
import { ChartsModule } from 'ng2-charts';
import { ServiceLocator } from 'src/app/services/locator.service';
import { UtilityService } from 'src/app/services/utility.service';
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';
import { GLOBAL } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgendamentoModule } from './modules/agendamento/agendamento.module';
import { CalendarioModule } from './modules/calendario/calendario.module';
import { EscolaModule } from './modules/escola/escola.module';
import { ImpressoesModule } from './modules/impressoes/impressoes.module';
import { ModeloDiagnosticoModule } from './modules/modelo-diagnostico/modelo-diagnostico.module';
import { RelatoriosModule } from './modules/relatorios/relatorios.module';
import { TurmaModule } from './modules/turma/turma.module';
import { MyInterceptor } from './services/interceptor';
import { ServicesModule } from './services/services.module';
import { TemplateModule } from './template/template.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModeloTermoModule } from './modules/modelo-termo/modelo-termo.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServicesModule,
    TemplateModule,
    EscolaModule,
    ModeloDiagnosticoModule,
    CalendarioModule,
    AgendamentoModule,
    TurmaModule,
    ImpressoesModule,
    RelatoriosModule,
    ModeloTermoModule,
    NgbModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      message: 'Carregando ...',
    }),
    BlockUIHttpModule.forRoot(),
    NgBrazil,
    ChartsModule,
    NoopAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private utils: UtilityService, private injector: Injector) {
    console.log('AppModule.constructor()');

    ServiceLocator.injector = this.injector;

    var token = utils.getUid();
    var dtnUserWrapper = new DtnAuthUserWrapper(token);

    GLOBAL.dtnuser = token;
    GLOBAL.wrapper = dtnUserWrapper;
  }
}
