import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { ModeloTermoCompromissoService } from 'src/app/services/modelo-termo.service';
import { UtilityService } from 'src/app/services/utility.service';

export interface DialogMotivoReprovacaoData {
  motivoReprovacao: string;
}

@Component({
    selector: 'app-modelo-termo-aprovacao',
    templateUrl: './modelo-termo-aprovacao.component.html',
    styleUrls: ['./modelo-termo-aprovacao.component.css']
})

export class  ModeloTermoAprovacaoComponent implements OnInit {
    modeloId: number;
    formModeloTermoTexto: FormGroup;
    motivoReprovacao: string;
    
    constructor (
        private modeloTermoService: ModeloTermoCompromissoService, 
        private route: ActivatedRoute,
        private router: Router, 
        private messageService: MessageService, 
        private utils: UtilityService, 
        private fb: FormBuilder,
        public dialog: MatDialog
    ) {
      this.formModeloTermoTexto = this.fb.group({
//      cabecalho: [''],
      responsabilidadeCidadeEscolaTransito: [''],
      responsabilidadeEscola: [''],
      infoPeriodoMatutino: [''],
      infoPeriodoVespetino: [''],
      infoHorarioSaidaDoOnibus: [''],
      infoSeriesAtendidas: [''],
      infoTurmas: [''],
      observacao: [''],
      maioresInformacoes: [''],
      input1: [''],
      input2: [''],
      informacoesSobreVisita: ['']
    });
    }
    
    ngOnInit(): void {
   
    this.modeloId = +this.route.snapshot.params['id'];
   
   const replaceBrWithNewline = (str: string) => str.replace(/<br\s*\/?>/gi, '\n');
   
    this.modeloTermoService.findById(this.modeloId).subscribe(data => {
      this.formModeloTermoTexto.patchValue({
        responsabilidadeCidadeEscolaTransito: replaceBrWithNewline(data.responsabilidadeCidadeEscolaTransito),
        responsabilidadeEscola: replaceBrWithNewline(data.responsabilidadeEscola),
        infoPeriodoMatutino: replaceBrWithNewline(data.infoPeriodoMatutino),
        infoPeriodoVespetino: replaceBrWithNewline(data.infoPeriodoVespetino),
        infoHorarioSaidaDoOnibus: replaceBrWithNewline(data.infoHorarioSaidaDoOnibus),
        infoSeriesAtendidas: replaceBrWithNewline(data.infoSeriesAtendidas),
        infoTurmas: replaceBrWithNewline(data.infoTurmas),
        observacao: replaceBrWithNewline(data.observacao),
        maioresInformacoes: replaceBrWithNewline(data.maioresInformacoes),
        input1: replaceBrWithNewline(data.input1),
        input2: replaceBrWithNewline(data.input2),
        informacoesSobreVisita: replaceBrWithNewline(data.infoSobreVisita)
      });

      
    });
   
    }
    
    aprovar() {
        if(this.modeloId) {
            this.modeloTermoService.approve(this.modeloId, this.utils.getDtnUserWrapper().getNomeUsuario()).subscribe(response => {
                this.messageService.tratarMensagemSucesso('Modelo do Termo de Compromisso aprovado com sucesso.',
                    true, () => { this.router.navigate(["../"], { relativeTo: this.route }); });
            }, errorResponse => {
                this.messageService.tratarMensagemErro('Não foi possível aprovar o Modelo do Termo de Compromisso.',
                true, errorResponse, () => {});
            });
        }
    }
    
    reprovar() {
        if(this.modeloId) {
            this.modeloTermoService.reprove(this.modeloId, this.utils.getDtnUserWrapper().getNomeUsuario(), this.motivoReprovacao).subscribe(response => {
                this.messageService.tratarMensagemSucesso('Modelo do Termo de Compromisso reprovado com sucesso.',
                    true, () => { this.router.navigate(["../"], { relativeTo: this.route }); });
            }, errorResponse => {
                this.messageService.tratarMensagemErro('Não foi possível reprovar o Modelo do Termo de Compromisso.',
                    true, errorResponse, () => {});
            });
        }
    }
    
    openDialogMotivoReprovacao(): void {
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
          width: '600px', 
          data: {motivoReprovacao: this.motivoReprovacao}
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          if(result != null) {
              this.motivoReprovacao = result;
              this.reprovar();
          }
          
        });
      }
}

@Component({
  selector: 'dialog-motivo-reprovacao',
  templateUrl: 'dialog-motivo-reprovacao.html',
})
export class DialogOverviewExampleDialog {

    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogMotivoReprovacaoData
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    reprovar(): void {
        this.dialogRef.close(this.data.motivoReprovacao);
    }
}