import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AgendamentoTurma } from "src/app/models/agendamento-turma";
import { Dominio } from "src/app/models/dominio";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { DominioService } from "src/app/services/dominio.service";
import { MessageService } from "src/app/services/message.service";
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-autorizacao',
  templateUrl: './autorizacao.component.html',
  styleUrls: ['./autorizacao.component.css']
})
export class AutorizacaoComponent implements OnInit {
  formFiltro: FormGroup;

  dominioPeriodos: Dominio[];
  agendamentos: AgendamentoTurma[];
  dtnUserWrapper: DtnAuthUserWrapper;

  page: number;
  pageSize: number;
  totalSize: number;

  constructor(private agendamentoService: AgendamentoService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private dominioService: DominioService,
    private utils: UtilityService) {

    console.log('AutorizacaoComponent - constructor');

    this.agendamentos = [];
    this.page = 1;
    this.pageSize = 8;
    this.totalSize = 0;
    this.dtnUserWrapper = utils.getDtnUserWrapper();
  }

  ngOnInit(): void {
    this.formFiltro = this.fb.group({
      dataAgendada: [''],
      numeroTurma: [''],
      dominioIdPeriodo: [''],
      escolaId: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? this.dtnUserWrapper.getInstituicaoEscola().getId() : [''],
      escola: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? this.dtnUserWrapper.getInstituicaoEscola().getNome() : ['']
    });

    this.dominioService.getDominioPeriodo()
      .subscribe(data => {
        this.dominioPeriodos = data;
      });
    this.search();
  }

  search() {
      console.log('AutorizacaoComponent - filters: ', this.formFiltro.getRawValue());
    this.agendamentoService.listAutorizacaoImpressao(this.formFiltro.getRawValue(), (this.page - 1), this.pageSize)
      .subscribe(data => {
        this.agendamentos = data.list;
        this.totalSize = data.totalSize;
      }, err => {
        this.messageService.tratarMensagemErro(
          'Não foi possível recuperar os agendamentos para impressão das autorizações.',
          false, err, () => {});
      })
  }

  print(agendamentoId: number) {
    this.agendamentoService.printAutorizacoes(agendamentoId)
      .subscribe((response) => {
        let blob = new Blob([response], { type: 'application/pdf' });
        let downloadURL = window.URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Autorizacoes.pdf";
        link.click();
      }, (errorResponse) => {
        this.messageService.tratarMensagemErro(
          "Não foi possível gerar a impressão",
          true,
          errorResponse,
          () => {});
      });
  }
}
