import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Agenda } from 'src/app/models/agenda';
import { CalendarioStateService } from 'src/app/services/calendario-state.service';
import { CalendarioStateEnum } from 'src/app/utils/calendario-state.enum';

@Component({
    selector: 'app-calendario',
    templateUrl: './calendario-form.component.html',
    styleUrls: ['./calendario-form.component.css']
})
export class CalendarioFormComponent implements OnInit, AfterViewInit {
    agendaMes: Agenda;
    tabAtiva: string;

    @ViewChild('tabset') tab;

    constructor(private stateService: CalendarioStateService) {
        if (this.stateService.state == CalendarioStateEnum.Visualizacao) {
            this.agendaMes = this.stateService.mes;
            this.tabAtiva = "datas";
        }
    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        if (this.stateService.state != CalendarioStateEnum.Visualizacao)
            this.stateService.updateState(CalendarioStateEnum.Calendario);
    }

    setAgendaMes(agenda: Agenda) {
        this.agendaMes = agenda;
        this.stateService.mes = agenda;
    }

    onSelectTab(selectedTab: string) {
        this.stateService.updateState(selectedTab == 'datas' ? CalendarioStateEnum.Calendario : CalendarioStateEnum.Datas);
    }
}
