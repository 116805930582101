import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Escola } from '../models/escola';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
    providedIn: 'root'
})
export class EscolaService {

    constructor(
        private http: HttpClient
    ) { }

    find(escola: any, page: number, pageSize: number): Observable<ReponsePaginationListDTO<Escola>> {

        let httpParams = new HttpParams();//{fromObject: escola}
        Object.keys(escola).forEach(
            key => escola[key] && (httpParams = httpParams.append(key, escola[key]))
        );
        httpParams = httpParams.append("page", page.toString());
        httpParams = httpParams.append("pageSize", pageSize.toString());
        return this.http.get<ReponsePaginationListDTO<Escola>>(`${environment.apiBaseUrl}/escola`, {
            params: httpParams
        });

    }
    findById(id: number): Observable<Escola> {


        return this.http.get<Escola>(`${environment.apiBaseUrl}/escola/${id}`, {

        });

    }
}
