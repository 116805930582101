import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EnumModalType, EnumModalButtons } from './enum-modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  providers: [NgbModalConfig, NgbModal]
})
export class ModalComponent implements OnInit {

  cssClassTitle: string;
  title: string;
  message: string;

  type: EnumModalType = EnumModalType.ALERT;
  buttons: EnumModalButtons[] = [];
  hideClose: boolean;

  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit() {
    switch (this.type) {
      case EnumModalType.ALERT:
        this.cssClassTitle = 'text-warning'
        this.title = 'Atenção';
        break;
      case EnumModalType.ERROR:
        this.cssClassTitle = 'text-danger'
        this.title = 'Erro';
        break;
      case EnumModalType.SUCCESS:
        this.cssClassTitle = 'text-success'
        this.title = 'Sucesso';
        break;
    }
  }

  showOkButton() :boolean{
    return this.buttons.some(x => x == EnumModalButtons.OK);
  }
  showYesButton() :boolean{
    return this.buttons.some(x => x == EnumModalButtons.YES);
  }
  showNoButton() :boolean{
    return this.buttons.some(x => x == EnumModalButtons.NO);
  }
  showCancelButton() :boolean{
    return this.buttons.some(x => x == EnumModalButtons.CANCEL);
  }
  
}
