import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AgendamentoCalendarioComponent } from "./agendamento-calendario/agendamento-calendario.component";
import { AgendamentoListComponent } from "./agendamento-list/agendamento-list.component";
import { AgendamentoViewComponent } from "./agendamento-view/agendamento-view.component";
import { ManualComponent } from './manual/manual.component';
import { TermoCompromissoComponent } from "./termo-compromisso/termo-compromisso.component";
import { CalendarioViewComponent } from '../calendario/calendario-view/calendario-view.component';

const routes: Routes = [
    {
        path: 'agendamento',
        children: [
            { path: '', redirectTo: 'agendamento', pathMatch: 'full' },
            { path: '', component: AgendamentoListComponent },
            { path: 'termo-compromisso/:escolaId', component: TermoCompromissoComponent },
            { path: 'criar/:escolaId', component: AgendamentoCalendarioComponent },
            { path: 'visualizar/:id', component: AgendamentoViewComponent },
            { path: 'acessar-manual', component: ManualComponent },
            { path: 'visualizar2/:id/:data/:periodo/:agendamentoId', component: CalendarioViewComponent }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AgendamentoRoutingModule { }
