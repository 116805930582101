<div class="container">
    <div class="jumbotron" style="text-align: center;">
        <h3>Relatórios</h3>
    </div>
    <form [formGroup]="formFiltro" (ngSubmit)="submit()">
        <fieldset class="form-group border p-2">
            <legend class="w-auto">Filtros de Pesquisa</legend>
            <div class="form-row form-group">
                <div class="col-md-12">
                    <div class="form-row mb-3">
                        <label for="tipoRelatorio" class="col-md-2 col-form-label">Tipo do Relatório *</label>
                        <div class="col-md-6">
                            <select class="custom-select" formControlName="tipoRelatorio" required>
                                <option value="" selected>--</option>
                                <option *ngFor="let dominio of tiposRelatorio" [value]="dominio.id">{{dominio.descricao}}</option>
                            </select>
                            <div class="text-danger" *ngIf="(formFiltro.controls.tipoRelatorio.touched || formFiltro.controls.tipoRelatorio.dirty)">
                                <small *ngIf="formFiltro.controls.tipoRelatorio.errors?.required">
                                    {{messageError.campoObrigatorio}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <label class="col-md-2 col-form-label">Relatório *</label>
                        <div class="col-md-3">
                            <fieldset class="border col-md-11">
                                <div class="form-check form-check-inline pl-2">
                                    <input type="radio" id="mensal" name="agrupamentoRelatorio" class="form-check-input"
                                           (change)="enableMesAno(agrupamentoRelatorioEnum.Mensal)" [value]="agrupamentoRelatorioEnum.Mensal"
                                           formControlName="agrupamentoRelatorio" />
                                    <label class="col-form-label" for="mensal">Mensal</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="anual" name="agrupamentoRelatorio" class="form-check-input"
                                           (change)="enableMesAno(agrupamentoRelatorioEnum.Anual)" [value]="agrupamentoRelatorioEnum.Anual"
                                           formControlName="agrupamentoRelatorio" />
                                    <label class="col-form-label" for="anual">Anual</label>
                                </div>
                                <div class="text-danger" *ngIf="(formFiltro.controls.agrupamentoRelatorio.touched || formFiltro.controls.agrupamentoRelatorio.dirty)">
                                    <small *ngIf="formFiltro.controls.agrupamentoRelatorio.errors?.required">
                                        {{messageError.campoObrigatorio}}
                                    </small>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-3">
                            <div class="form-row">
                                <label class="col-md-4 col-form-label" for="mes">Mês <span *ngIf="mesIsRequired">*</span></label>
                                <div class="col-md-8">
                                    <select class="custom-select" id="mes" formControlName="mes">
                                        <option value="" selected>--</option>
                                        <option *ngFor="let mes of meses" [value]="mes.key">{{mes.value}}</option>
                                    </select>
                                    <div class="text-danger" *ngIf="mesIsRequired && !mesHasValue && this.isSubmitted">
                                        <small>
                                            {{messageError.campoObrigatorio}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label class="col-md-1 col-form-label" for="ano">Ano</label>
                        <div class="col-md-2">
                            <input type="number" class="col-md-8 form-control" id="ano" formControlName="ano" />
                        </div>
                    </div>
                    <div class="form-row mb-4">
                        <label class="col-md-2 col-form-label" for="periodo">Período</label>
                        <div class="col-md-3">
                            <select class="custom-select col-md-11" id="periodo" formControlName="periodo">
                                <option value="" selected>Todos</option>
                                <option *ngFor="let periodo of periodos" [value]="periodo.id">{{periodo.descricao}}</option>
                            </select>
                        </div>
                        <label class="col-md-1 col-form-label" for="serie">Série</label>
                        <div class="col-md-2">
                            <select class="custom-select" id="serie" formControlName="serie">
                                <option *ngFor="let serie of series" [value]="serie.key">{{serie.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <label class="col-md-2 col-form-label" for="tipoEscola">Tipo Escola</label>
                        <div class="col-md-3">
                            <select class="custom-select col-md-11" id="tipoEscola" formControlName="tipoEscola">
                                <option value="" selected>Todos</option>
                                <option *ngFor="let tipoEscola of tiposEscola" [value]="tipoEscola.key">{{tipoEscola.value}}</option>
                            </select>
                            <div class="text-danger" *ngIf="(formFiltro.controls.tipoEscola.touched || formFiltro.controls.tipoEscola.dirty)">
                                <small *ngIf="formFiltro.controls.tipoEscola.errors?.required">
                                    {{messageError.campoObrigatorio}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row float-right mr-3">
                        <button class="btn btn-success mr-2" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
                            </svg>
                            Gerar Relatório
                        </button>
                        <button class="btn btn-warning" type="button" (click)="limpar()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                            </svg>
                            Limpar
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
    <ng-template appDynamicComponent></ng-template>
</div>