import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Agendamento } from "../models/agendamento";
import { AgendamentoCancelamento } from "../models/agendamento-cancelamento";
import { AgendamentoDisponibilidade } from "../models/agendamento-disponibilidade";
import { AgendamentoEscola } from "../models/agendamento-escola";
import { AgendamentoTurma } from "../models/agendamento-turma";
import { TermoCompromisso } from "../models/termo-compromisso";
import { TermoCompromissoAssinado } from '../models/termo-compromisso-assinado';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';


@Injectable({
    providedIn: 'root'
})
export class AgendamentoService {

    private readonly BASE_URL: string = `${environment.apiBaseUrl}/agendamento`;

    constructor(private http: HttpClient) { }

    list(escolaId: number, dataAgendada: string, page: number, pageSize: number, dominioIdAgendamentoSituacao?: number): Observable<ReponsePaginationListDTO<AgendamentoEscola>> {
        let httpParams = new HttpParams();

        httpParams = escolaId ? httpParams.append("escolaId",escolaId.toString()) : httpParams;
        httpParams = dataAgendada ? httpParams.append("dataAgendada",  dataAgendada) : httpParams;
        httpParams = dominioIdAgendamentoSituacao ? httpParams.append("dominioIdAgendamentoSituacao", dominioIdAgendamentoSituacao.toString()) : httpParams;
        httpParams = httpParams.append("page", page.toString());
        httpParams = httpParams.append("pageSize", pageSize.toString());

        return this.http.get<ReponsePaginationListDTO<AgendamentoEscola>>(`${environment.apiBaseUrl}/agendamento`, {
            params: httpParams
        });
    }

    find(agendamentoId: number): Observable<AgendamentoCancelamento> {
        return this.http.get<AgendamentoCancelamento>(`${this.BASE_URL}/${agendamentoId}`);
    }

    getInfo(id: number): Observable<TermoCompromisso> {
        return this.http.get<TermoCompromisso>(`${this.BASE_URL}/info/${id}`);
    }
    
    getTermoAssinado(id: number): Observable<TermoCompromisso> {
        return this.http.get<TermoCompromisso>(`${this.BASE_URL}/find-by-id-escola/${id}`);
    }

    acceptTerms(id: number): Observable<any> {
        return this.http.patch<any>(`${this.BASE_URL}/aceitar-termos/${id}`, null);
    }

    printTerm(idEscola: number) {
        return this.http.get(`${this.BASE_URL}/termo/print/pdf/${idEscola}`, { responseType: 'blob' });
    }

    printTermAgendamento(idEscola: number) {
        return this.http.get(`${this.BASE_URL}/termo/print/pdf/${idEscola}`, { responseType: 'arraybuffer' });
    }
    
    assinarTermo(idEscola: number) {
        return this.http.put(`${this.BASE_URL}/termo-assinado/${idEscola}`, null);
    }
    
    availability(): Observable<AgendamentoDisponibilidade[]> {
        return this.http.get<AgendamentoDisponibilidade[]>(`${this.BASE_URL}/disponibilidade`);
    }

    save(agendamento: Agendamento): Observable<Agendamento> {
        return this.http.post<Agendamento>(`${this.BASE_URL}`, agendamento);
    }

    cancel(id: number, usuario: string, isOperadorEscola: boolean): Observable<any> {
        return this.http.patch(`${this.BASE_URL}/${id}`, {usuarioCancelamento: usuario, isOperadorEscola: isOperadorEscola});
    }

    listSeriesAgendamento(idAgendamento: number): Observable<number[]> {
        return this.http.get<number[]>(`${this.BASE_URL}/series/${idAgendamento}`);
    }

    listAgendamentoTurma(agendamentoTurma: AgendamentoTurma): Observable<ReponsePaginationListDTO<AgendamentoTurma>> {
        let httpParams = new HttpParams();
        Object.keys(agendamentoTurma).forEach(
            key => agendamentoTurma[key] && (httpParams = httpParams.append(key, agendamentoTurma[key]))
        );
//        httpParams = httpParams.append("page", page.toString());
//        httpParams = httpParams.append("pageSize", pageSize.toString());

        return this.http.get<ReponsePaginationListDTO<AgendamentoTurma>>(`${this.BASE_URL}/list-turmas`, { params: httpParams });
    }

    listAutorizacaoImpressao(agendamentoTurma: AgendamentoTurma, page: number, pageSize: number): Observable<ReponsePaginationListDTO<AgendamentoTurma>> {
        let httpParams = new HttpParams();
        Object.keys(agendamentoTurma).forEach(
            key => agendamentoTurma[key] && (httpParams = httpParams.append(key, agendamentoTurma[key]))
        );
        httpParams = httpParams.append("page", page.toString());
        httpParams = httpParams.append("pageSize", pageSize.toString());

        return this.http.get<ReponsePaginationListDTO<AgendamentoTurma>>(`${this.BASE_URL}/autorizacoes/list-for-print`, { params: httpParams });
    }

    listEtiquetaImpressao(agendamentoTurma: AgendamentoTurma, page: number, pageSize: number): Observable<ReponsePaginationListDTO<AgendamentoTurma>> {
        let httpParams = new HttpParams();
        Object.keys(agendamentoTurma).forEach(
            key => agendamentoTurma[key] && (httpParams = httpParams.append(key, agendamentoTurma[key]))
        );
        httpParams = httpParams.append("page", page.toString());
        httpParams = httpParams.append("pageSize", pageSize.toString());

        return this.http.get<ReponsePaginationListDTO<AgendamentoTurma>>(`${this.BASE_URL}/etiquetas/list-for-print`, { params: httpParams });
    }

    printAutorizacoes(agendamentoId: number) {
        return this.http.get(`${this.BASE_URL}/${agendamentoId}/autorizacoes/print/pdf`, { responseType: 'blob' });
    }

    printEtiquetas(agendamentoId: number) {
        return this.http.get(`${this.BASE_URL}/${agendamentoId}/etiquetas/print/pdf`, { responseType: 'blob' });
    }

    atualizar(agendaId:number, dominioIdPeriodo:number, usuario:String,agendamentoId: number){
        
        const dados = {
        "agendaDataId": agendaId,
        "dominioIdPeriodo": dominioIdPeriodo,
        "usuario": usuario
        }
        
        return this.http.put(`${this.BASE_URL}/atualizar/${agendamentoId}`,dados );
        
        }

}
