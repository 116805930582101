import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModeloDiagnosticoQuestao } from '../models/modelo-diagnostico-questao';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
  providedIn: 'root'
})
export class ModeloDiagnosticoVariavelService {


  private readonly BASE_URL = `${environment.apiBaseUrl}/modelo-diagnostico-variavel`;

  constructor(private http: HttpClient) { }

  find(modeloDiagnostico: ModeloDiagnosticoQuestao, page:number, pageSize:number) : Observable<ReponsePaginationListDTO<ModeloDiagnosticoQuestao>> {

    let httpParams = new HttpParams();
    Object.keys(modeloDiagnostico).forEach(
      key => modeloDiagnostico[key] && (httpParams = httpParams.append(key, modeloDiagnostico[key]))
      );
    httpParams = httpParams.append("page", page.toString());
    httpParams = httpParams.append("pageSize", pageSize.toString());
    return this.http.get<ReponsePaginationListDTO<ModeloDiagnosticoQuestao>>(this.BASE_URL, {
      params: httpParams
    });

  }

  findById(id:number) : Observable<ModeloDiagnosticoQuestao>{
    return this.http.get<ModeloDiagnosticoQuestao>(`${this.BASE_URL}/${id}`);
  }

  save(modeloDiagnostico: ModeloDiagnosticoQuestao) : Observable<ModeloDiagnosticoQuestao>{
    console.log(modeloDiagnostico)
    return this.http.post<ModeloDiagnosticoQuestao>(this.BASE_URL, modeloDiagnostico);
  }

  update(id:number, modeloDiagnostico: ModeloDiagnosticoQuestao) : Observable<any>{
    return this.http.put(`${this.BASE_URL}/${id}`, modeloDiagnostico);
  }

  remove(id: number) : Observable<any> {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }
}
