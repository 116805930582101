import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModeloTermo } from 'src/app/models/modelo-termo';
import { DominioService } from 'src/app/services/dominio.service';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloTermoCompromissoService } from 'src/app/services/modelo-termo.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { DominioIdDescricaoDTO } from 'src/app/utils/dominio-id-descricao.dto';

@Component({
  selector: 'app-modelo-termo-list',
  templateUrl: './modelo-termo-list.component.html',
  styleUrls: ['./modelo-termo-list.component.css']
})
export class ModeloTermoListComponent implements OnInit {

  formModeloTermoDadosFind: FormGroup;
  util: UtilityService;
  listMd: ModeloTermo[] = new Array();
  page: number;
  pageSize: number = 4;
  totalSize: number;
  listDominioSituacao: DominioIdDescricaoDTO[];
  @Output() fnAlterar = new EventEmitter<number>();
  @Output() fnVisualizar = new EventEmitter<number>();

  constructor(
    private fb: FormBuilder,
    private utilService: UtilityService,
    private modeloTermoService: ModeloTermoCompromissoService,
    private modalService: ModalUiService,
    private dominioService: DominioService
  ) {
    this.util = utilService;
  }

  ngOnInit(): void {
    this.formModeloTermoDadosFind = this.fb.group({
      numero: [''],
      dataEnvioAprovacao: [''],
      descricao: ['', Validators.maxLength(200)],
      ativo: [''],
      dominioIdSituacao: ['']
    });
    this.page = 1;
    this.totalSize = 0;
    this.dominioService.getDominioModeloTermoSituacao().subscribe(response => {
      this.listDominioSituacao = response;
    });

    this.buscar();
  }

  buscar(id?: number) {
    this.modeloTermoService.find(id ? {numero : id} : this.formModeloTermoDadosFind.value, this.page - 1, this.pageSize).subscribe(response => {
      this.listMd = response.list;
      this.totalSize = response.totalSize;
    }, error => {
      if (error.status == 0) {
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: 'Ocorreu um erro ao listar o modelo termo de compromisso',
          buttons: [EnumModalButtons.OK]
        });
      }
      this.listMd = new Array();
    });
  }

  alterar(id) {
    this.fnAlterar.emit(id);
  }
  
  visualizar(id) {
    this.fnVisualizar.emit(id);
  }

  enviarParaAprovacao(id: number) {
    this.modalService.open({
      buttons: [EnumModalButtons.YES, EnumModalButtons.NO],
      type: EnumModalType.ALERT,
      message: 'Deseja enviar o modelo termo de compromisso para aprovação?',
      yesEvent: () => {
        this.formModeloTermoDadosFind.value.usuario = this.utilService.getDtnUserWrapper().getNomeUsuario();
        this.modeloTermoService.sendToApprove(id, this.formModeloTermoDadosFind.value).subscribe(response => {

          this.modalService.open({
            buttons: [EnumModalButtons.OK],
            type: EnumModalType.SUCCESS,
            message: "Modelo termo de compromisso enviado para aprovação",
            okEvent: () => this.buscar(id)
          })

        },
          errorResponse => {

            switch (errorResponse.status) {
              case 404:
              case 422:
                this.modalService.open({
                  type: EnumModalType.ALERT,
                  message: errorResponse.error.errors.join('. '),
                  buttons: [EnumModalButtons.OK]
                });
                break;
              default:
                this.modalService.open({
                  type: EnumModalType.ERROR,
                  message: 'Não foi possível alterar o modelo termo de compromisso',
                  buttons: [EnumModalButtons.OK]
                });
                break;
            }

          })
      }
    })
  }
}