import { Component, OnInit } from '@angular/core';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.css']
})
export class ManualComponent implements OnInit {
    
    dtnUserWrapper: DtnAuthUserWrapper;

  constructor(
        private agendamentoService: AgendamentoService,
        private messageService: MessageService,
        private utils: UtilityService) {

        this.dtnUserWrapper = utils.getDtnUserWrapper();

        console.log('manual construtor', this.dtnUserWrapper);
    }

  ngOnInit(): void {}
  
}
