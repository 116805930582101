import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-turma-checklist-view',
  templateUrl: './turma-checklist-view.component.html',
  styleUrls: ['./turma-checklist-view.component.css']
})
export class TurmaChecklistViewComponent implements OnInit {

  agendamentoId: number = 0;

  constructor(private activatedRoute: ActivatedRoute) {
    this.agendamentoId = +this.activatedRoute.snapshot.params['agendamentoId'];
  }

  ngOnInit(): void {
  }

}
