import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Agenda } from 'src/app/models/agenda';
import { Dominio } from 'src/app/models/dominio';
import { CalendarioStateService } from 'src/app/services/calendario-state.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { DominioService } from 'src/app/services/dominio.service';
import { MessageService } from 'src/app/services/message.service';
import { CalendarioStateEnum } from 'src/app/utils/calendario-state.enum';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { number } from 'ng-brazil/number/validator';
import { UtilityService } from 'src/app/services/utility.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-calendario-tab-calendario',
  templateUrl: './calendario-tab-calendario.component.html',
  styleUrls: ['./calendario-tab-calendario.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'PT-BR' },
  ],
})
export class CalendarioTabCalendarioComponent implements OnInit {
  dateFilter = new FormControl(moment());
  dateInput = new FormControl(moment());
  dateValidator: Boolean = false;
  monthAndYearSelected: Boolean = false;
  dataValue: String;
  
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    IdentificadorDate: String
  ) {

    console.log(IdentificadorDate == 'dateFilter')
    console.log(IdentificadorDate == 'dateInput')
    if(IdentificadorDate == 'dateFilter'){

      this.monthAndYearSelected = true;

      const ctrlValue = this.dateFilter.value;
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      this.dateFilter.setValue(ctrlValue);
 

      this.dataValue = normalizedMonthAndYear.year() + '-' + (normalizedMonthAndYear.month()+1);     
      if (this.dataValue.length < 7)
        this.dataValue = normalizedMonthAndYear.year() + '-0' + (normalizedMonthAndYear.month()+1);
      }
    
      else if(IdentificadorDate == 'dateInput'){
        const ctrlValue = this.dateInput.value;
        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());
        this.dateInput.setValue(ctrlValue);
        this.monthAndYearSelected = true;
  
        this.dataValue = normalizedMonthAndYear.year() + '-' + (normalizedMonthAndYear.month()+1);
      if (this.dataValue.length < 7)
        this.dataValue =
          normalizedMonthAndYear.year() + '-0' + (normalizedMonthAndYear.month()+1);
      }
      
      this.formFiltro.value.data = this.dataValue;  

    
   

    datepicker.close();
  }

  mesSelecionado: Agenda;
  formCalendario: FormGroup;
  formFiltro: FormGroup;

  totalSize: number;
  pageSize: number;
  page: number;

  mesesCalendario: Agenda[];
  dominios: Dominio[];

  @Output() setAgendaMes = new EventEmitter<Agenda>();

  constructor(
    private fb: FormBuilder,
    private calendarioService: CalendarioService,
    private stateService: CalendarioStateService,
    private messageService: MessageService,
    private dominioService: DominioService,
    private utils: UtilityService
  ) {
    this.mesesCalendario = new Array();
    this.page = 1;
    this.totalSize = 0;
    this.pageSize = 4;

    if (this.stateService.state == CalendarioStateEnum.Visualizacao) {
      this.page = this.stateService.pageCalendario;
      this.totalSize = this.stateService.totalSizeCalendario;
      this.formCalendario = this.stateService.formCalendario;
      this.formFiltro = this.stateService.formCalendarioFiltro;
      this.mesesCalendario = this.stateService.meses;
    }
  }

  ngOnInit(): void {
    if (!this.formCalendario) {
      this.formCalendario = this.fb.group({
        id: [''],
        data: [''],
        dominioIdSituacao: [''],
      });
      this.formCalendario.valueChanges.subscribe((data) => {
        if (
          this.formCalendario.value.dominioIdSituacao != '' &&
          (this.formCalendario.value.data != '' || this.monthAndYearSelected)
        )
          this.dateValidator = true;

        this.stateChange();
      });
    }

    if (!this.formFiltro) {
      this.formFiltro = this.fb.group({
        id: [''],
        data: [''],
        dominioIdSituacao: [''],
      });
      this.formFiltro.valueChanges.subscribe((data) => {
        this.stateChange();
      });
    }

    this.dominioService.getDominioCalendarioSituacao().subscribe((response) => {
      this.dominios = response;
    });

    this.buscar();
  }

  submit() {
    this.formCalendario.value.data = this.dataValue;
    this.formCalendario.value.usuario = this.utils.getDtnUserWrapper().getNomeUsuario();
    
    console.log(this.formCalendario.value.data);
    if (this.formCalendario.valid) {
      if (this.dateValidator) {
        this.calendarioService.updateMonth(this.formCalendario.value).subscribe(
          (response) => {
            this.messageService.tratarMensagemSucesso(
              'Mês alterado com sucesso',
              true,
              () => {
                this.limpar();
                this.buscar();
              }
            );
          },
          (errorResponse) => {
            this.messageService.tratarMensagemErro(
              'Não foi possível atualizar o mês no calendário',
              true,
              errorResponse,
              () => {}
            );
          }
        );
        return;
      }
      // --

      this.calendarioService.saveMonth(this.formCalendario.value)
      .subscribe(response => {
          this.messageService
              .tratarMensagemSucesso("Mês salvo com sucesso.", true, () => {
                  this.limpar();
                  this.buscar();
              });
      }, errorResponse => {
          this.messageService.tratarMensagemErro('Não foi possível salvar o mês no calendário',
              true, errorResponse, () => { });
      });

      // -- 
          
    }

    this.calendarioService.saveMonth(this.formCalendario.value).subscribe(
      (response) => {
        this.messageService.tratarMensagemSucesso(
          'Mês salvo com sucesso.',
          true,
          () => {
            this.limpar();
            this.buscar();
          }
        );
      },
      (errorResponse) => {
        this.messageService.tratarMensagemErro(
          'Não foi possível salvar o mês no calendário',
          true,
          errorResponse,
          () => {}
        );
      }
    );
  }

  buscar() {
    
    this.calendarioService
      .listMonths(this.formFiltro.value, this.page - 1, this.pageSize)
      .subscribe(
        (response) => {
          this.mesesCalendario = response.list;
          this.totalSize = response.totalSize;
        },
        (errorResponse) => {
          this.messageService.tratarMensagemErro(
            'Não foi possível recuperar os meses do calendário',
            true,
            errorResponse,
            () => {}
          );
        },
        () => {
          this.stateChange();
        }
      );
  }

  alterar(id: number) {
    if (id) {
      this.calendarioService.findMonthById(id).subscribe(
        (response) => {
          this.formCalendario.patchValue({
            data: response.data,
            dominioIdSituacao: response.dominioIdSituacao,
            id: response.id,
          });
          this.setAgendaMes.emit(response);
        },
        (errorResponse) => {
          this.messageService.tratarMensagemErro(
            'Não foi possível carregar o mês',
            false,
            errorResponse,
            () => {}
          );
        },
        () => {
          this.stateChange();
        }
      );
    }
  }

  limpar() {
    this.formCalendario.patchValue({
      id: '',
      data: '',
      dominioIdSituacao: '',
    });
    this.dateValidator = false;
    this.setAgendaMes.emit(null);
  }

  stateChange() {
    this.stateService.saveCalendarioState(
      this.mesesCalendario,
      this.formCalendario,
      this.formFiltro,
      this.page,
      this.totalSize
    );
  }
}
