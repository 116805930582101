import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AutorizacaoComponent } from "./autorizacao/autorizacao.component";
import { EtiquetaComponent } from "./etiqueta/etiqueta.component";


const routes: Routes = [
  { path: 'impressao',
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'autorizacao', component: AutorizacaoComponent },
      { path: 'etiqueta', component: EtiquetaComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImpressoesRoutingModule {

}
