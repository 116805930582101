import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { MessageService } from 'src/app/services/message.service';
import { ModeloDiagnosticoService } from 'src/app/services/modelo-diagnostico.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-modelo-diagnostico-aprovacao',
  templateUrl: './modelo-diagnostico-aprovacao.component.html',
  styleUrls: ['./modelo-diagnostico-aprovacao.component.css']
})
export class ModeloDiagnosticoAprovacaoComponent implements OnInit {
  modeloId: number;

  constructor(private modeloService: ModeloDiagnosticoService, private route: ActivatedRoute,
    private router: Router, private messageService: MessageService, private utils: UtilityService)
  { }

  ngOnInit(): void {
    this.modeloId = +this.route.snapshot.params['id'];
  }

  aprovar() {
    if(this.modeloId) {
      this.modeloService.approve(this.modeloId, this.utils.getDtnUserWrapper().getNomeUsuario()).subscribe(response => {
        this.messageService.tratarMensagemSucesso('Modelo Diagnóstico aprovado com sucesso.',
          true, () => { this.router.navigate(["../"], { relativeTo: this.route }); });
      }, errorResponse => {
        this.messageService.tratarMensagemErro('Não foi possível aprovar o modelo diagnóstico.',
          true, errorResponse, () => {});
      });
    }
  }

  reprovar() {
    if(this.modeloId) {
      this.modeloService.reprove(this.modeloId, this.utils.getDtnUserWrapper().getNomeUsuario()).subscribe(response => {
        this.messageService.tratarMensagemSucesso('Modelo Diagnóstico reprovado com sucesso.',
          true, () => { this.router.navigate(["../"], { relativeTo: this.route }); });
      }, errorResponse => {
        this.messageService.tratarMensagemErro('Não foi possível aprovar o modelo diagnóstico.',
          true, errorResponse, () => {});
      });
    }
  }
}
