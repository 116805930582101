import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModeloDiagnostico } from '../models/modelo-diagnostico';
import { QuestionarioDiagnosticoEscola } from '../models/questionario-diagnostico-escola';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
  providedIn: 'root'
})
export class ModeloDiagnosticoService {

  constructor(
    private http: HttpClient
    ) { }

    find(modeloDiagnostico: ModeloDiagnostico, page:number, pageSize:number) : Observable<ReponsePaginationListDTO<ModeloDiagnostico>> {

      let httpParams = new HttpParams();

      Object.keys(modeloDiagnostico).forEach(
        key => modeloDiagnostico[key] && (httpParams = httpParams.append(key, modeloDiagnostico[key]))
        );
      httpParams = httpParams.append("page", page.toString());
      httpParams = httpParams.append("pageSize", pageSize.toString());
      return this.http.get<ReponsePaginationListDTO<ModeloDiagnostico>>(`${environment.apiBaseUrl}/modelo-diagnostico`, {
        params: httpParams
      });

    }

    findById(id:number) : Observable<ModeloDiagnostico>{
      return this.http.get<ModeloDiagnostico>(`${environment.apiBaseUrl}/modelo-diagnostico/${id}`);
    }

    save(modeloDiagnostico: ModeloDiagnostico) : Observable<ModeloDiagnostico>{
      return this.http.post<ModeloDiagnostico>(`${environment.apiBaseUrl}/modelo-diagnostico`, modeloDiagnostico);
    }

    update(id:number, modeloDiagnostico: ModeloDiagnostico) : Observable<ModeloDiagnostico>{
      return this.http.put<ModeloDiagnostico>(`${environment.apiBaseUrl}/modelo-diagnostico/${id}`, modeloDiagnostico);
    }

    sendToApprove(id:number, modeloDiagnostico: ModeloDiagnostico): Observable<any>{
      return this.http.patch<any>(`${environment.apiBaseUrl}/modelo-diagnostico/${id}/send`, modeloDiagnostico)
    }

    approve(id:number, usuario: string) : Observable<any> {
      return this.http.patch<any>(`${environment.apiBaseUrl}/modelo-diagnostico/${id}/approve`, {usuario: usuario});
    }

    reprove(id:number, usuario: string) : Observable<any> {
      return this.http.patch<any>(`${environment.apiBaseUrl}/modelo-diagnostico/${id}/reprove`, {usuario: usuario});
    }

    getQuestionario(id:number): Observable<QuestionarioDiagnosticoEscola[]>{
      return this.http.get<QuestionarioDiagnosticoEscola[]>(`${environment.apiBaseUrl}/modelo-diagnostico/questionario/${id}`);
    }
}
