import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { EscolasAtendidasAnualmente } from "../models/escolas-atendidas-anualmente";
import { EscolasAtendidasConsolidado } from "../models/escolas-atendidas-consolidado";
import { ParametrosRelatorio } from "../models/parametros-relatorio";

@Injectable({
  providedIn: 'root'
})
export class RelatoriosService {
  private readonly BASE_URL = `${environment.apiBaseUrl}/relatorios`;

  constructor(private http: HttpClient) { }

  getEscolasAtendidasData(dto: ParametrosRelatorio): Observable<EscolasAtendidasConsolidado[]> {
    let httpParams = new HttpParams();
    Object.keys(dto).forEach(
      key => dto[key] && (httpParams = httpParams.append(key, dto[key]))
      );
    return this.http.get<EscolasAtendidasConsolidado[]>(`${this.BASE_URL}/escolas-atendidas/data`, { params: httpParams });
  }

  getEscolasAtendidasDataChart(dto: ParametrosRelatorio): Observable<EscolasAtendidasAnualmente> {
    let httpParams = new HttpParams();
    Object.keys(dto).forEach(
      key => dto[key] && (httpParams = httpParams.append(key, dto[key]))
      );
    return this.http.get<EscolasAtendidasAnualmente>(`${this.BASE_URL}/escolas-atendidas/chart`, { params: httpParams });
  }

  printEscolasAtendidas(dto: ParametrosRelatorio) {
    let httpParams = new HttpParams();
    Object.keys(dto).forEach(
      key => dto[key] && (httpParams = httpParams.append(key, dto[key]))
      );
    return this.http.get(`${this.BASE_URL}/escolas-atendidas/print/pdf`, { responseType: 'blob', params: httpParams });
  }
}
