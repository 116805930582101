import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartsModule } from "ng2-charts";
import { ServicesModule } from "src/app/services/services.module";
import { TemplateModule } from "src/app/template/template.module";
import { EscolasAtendidasChartComponent } from "./escolas-atendidas-chart/escolas-atendidas-chart.component";
import { EscolasAtendidasComponent } from "./escolas-atendidas/escolas-atendidas.component";
import { RelatoriosFormComponent } from "./relatorios-form/relatorios-form.component";
import { RelatoriosRoutingModule } from "./relatorios-routing.module";


@NgModule({
  declarations: [
    RelatoriosFormComponent,
    EscolasAtendidasComponent,
    EscolasAtendidasChartComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    TemplateModule,
    ServicesModule,
    ChartsModule,
    RelatoriosRoutingModule
  ]
})
export class RelatoriosModule {

}
