import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DiagnosticoEscolaService } from 'src/app/services/diagnostico-escola.service';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MessageError } from 'src/app/utils/message-error';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RespostaQuestionarioEscola } from 'src/app/models/resposta-questionario-escola';
import { MessageService } from 'src/app/services/message.service';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { UtilityService } from 'src/app/services/utility.service'
import { QuestionarioDiagnosticoFormComponentComponent } from '../questionario-diagnostico-form-component/questionario-diagnostico-form-component.component';
import { DiagnosticoEscola } from 'src/app/models/diagnostico-escola';

@Component({
    selector: 'app-diagnostico-form',
    templateUrl: './diagnostico-form.component.html',
    styleUrls: ['./diagnostico-form.component.css']
})
export class DiagnosticoFormComponent implements OnInit {

    public MASKS = MASKS;

    formularioDiagnostico: FormGroup;
    messageError: MessageError;
    id: number;
    idEscola: number;
    idModeloDiagnostico: number;
    @Input() paramIdModeloDiagnostico: number;
    @Input() paramIdDiagnostico: number;
    respostas: RespostaQuestionarioEscola[];

    focus: any = {
        diretorCpf: false,
        diretorCelular: false,
        adjuntoCelular: false,
        adjuntoCpf: false,
        supervisorCelular: false,
        supervisorCpf: false,
        coordenadorCelular: false,
        coordenadorCpf: false,
        orientadorCpf: false,
        orientadorCelular: false,
    }

    get diagnostico() { return this.formularioDiagnostico.controls; }

    @ViewChild(QuestionarioDiagnosticoFormComponentComponent)
    private childQuestionarioComponent: QuestionarioDiagnosticoFormComponentComponent;

    constructor(private diagnosticoEscolaService: DiagnosticoEscolaService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private utilityService: UtilityService) {
        this.messageError = new MessageError();
    }

    ngOnInit(): void {
        let params: Observable<Params> = this.activatedRoute.params;

        params.subscribe(urlParams => {
            this.idEscola = urlParams['idEscola'];
        });

        const defaultValidatorsQuantidade: ValidatorFn[] = [Validators.required, Validators.min(0), Validators.pattern("^[0-9]*")];

        this.formularioDiagnostico = this.formBuilder.group({
            diretorNome: [null],
            diretorCelular: [null],
            adjuntoNome: [null],
            adjuntoCelular: [null],
            supervisorNome: [null],
            supervisorCelular: [null],
            coordenadorNome: [null, [Validators.required]],
            coordenadorCelular: [null, [Validators.required, NgBrazilValidators.celular]],
            orientadorNome: [null],
            orientadorCelular: [null],
            quantidadeProfessores: [null, defaultValidatorsQuantidade],
            quantidadeAlunos1Matutino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos2Matutino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos3Matutino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos4Matutino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos5Matutino: [null, defaultValidatorsQuantidade],
            quantidadeSalas1Matutino: [null, defaultValidatorsQuantidade],
            quantidadeSalas2Matutino: [null, defaultValidatorsQuantidade],
            quantidadeSalas3Matutino: [null, defaultValidatorsQuantidade],
            quantidadeSalas4Matutino: [null, defaultValidatorsQuantidade],
            quantidadeSalas5Matutino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos1Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos2Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos3Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos4Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeAlunos5Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeSalas1Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeSalas2Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeSalas3Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeSalas4Vespertino: [null, defaultValidatorsQuantidade],
            quantidadeSalas5Vespertino: [null, defaultValidatorsQuantidade],
        });

        if (this.paramIdDiagnostico) {
            this.diagnosticoEscolaService.getDiagnosticoEscola(this.paramIdDiagnostico).subscribe(response => {
                this.setValues(response);
                this.idEscola = null;
            }, errorResponse => {
                if (errorResponse.status != 404)
                    this.messageService.tratarMensagemErro('Erro ao buscar o diagnóstico', true, errorResponse, () => { });
            });
        }
        else {
            if (this.paramIdModeloDiagnostico) {
                this.idModeloDiagnostico = this.paramIdModeloDiagnostico;
                this.formularioDiagnostico.disable();
            }
            else
                this.findDiagnosticoEscola();
        }

    }

    findDiagnosticoEscola() {

        if (!this.idEscola)
            this.formularioDiagnostico.disable();

        this.diagnosticoEscolaService.findDiagnosticoByIdEscola(this.idEscola ?? 0).subscribe(response => {
            this.setValues(response);
        }, errorResponse => {
            if (errorResponse.status != 404)
                this.messageService.tratarMensagemErro('Erro ao buscar o diagnóstico', true, errorResponse, () => { });
        });

    }

    setValues(response: DiagnosticoEscola) {
        this.id = response.id;
        this.idModeloDiagnostico = response.idModeloDiagnostico;
        this.respostas = response.respostas;

        if (this.respostas?.length > 0)
            this.formularioDiagnostico.disable();



        this.formularioDiagnostico.patchValue({
            diretorNome: response.diretorNome,
            diretorCelular: response.diretorCelular,
            adjuntoNome: response.adjuntoNome,
            adjuntoCelular: response.adjuntoCelular,
            supervisorNome: response.supervisorNome,
            supervisorCelular: response.supervisorCelular,
            coordenadorNome: response.coordenadorNome,
            coordenadorCelular: response.coordenadorCelular,
            orientadorNome: response.orientadorNome,
            orientadorCelular: response.orientadorCelular,
            quantidadeProfessores: response.quantidadeProfessores,
            quantidadeAlunos1Matutino: response.quantidadeAlunos1Matutino,
            quantidadeAlunos2Matutino: response.quantidadeAlunos2Matutino,
            quantidadeAlunos3Matutino: response.quantidadeAlunos3Matutino,
            quantidadeAlunos4Matutino: response.quantidadeAlunos4Matutino,
            quantidadeAlunos5Matutino: response.quantidadeAlunos5Matutino,
            quantidadeSalas1Matutino: response.quantidadeSalas1Matutino,
            quantidadeSalas2Matutino: response.quantidadeSalas2Matutino,
            quantidadeSalas3Matutino: response.quantidadeSalas3Matutino,
            quantidadeSalas4Matutino: response.quantidadeSalas4Matutino,
            quantidadeSalas5Matutino: response.quantidadeSalas5Matutino,
            quantidadeAlunos1Vespertino: response.quantidadeAlunos1Vespertino,
            quantidadeAlunos2Vespertino: response.quantidadeAlunos2Vespertino,
            quantidadeAlunos3Vespertino: response.quantidadeAlunos3Vespertino,
            quantidadeAlunos4Vespertino: response.quantidadeAlunos4Vespertino,
            quantidadeAlunos5Vespertino: response.quantidadeAlunos5Vespertino,
            quantidadeSalas1Vespertino: response.quantidadeSalas1Vespertino,
            quantidadeSalas2Vespertino: response.quantidadeSalas2Vespertino,
            quantidadeSalas3Vespertino: response.quantidadeSalas3Vespertino,
            quantidadeSalas4Vespertino: response.quantidadeSalas4Vespertino,
            quantidadeSalas5Vespertino: response.quantidadeSalas5Vespertino,
        });
    }

    onSubmit() {

        if (this.formularioDiagnostico.invalid) {
            this.formularioDiagnostico.markAllAsTouched();
            return;
        }

        const respostas: RespostaQuestionarioEscola[] = this.childQuestionarioComponent.getFormValues();
        if (respostas == null)
            return;

        if (!this.id) {

            const formDiagnostico = this.utilityService.replaceFieldsOnlyNumber(this.formularioDiagnostico.value, [
                'diretorCelular',
                'adjuntoCelular',
                'supervisorCelular',
                'coordenadorCelular',
                'orientadorCelular'
            ]);
            formDiagnostico.respostas = respostas;
            formDiagnostico.id = this.id;
            formDiagnostico.idModeloDiagnostico = this.idModeloDiagnostico;
            formDiagnostico.idInstituicaoEscola = this.idEscola;
            formDiagnostico.usuario = this.utilityService.getDtnUserWrapper().getNomeUsuario();

            this.diagnosticoEscolaService.create(formDiagnostico).subscribe(
                response => {
                    this.messageService.tratarMensagemSucesso('Diagnóstico cadastrado com sucesso', true, () => this.router.navigate(["/escola"]));
                    this.id = response.id;
                },
                error => {
                    this.messageService.tratarMensagemErro('Não foi possível cadastrar o diagnóstico', true, error, () => { });
                });
        }
        else this.messageService.tratarMensagemErro('Não é permitido editar o diagnóstico', true, { status: 0 }, () => { });
    }

}
