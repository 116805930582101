import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AgendamentoCancelamento } from "src/app/models/agendamento-cancelamento";
import { AgendamentoService } from "src/app/services/agendamento.service";


@Component({
    selector: 'app-agendamento-view',
    templateUrl: './agendamento-view.component.html',
    styleUrls: ['./agendamento-view.component.css']
})
export class AgendamentoViewComponent implements OnInit {
    agendamento: AgendamentoCancelamento;

    constructor(private agendamentoService: AgendamentoService,
        private route: ActivatedRoute) {
        this.agendamento = new AgendamentoCancelamento();
    }

    ngOnInit(): void {
        this.agendamentoService.find(+this.route.snapshot.params['id'])
            .subscribe(data => {
                this.agendamento = data
            });
    }

}
