import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EscolaRoutingModule } from './escola-routing.module';
import { EscolaListComponent } from './escola-list/escola-list.component';
import { EnderecoFormComponent } from './endereco-form/endereco-form.component';
import { EnderecoEscola } from 'src/app/models/enderecoEscola';
import { QuestionarioDiagnosticoFormComponentComponent } from './questionario-diagnostico-form-component/questionario-diagnostico-form-component.component';
import { DiagnosticoFormComponent } from './diagnostico-form/diagnostico-form.component';
import { DiagnosticoHistoricoFormComponent } from './diagnostico-historico-form/diagnostico-historico-form.component';

import { TextMaskModule } from 'angular2-text-mask';
import { NgBrazil } from 'ng-brazil'
import { TemplateModule } from 'src/app/template/template.module';
import { TermoCompromissoFormComponent } from './termo-compromisso-form/termo-compromisso-form.component';


@NgModule({
  declarations: [EscolaListComponent, EnderecoFormComponent, DiagnosticoFormComponent, QuestionarioDiagnosticoFormComponentComponent, DiagnosticoHistoricoFormComponent, TermoCompromissoFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,    
    FormsModule,        
    TextMaskModule,
    NgBrazil,
    EscolaRoutingModule,
    TemplateModule
  ],
  exports: [
    EnderecoFormComponent,
    DiagnosticoFormComponent,
    QuestionarioDiagnosticoFormComponentComponent,
    EscolaListComponent,
    TermoCompromissoFormComponent
  ],
  bootstrap:[
    EscolaListComponent,
    EnderecoFormComponent
  ]
})
export class EscolaModule { }
