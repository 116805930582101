import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Agendamento } from "src/app/models/agendamento";
import { AgendamentoDisponibilidade } from "src/app/models/agendamento-disponibilidade";
import { AgendamentoService } from "src/app/services/agendamento.service";
import { MessageService } from "src/app/services/message.service";
import { UtilityService } from 'src/app/services/utility.service';
import { DominioIdPeriodo } from "src/app/utils/dominio-enum";
import { MessageError } from "src/app/utils/message-error";
import {AgendamentoListComponent} from "src/app/modules/agendamento/agendamento-list/agendamento-list.component"
import { AgendaData } from 'src/app/models/agenda-data';


@Component({
    selector: 'app-calendario-atualizacao-form',
    templateUrl: './calendario-atualizacao-form.component.html',
    styleUrls: ['./calendario-atualizacao-form.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CalendarioAtualizacaoFormComponent implements OnInit {
    periodoEnum = DominioIdPeriodo;
    errorMessage: boolean = false;
    formAgendamento: FormGroup;
//    data: Date;
    get form() { return this.formAgendamento.controls; };

    messageError: MessageError;

    @Input() evento: AgendaData;
    @Input() escolaId: number;
    @Input() data: String;
    @Input() periodo: String;
    @Input() agendamentoId: number;
  
    dataForm: String;
    validation: number;
    constructor(public modalAtivaService: NgbActiveModal,
        private fb: FormBuilder,
        private agendamentoService: AgendamentoService,
        private messageService: MessageService,
        private agendamentolistcomponent: AgendamentoListComponent,
        private router: Router,
        private utils: UtilityService) {
        this.messageError = new MessageError();
    }

    ngOnInit(): void {
        this.formAgendamento = this.fb.group({
            turno: ['']
        });
    }

    confirm() {
  
         this.agendamentoService.atualizar(this.evento.id,this.formAgendamento.value.turno,this.utils.getDtnUserWrapper().getNomeUsuario(), this.agendamentoId).subscribe(
      response => {
        console.log('Requisição PUT realizada com sucesso:', response);
        // Faça o que for necessário com a resposta da API
      },
      error => {
        console.error('Erro na requisição PUT:', error);
        // Trate o erro de acordo com suas necessidades
      }
    );
  
        console.log("DominioIdPeriodo" + this.formAgendamento.value.turno)
        let periodoMensagem = this.formAgendamento.value.turno == DominioIdPeriodo.Matutino ? "Matutino" : "Vespertino";
        this.messageService.tratarMensagemSucesso("Data agendada com Sucesso",true,() => this.paginaInicial());
        setTimeout(() => this.modalAtivaService.dismiss(), 1000)
        
    }
    
    paginaInicial(){
         this.router.navigate(['/agendamento']);           
        }

//    save() {
//        this.agendamentoService.save(this.montaAgendamento())
//            .subscribe(result => {
//                this.messageService.tratarMensagemSucesso("Data agendada com sucesso.", true, () => {
//                    this.modalAtivaService.dismiss();
//                    this.router.navigate(['/agendamento']);
//                });
//            }, error => {
//                this.messageService.tratarMensagemErro("Não foi possível efetuar o agendamento.", false, error, () => { });
//            });
//    }
//
//    serieHasError(): boolean {
//        if (!this.form.ano1.value && !this.form.ano2.value && !this.form.ano3.value &&
//            !this.form.ano4.value && !this.form.ano5.value) {
//            return true;
//        }
//
//        return false;
//    }


    formatDateToDDMMYYYY(date: Date): string {
        const day = String(date.getDate() + 1).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());

        return `${day}/${month}/${year}`;
     }
     
    turnoIsUnavailable(turno: DominioIdPeriodo): boolean {
        this.validation = this.evento.quantidadeAgendamentos
        console.log("validation" + this.validation)
        console.log("agendamentoId" + this.agendamentoId)
        console.log("agendaDataId" + this.evento.id)
        console.log("Usuario" + this.utils.getDtnUserWrapper().getNomeUsuario())
        console.log("Turno" + turno)
        
        const dataAgendamento = new Date(this.evento.data);
        this.dataForm = this.formatDateToDDMMYYYY(dataAgendamento) 
        if (turno == this.evento.periodo){
            return false;
            }
        if(this.validation == 2){
            return false            
            }     
        return null;
    }

//    montaAtualizaacao(): Agendamento {
//        
//        if(this.formAgendamento2.value.turno == 28){
//            this.formAgendamento2.value.turno = 29
//            }else{
//                this.formAgendamento2.value.turno = 28
//                }
//        
//        let agendamento: Agendamento = {
//            id: 0,
//            agendaDataId: this.evento.id,
//            codigoEscola: this.escolaId,
//            dominioIdPeriodo: this.formAgendamento2.value.turno,
//            turmas: [],
//            usuario: this.utils.getDtnUserWrapper().getNomeUsuario(),
//            cpfUsuario: this.utils.getDtnUserWrapper().getLogin()
//        };
//        
//        Object.keys(this.formAgendamento2.value).forEach(
//            key => {
//                if (key.includes("ano") && this.formAgendamento2.value[key])
//                    agendamento.turmas.push(+key.replace("ano", ""));
//            });
//
//        return agendamento;
//    }
}
