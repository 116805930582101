import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModeloTermoRoutingModule } from './modelo-termo-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServicesModule } from 'src/app/services/services.module';
import { UtilityService } from 'src/app/services/utility.service';
import { TemplateModule } from 'src/app/template/template.module';
import { EscolaModule } from '../escola/escola.module';
import { ModeloTermoFormComponent } from './modelo-termo-form/modelo-termo-form.component';
import { ModeloTermoListComponent } from './modelo-termo-list/modelo-termo-list.component';
import { ModeloTermoTextoComponent } from './modelo-termo-texto/modelo-termo-texto.component';
import { ModeloTermoAprovacaoListComponent } from './modelo-termo-aprovacao-list/modelo-termo-aprovacao-list.component';
import { DialogOverviewExampleDialog, ModeloTermoAprovacaoComponent } from './modelo-termo-aprovacao/modelo-termo-aprovacao.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule ({
  declarations: [
    ModeloTermoFormComponent,
    ModeloTermoListComponent,
    ModeloTermoTextoComponent,
    ModeloTermoAprovacaoListComponent,
    ModeloTermoAprovacaoComponent,
    DialogOverviewExampleDialog
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    ModeloTermoRoutingModule,
    ServicesModule,
    TemplateModule,
    EscolaModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    UtilityService
  ]
})
export class ModeloTermoModule { }