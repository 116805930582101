<ng-select
  [items]="items$ | async"
  [minTermLength]="3"
  [loading]="isLoading"
  [typeahead]="input$"
  [trackByFn]="trackFn"
  bindLabel="value"
  bindValue="key"
  placeholder="{{placeholder}}"
  [formControl]="control">
</ng-select>
