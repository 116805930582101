import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "src/app/services/message.service";
import { TurmaService } from "src/app/services/turma.service";
import { TurmaTabEnum } from "src/app/utils/turma-tab.enum";


@Component({
  selector: 'app-turma',
  templateUrl: './turma-form.component.html',
  styleUrls: ['./turma-form.component.css']
})
export class TurmaFormComponent implements OnInit {
  tabEnum = TurmaTabEnum;
  tabAtiva: string;
  agendamentoId: number;
  hasProfissionalApoio: boolean = false;

  constructor(private route: ActivatedRoute,
    private messageService: MessageService) {
    this.tabAtiva = 'acompanhante';
    this.agendamentoId = +this.route.snapshot.params['agendamentoId'];
  }

  ngOnInit(): void {
  }

  accessNextTab(tabType: TurmaTabEnum) {
    switch (tabType) {
      case TurmaTabEnum.Acompanhante:
        {
          if(this.hasProfissionalApoio){
            this.tabAtiva = 'profissionalApoio';
            return;
          }
          this.messageService.confirmacao("Existe algum aluno portador de necessidades especiais que necessita de um profissional de apoio?",
            () => {
              this.tabAtiva = 'profissionalApoio';
              this.hasProfissionalApoio = true;
            }, () => {
              this.tabAtiva = 'alunos';
            });
        }break;
      case TurmaTabEnum.ProfissionalApoio:
        {
          this.tabAtiva = 'alunos';
        }break;
      case TurmaTabEnum.Alunos:
        {
          if(this.hasProfissionalApoio)
            this.tabAtiva = 'profissionalApoio';
          else
          this.tabAtiva = 'acompanhante';
        }break;
      default:
        this.tabAtiva = 'acompanhante';
        return;
    }
  }
}
