<div class="modal-header">
    <h4 class="{{cssClassTitle}}" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')" *ngIf="!hideClose">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" ngbAutofocus >
    <p [innerHTML]="message"></p>
</div> 
<div class="modal-footer">
    <button type="button" class="btn btn-success" *ngIf="showOkButton()" (click)="activeModal.close('OK');">Ok</button>
    <button type="button" class="btn btn-outline-success" *ngIf="showYesButton()" (click)="activeModal.close('YES')">Sim</button>
    <button type="button" class="btn btn-outline-danger" *ngIf="showNoButton()" (click)="activeModal.close('NO')">Não</button>
    <button type="button" class="btn btn-outline-dark" *ngIf="showCancelButton()" (click)="activeModal.close('CANCEL')">Calcelar</button>
</div>