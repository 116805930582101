import { Injectable } from "@angular/core";
import { Agenda } from "../models/agenda";
import { AgendaData } from "../models/agenda-data";
import { CalendarioStateEnum } from "../utils/calendario-state.enum";

@Injectable({
  providedIn: 'root'
})
export class CalendarioStateService {
  private _mes: Agenda;
  private _mesesCalendario: Agenda[];
  private _eventos: AgendaData[];

  private _formCalendario: any;
  private _formCalendarioFiltro: any;
  private _pageCalendario: number;
  private _totalSizeCalendario: number;

  private _formDatas: any;
  private _formDatasFiltro: any;
  private _pageDatas: number;
  private _totalSizeDatas: number;

  private _state: CalendarioStateEnum;

  updateState(state:CalendarioStateEnum) {
    this._state = state;
  }

  saveCalendarioState(meses: Agenda[], form: any, formFiltro: any, page: number, totalSize: number) {
    this._mesesCalendario = meses;
    this._formCalendario = form;
    this._formCalendarioFiltro = formFiltro;
    this._pageCalendario = page;
    this._totalSizeCalendario = totalSize;
  }

  saveDatasState(eventos: AgendaData[], form: any, formFiltro: any, page: number, totalSize: number) {
    this._eventos = eventos;
    this._formDatas = form;
    this._formDatasFiltro = formFiltro;
    this._pageDatas = page;
    this._totalSizeDatas = totalSize;
  }

  get state(): CalendarioStateEnum { return this._state; };

  get mes(): Agenda { return this._mes };
  set mes(value: Agenda) { this._mes = value };

  get meses(): Agenda[] { return this._mesesCalendario };
  get formCalendario(): any { return this._formCalendario };
  get formCalendarioFiltro(): any { return this._formCalendarioFiltro };
  get pageCalendario(): number { return this._pageCalendario };
  get totalSizeCalendario(): number { return this._totalSizeCalendario };

  get eventos(): AgendaData[] { return this._eventos };
  get formDatas(): any { return this._formDatas };
  get formDatasFiltro(): any { return this._formDatasFiltro };
  get pageDatas(): number { return this._pageDatas };
  get totalSizeDatas(): number { return this._totalSizeDatas };
}
