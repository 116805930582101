import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AgendaData } from 'src/app/models/agenda-data';
import { Dominio } from 'src/app/models/dominio';
import { CalendarioStateService } from 'src/app/services/calendario-state.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { DominioService } from 'src/app/services/dominio.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilityService } from 'src/app/services/utility.service';
import { CalendarioStateEnum } from 'src/app/utils/calendario-state.enum';

@Component({
    selector: 'app-calendario-tab-datas',
    templateUrl: './calendario-tab-datas.component.html',
    styleUrls: ['./calendario-tab-datas.component.css']
})
export class CalendarioTabDatasComponent implements OnInit {
    formDatas: FormGroup;
    formFiltro: FormGroup;

    totalSize: number;
    pageSize: number;
    page: number;

    eventos: AgendaData[];
    dominios: Dominio[];

    private _agendaId: number;
    @Input() set agendaId(value: number) {
        this._agendaId = value;
        this.page = 1;

        if (this.stateService.state != CalendarioStateEnum.Visualizacao)
            this.buscar();
    }
    get agendaId() {
        return this._agendaId;
    }

    private _mes: string;
    @Input() set mes(value: string) {
        if (this.stateService.state != CalendarioStateEnum.Visualizacao) {
            this.formDatas?.patchValue({ data: '' });
            this.formFiltro?.patchValue({ data: '' });
        }

        this._mes = value;
    }
    get mes() { return this._mes; }

    constructor(
        private fb: FormBuilder,
        private calendarioService: CalendarioService,
        private stateService: CalendarioStateService,
        private messageService: MessageService,
        private dominioService: DominioService,
        private utils: UtilityService) {

        this.eventos = new Array();
        this.page = 1;
        this.totalSize = 0;
        this.pageSize = 10;

        if (this.stateService.state == CalendarioStateEnum.Visualizacao) {
            this.page = this.stateService.pageDatas;
            this.totalSize = this.stateService.totalSizeDatas;
            this.formDatas = this.stateService.formDatas;
            this.formFiltro = this.stateService.formDatasFiltro;
            this.eventos = this.stateService.eventos;
        }
    }

    ngOnInit(): void {
        if (!this.formDatas) {
            this.formDatas = this.fb.group({
                id: [''],
                data: [''],
                dominioIdEvento: [''],
                agendaId: ['']
            });
            this.formDatas.valueChanges.subscribe(data => {
                this.stateChange();
            });
        }

        if (!this.formFiltro) {
            this.formFiltro = this.fb.group({
                agendaId: [''],
                id: [''],
                data: [''],
                dominioIdEvento: ['']
            });
            this.formFiltro.valueChanges.subscribe(data => {
                this.stateChange();
            });
        }

        this.dominioService.getDominioCalendarioEvento().subscribe(response => {
            this.dominios = response;
        });
    }

    submit() {
        if (this.formDatas.valid) {
            this.formDatas.patchValue({
                agendaId: this._agendaId
            });
            
            this.formDatas.value.usuario = this.utils.getDtnUserWrapper().getNomeUsuario();
            
            if (this.formDatas.value.id) {
                this.calendarioService.updateDate(this.formDatas.value)
                    .subscribe(response => {
                        this.messageService
                            .tratarMensagemSucesso("Data alterada com sucesso", true, () => {
                                this.formDatas.reset();
                                this.buscar();
                            });
                    }, errorResponse => {
                        this.messageService.tratarMensagemErro('Não foi possível atualizar a data do calendário',
                            true, errorResponse, () => { });
                    });
                return;
            }

            this.calendarioService.saveDate(this.formDatas.value)
                .subscribe(response => {
                    this.messageService
                        .tratarMensagemSucesso("Data salva com sucesso.", true, () => {
                            this.formDatas.reset();
                            this.buscar();
                        });
                }, errorResponse => {
                    this.messageService.tratarMensagemErro('Não foi possível salvar a data do calendário',
                        true, errorResponse, () => { });
                });
        }
    }

    buscar() {
        if (this._agendaId) {
            this.formFiltro.patchValue({ agendaId: this._agendaId });
            this.calendarioService
                .listDate(this.formFiltro.value, (this.page - 1), this.pageSize)
                .subscribe(response => {
                    this.eventos = response.list;
                    this.totalSize = response.totalSize;
                }, errorResponse => {
                    this.messageService.tratarMensagemErro('Não foi possível recuperar as datas de eventos',
                        true, errorResponse, () => { });
                }, () => {
                    this.stateChange();
                });
        }
    }

    alterar(id: number) {
        if (id) {
            this.calendarioService.findDateById(id)
                .subscribe(response => {
                    this.formDatas.patchValue({
                        data: response.data,
                        dominioIdEvento: response.dominioIdEvento,
                        id: response.id
                    });
                }, errorResponse => {
                    this.messageService.tratarMensagemErro('Não foi possível carregar as datas de eventos', false, errorResponse, () => { });
                });
        }
    }

    excluir(id: number) {
        if (id) {
            this.calendarioService.deleteDate(id)
                .subscribe(response => {
                    this.messageService.tratarMensagemSucesso("Data do evento excluída", false, () => {
                        this.buscar();
                    });
                }, errorResponse => {
                    this.messageService.tratarMensagemErro("Não foi possível excluir a data", false, errorResponse, () => { });
                });
        }
    }

    ultimoDiaDoMes(): string {
        let quantidadeDeDias: number;

        if (this.mes) {
            let mes = this.mes.split('-')[1];
            let ano = this.mes.split('-')[0];
            quantidadeDeDias = new Date(+ano, +mes, 0).getDate();

            return this.mes + '-' + quantidadeDeDias.toString();
        }

        return null;
    }

    stateChange() {
        this.stateService.saveDatasState(
            this.eventos,
            this.formDatas,
            this.formFiltro,
            this.page,
            this.totalSize);
    }
}
