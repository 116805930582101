import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModeloTermo } from '../models/modelo-termo';
import { ModeloTermoTexto } from '../models/modelo-termo-texto';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
    providedIn: 'root'
})
export class ModeloTermoCompromissoService {
    
    private readonly BASE_URL: string = `${environment.apiBaseUrl}/modelo-termo`;
    
    constructor(private http: HttpClient) { }

    find(modeloTermo: ModeloTermo, page:number, pageSize:number) : Observable<ReponsePaginationListDTO<ModeloTermo>> {
        let httpParams = new HttpParams();

        Object.keys(modeloTermo).forEach(
        key => modeloTermo[key] && (httpParams = httpParams.append(key, modeloTermo[key]))
        );
        httpParams = httpParams.append("page", page.toString());
        httpParams = httpParams.append("pageSize", pageSize.toString());
        
        return this.http.get<ReponsePaginationListDTO<ModeloTermo>>(`${environment.apiBaseUrl}/modelo-termo`, {
            params: httpParams
        });
    }

    findById(id:number) : Observable<ModeloTermoTexto> {
        return this.http.get<ModeloTermoTexto>(`${this.BASE_URL}/${id}`);
    }

    findById2(id:number) : Observable<ModeloTermo> {
        return this.http.get<ModeloTermo>(`${this.BASE_URL}/${id}`);
    }
    
    findByDados(id:number) : Observable<ModeloTermo> {
        return this.http.get<ModeloTermo>(`${this.BASE_URL}/dados/${id}`);
    }

    save(modeloTermo: ModeloTermo) : Observable<ModeloTermo> {
        return this.http.post<ModeloTermo>(`${this.BASE_URL}`, modeloTermo);
    }

    update(id:number, modeloTermo: ModeloTermo) : Observable<ModeloTermo> {
        return this.http.put<ModeloTermo>(`${this.BASE_URL}/${id}`, modeloTermo);
    }

    sendToApprove(id:number, modeloTermo: ModeloTermo): Observable<any> {
        return this.http.patch<any>(`${this.BASE_URL}/${id}/send`, modeloTermo)
    }

    approve(id:number, usuario: string) : Observable<any> {
        return this.http.patch<any>(`${this.BASE_URL}/${id}/approve`, {usuario: usuario});
    }

    reprove(id:number, usuario: string, motivoReprovacao: string) : Observable<any> {
        return this.http.patch<any>(`${this.BASE_URL}/${id}/reprove`, {usuario: usuario, motivoReprovacao: motivoReprovacao});
    }
}