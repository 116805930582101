import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms'
import { Escola } from 'src/app/models/escola';
import { DominioService } from 'src/app/services/dominio.service';
import { EscolaService } from 'src/app/services/escola.service';
import { DominioIdDescricaoDTO } from 'src/app/utils/dominio-id-descricao.dto';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { UtilityService } from 'src/app/services/utility.service';
import { MessageService } from 'src/app/services/message.service';
import { DtnAuthUserWrapper } from 'src/app/utils/dtn-auth-user-wrapper';

@Component({
    selector: 'app-escola-list',
    templateUrl: './escola-list.component.html',
    styleUrls: ['./escola-list.component.css']
})
export class EscolaListComponent implements OnInit {

    public MASKS = MASKS;

    showToast: boolean;

    school: Escola = new Escola;
    listEscola: Escola[] = new Array();
    formFind: FormGroup;
    page: number;
    pageSize: number = 8;
    totalSize: number;

    listDominioEscolaSituacao: DominioIdDescricaoDTO[];
    dtnUserWrapper: DtnAuthUserWrapper;
    isOperadorEscola: boolean;

    constructor(
        private messageService: MessageService,
        private escolaService: EscolaService,
        private fb: FormBuilder,
        private dominioService: DominioService,
        private utilityService: UtilityService) {

        console.log('EscolaListComponent - constructor()');

        this.dtnUserWrapper = utilityService.getDtnUserWrapper();
        this.isOperadorEscola = this.dtnUserWrapper.isOperadorEscola();
    }

    ngOnInit(): void {
        console.log('EscolaListComponent.ngOnInit()', this.dtnUserWrapper);
        this.showToast = false;
        this.formFind = this.fb.group({
            nome: [{value: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? this.dtnUserWrapper.getInstituicaoEscola().getNome() : '', disabled: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola()}],
            cnpj: [{value: '', disabled: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola()}],
            codigo: [{value: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? this.dtnUserWrapper.getCodigoEscola() : '', disabled: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola()}],
            isOperadorEscola: [{value: this.dtnUserWrapper && this.dtnUserWrapper.isOperadorEscola() ? '1' : '0', disabled: false}],
            dominioIdSituacao: [{value: '', disabled: false}]
        });

        this.page = 1;
        this.totalSize = 1;

        this.dominioService.getDominioEscolaSituacao().subscribe(response => {
            this.listDominioEscolaSituacao = response;
        }, error => {
            this.messageService.tratarMensagemErro('Não foi possível recuperar enumeração da situação da escola', true, error, () => { });
        }
        );

        this.submit();
    }

    submit() {
        const filters = this.formFind.getRawValue();
        console.log('EscolaListComponent - filters: ', filters);
        this.escolaService.find(filters, this.page - 1, this.pageSize).subscribe(response => {
            this.listEscola = response.list;
            this.totalSize = response.totalSize;
            
            if(this.dtnUserWrapper.isOperadorEscola()){
                this.formFind.patchValue({cnpj: response.list[0].cnpj});
            }
        }, error => {
            if (error.status == 0) {
                this.showToast = true;
            }
            this.listEscola = new Array();
        });
    }

    escolaFindFilterEscolaByOperadorEscola() {
        const filters = this.formFind.value;
        console.log(this.escolaService.findById(this.dtnUserWrapper.getInstituicaoEscola().getId()) );
        this.escolaService.find(filters, this.page - 1, this.pageSize).subscribe(response => {
            
            this.listEscola = response.list;
            this.totalSize = response.totalSize;
        }, error => {
            if (error.status == 0){
                this.showToast = true;
        }
            this.listEscola = new Array();
    });
}

limparForm() {
    this.formFind.patchValue({
        nome: '',
        cnpj: '',
        dominioIdSituacao: ''
    });
}

}
