export class AgendamentoCancelamento {
    dataAgendada: any;
    periodo: string;
    prazo: number;
    situacao: string;
    dataAgendamento: any;
    usuarioAgendamento: string;
    usuarioTermo: string;
    cpf: string;
    dataAssinatura: any;
    dataCancelamento: any;
    usuarioCancelamento: string;
}
