import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AgrupamentoRelatorioEnum } from "src/app/utils/agrupamento-relatorio.enum";
import { ChartConfig } from "src/app/utils/ng2-charts/chart-config";
import { ChartData } from "src/app/utils/ng2-charts/chart-data";
import { ChartTypeEnum } from "src/app/utils/ng2-charts/chart-type.enum";


@Component({
  selector: 'app-escolas-atendidas-chart',
  templateUrl: './escolas-atendidas-chart.component.html',
  styleUrls: ['./escolas-atendidas-chart.component.css']
})
export class EscolasAtendidasChartComponent implements OnInit {
  @Input() mes: number;
  @Input() tipoMes: number;
  @Input() ano: number;
  @Input() data: ChartData[];

  chart: ChartConfig;
  titulo: string;

  readonly label = {
    0: ['Tipo Escola'],
    1: ['Janeiro', 'Fevereiro', 'Março', 'Abril'],
    2: ['Maio', 'Junho', 'Julho', 'Agosto'],
    3: ['Setembro', 'Outubro', 'Novembro', 'Dezembro']
  }

  readonly grupoMeses = {
    0: '',
    1: 'Jan. à Abr.',
    2: 'Mai. à Ago.',
    3: 'Set. à Dez.'
  }

  readonly meses = {
    0: '',
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
  };

  constructor() {
    this.chart = new ChartConfig();
  }

  ngOnInit(): void {
    this.chart.datasets = this.data;
    this.chart.labels = this.label[this.tipoMes];
    this.chart.colors = [
      { backgroundColor: '#2CBFFF' },
      { backgroundColor: '#FA9B48' },
      { backgroundColor: '#8F8784' },
      { backgroundColor: '#FFF16C' }
    ];
    this.chart.type = ChartTypeEnum.Bar;

    this.titulo = `Quantidade de Escolas Atendidas - ${this.mes ? this.meses[this.mes] : this.grupoMeses[this.tipoMes]} ${this.ano}`;
  }
}
