<div class="jumbotron" style="text-align: center;">
  <h3>Aprovar Modelo Termo de Compromisso</h3>
</div>

<div class="card mb-3">
  <div class="card-title text-center mt-1 mb-0">
    <h6>
      Para analisar as informações clique no botão
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
      </svg>
      'Visualizar' abaixo
    </h6>
  </div>
</div>
<form [formGroup]="formFiltro" (ngSubmit)="buscar()">
  <div class="row">
    <div class="col-md-12">
      <table class="table table-condensed table-hover">
        <thead>
          <th class="clNumero align-middle">
            Número
            <input type="text" class="col-md-12 form-control" id="numero" formControlName="numero" />
          </th>
          <th class="clData align-middle">
            Data
            <input type="date" class="col-md-12 form-control" id="data" formControlName="dataEnvioAprovacao" />
          </th>
          <th class="align-middle">
            Nome
            <input type="text" class="col-md-12 form-control" id="nome" formControlName="descricao" />
          </th>
          <th class="clIcone">
            <button type="submit" class="btn btn-outline-secondary" onclick="this.blur()"
            [disabled]="!formFiltro.valid">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-search"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                <path fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
            </svg>
        </button>
          </th>
        </thead>
        <tbody>
          <tr *ngFor="let md of modelosTermo">
            <td>{{md.numero}}</td>
            <td>{{md.dataEnvioAprovacao}}</td>
            <td>{{md.descricao}}</td>
            <td>
              <button type="button" class="btn btn-outline-secondary" placement="bottom" ngbTooltip="Visualizar"
                routerLink="{{md.id}}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </button>
            </td>
          </tr>
          <tr *ngIf="modelosTermo.length == 0">
            <td colspan="4" align="center">
              Nenhum registro encontrado.
            </td>
          </tr>
        </tbody>
      </table>
      <app-pagination *ngIf="modelosTermo.length != 0" [totalSize]="totalSize" [(page)]="page"
        [pageSize]="pageSize" (pageChange)="buscar()"></app-pagination>
    </div>
  </div>
</form>
