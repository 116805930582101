import { Component, OnInit } from '@angular/core';
import { EnderecoEscolaService } from 'src/app/services/endereco-escola.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageError } from 'src/app/utils/message-error';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { UtilityService } from 'src/app/services/utility.service';
import { DiagnosticoEscolaService } from 'src/app/services/diagnostico-escola.service';
import { MessageService } from 'src/app/services/message.service';
import { DominioIdEscolaSituacao } from 'src/app/utils/dominio-enum'
import { DominioIdDescricaoDTO } from 'src/app/utils/dominio-id-descricao.dto';
import { DominioService } from 'src/app/services/dominio.service';

@Component({
    selector: 'app-endereco-form',
    templateUrl: './endereco-form.component.html',
    styleUrls: ['./endereco-form.component.css']
})
export class EnderecoFormComponent implements OnInit {

    public MASKS = MASKS;

    formulario: FormGroup;
    messageError: MessageError;
    dominioIdSituacao: number;

    listDominioEscolaTipo: DominioIdDescricaoDTO[];

    idEscola;
    listUF = [{
        key: "MS",
        value: "MS"
    }];

    listMunicipio = [{
        key: "Campo Grande",
        value: "Campo Grande"
    }];

    focus: any = {
        cep: false,
        telefone: false,
        celular: false,
        email: false,
        coordenadorCelular: false,
        diretorCelular: false
    }

    constructor(private enderecoEscolaService: EnderecoEscolaService,
        private diagnosticoEscolaService: DiagnosticoEscolaService,
        private messageService: MessageService,
        private modalService: ModalUiService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private dominioService: DominioService,
        private utilityService: UtilityService) {
        this.messageError = new MessageError();
    }

    ngOnInit(): void {

        let params: Observable<Params> = this.activatedRoute.params;
        params.subscribe(urlParams => {
            this.idEscola = urlParams['idEscola'];
            this.findEnderecoEscola(this.idEscola);

        })

        this.formulario = this.formBuilder.group({
            id: [null],
            cnpj: [{ value: null, disabled: true }],
            endereco: [null, [Validators.required]],
            bairro: [null, [Validators.required]],
            numero: [null, [Validators.required]],
            cep: [null, [Validators.required, Validators.minLength(8), NgBrazilValidators.cep]],
            complemento: [null],
            situacao: [{ value: null, disabled: true }],
            municipio: [{ value: "Campo Grande", disabled: true }],
            uf: [{ value: "MS", disabled: true }],
            escola: [{ value: null, disabled: true }, [Validators.required]],
            dominioIdTipo: [null, [Validators.required]],
            telefone: [null, [Validators.required, NgBrazilValidators.telefone]],
            celular: [null, [Validators.required, NgBrazilValidators.celular]],
            email: [null, [Validators.required, Validators.email]],
            ativo: [null],
            coordenadorNome: [null, [Validators.required]],
            coordenadorCelular: [null, [Validators.required, NgBrazilValidators.celular]],
            diretorCelular: [null],
            diretorNome: [null],
            idInstituicaoEscola: [null]

        });


        this.dominioService.getDominioEscolaTipo().subscribe(response => {
            this.listDominioEscolaTipo = response;
        });
    }


    findEnderecoEscola(id) {

        this.enderecoEscolaService.getEnderecoEscola(id).subscribe(response => {

            this.dominioIdSituacao = response.dominioIdSituacao;

            this.formulario.patchValue({
                id: response.id,
                cnpj: response.cnpj,
                endereco: response.endereco,
                bairro: response.bairro,
                numero: response.numero,
                cep: response.cep,
                complemento: response.complemento,
                escola: response.escola,
                dominioIdTipo: response.dominioIdTipo ?? '',
                situacao: response.situacao,
                telefone: response.telefone,
                celular: response.celular,
                email: response.email,
                ativo: response.ativo,
                coordenadorNome: response.coordenadorNome,
                coordenadorCelular: response.coordenadorCelular,
                diretorCelular: response.diretorCelular,
                diretorNome: response.diretorNome,
                idInstituicaoEscola: response.idInstituicaoEscola,
            });

        });

    }

    get f() { return this.formulario.controls; }

    onSubmit() {

        if (this.formulario.invalid)
            return

        const formEndereco = this.utilityService.replaceFieldsOnlyNumber(this.formulario.getRawValue(), [
            'cnpj', 'cep',
            'telefone', 'celular',
            'coordenadorCelular', 'diretorCelular'
        ]);
        
        formEndereco.usuario = this.utilityService.getDtnUserWrapper().getNomeUsuario();

        if (formEndereco.id == null) {
            this.enderecoEscolaService.create(formEndereco).subscribe(
                response => {
                    this.formulario.patchValue({
                        id: response.id,
                        situacao: response.situacao
                    });
                    this.tratarMensagemSucesso('cadastrado');

                },
                error => {
                    this.tratarMensagemErro('cadastrar', error);
                });

        }
        else {
            this.enderecoEscolaService.update(formEndereco)
                .subscribe(
                    response => {
                        this.tratarMensagemSucesso('alterado');
                    },
                    error => {
                        this.tratarMensagemErro('alterar', error);
                    });

        }
    }

    print() {
        this.diagnosticoEscolaService.print(this.idEscola).subscribe((response) => {

            let blob = new Blob([response], { type: 'application/pdf' });

            let downloadURL = window.URL.createObjectURL(blob);

            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "Modelo Diagnostico.pdf";
            link.click();

        }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível fazer o gerar a impressão", true, errorResponse, () => { })
        });
    }

    printBlank() {
        this.diagnosticoEscolaService.print(0).subscribe((response) => {

            let blob = new Blob([response], { type: 'application/pdf' });

            let downloadURL = window.URL.createObjectURL(blob);

            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "Modelo Diagnostico em branco.pdf";
            link.click();

        }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível fazer o gerar a impressão", true, errorResponse, () => { })
        });
    }

    get escolaAtualizada(): boolean {
        return this.dominioIdSituacao == DominioIdEscolaSituacao.ATUALIZADA;
    }

    private tratarMensagemSucesso(acao: string) {
        this.modalService.open({
            type: EnumModalType.SUCCESS,
            message: `Endereço ${acao} com sucesso`,
            buttons: [EnumModalButtons.OK],
            okEvent: () => { }
        });
    }

    private tratarMensagemErro(acao: string, errorResponse: any) {
        switch (errorResponse.status) {
            case 400:
            case 404:
            case 422:
                this.modalService.open({
                    type: EnumModalType.ALERT,
                    message: errorResponse.error.errors.join('. '),
                    buttons: [EnumModalButtons.OK],
                    okEvent: () => { }
                });
                break;
            default:
                this.modalService.open({
                    type: EnumModalType.ERROR,
                    message: `Não foi possível ${acao} o endereço`,
                    buttons: [EnumModalButtons.OK],
                    okEvent: () => { }
                });
                break;
        }
    }
}
