<div class="jumbotron" style="text-align: center;">
  <h3>Agendamento</h3>
</div>

<fieldset class="form-group border p-2">
  <legend class="w-auto">Informações do Agendamento</legend>
  <div class="form-row align-items-center">
    <div class="col-12">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Data Agendada</label>
        <div class="col-md-3">
          <input type="date" [disabled]="true" class="form-control" id="dataAgendada" [value]="agendamento.dataAgendada" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Período</label>
        <div class="col-md-3">
          <input type="text" [disabled]="true" class="form-control" id="periodo" [value]="agendamento.periodo" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Prazo Cadastrar Turma</label>
        <div class="col-md-3">
          <input type="text" [disabled]="true" class="form-control" id="prazo" [value]="agendamento.prazo" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Situação Agendamento</label>
        <div class="col-md-3">
          <input type="text" [disabled]="true" class="form-control" id="situacao" [value]="agendamento.situacao" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Data Agendamento</label>
        <div class="col-md-3">
          <input type="date" [disabled]="true" class="form-control" id="dataAgendamento" [value]="agendamento.dataAgendamento" />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group row">
        <label class="col-md-2 col-form-label">Usuario</label>
        <div class="col-md-5">
          <input type="text" [disabled]="true" class="form-control" id="usuario" [value]="agendamento.usuarioAgendamento" />
        </div>
      </div>
    </div>
  </div>
</fieldset>
<fieldset class="form-group border p-2">
  <legend class="w-auto">Informações do Termo de Compromisso</legend>
  <div class="form-group row">
    <label class="col-md-2 col-form-label">Usuário</label>
    <div class="col-md-5">
      <input type="text" [disabled]="true" class="form-control" id="usuarioTermo" [value]="agendamento.usuarioTermo" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label">CPF</label>
    <div class="col-md-5">
      <input type="text" [disabled]="true" class="form-control" id="cpf" [value]="agendamento.cpf" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label">Data Ciência</label>
    <div class="col-md-3">
      <input type="date" [disabled]="true" class="form-control" id="dataAssinatura" [value]="agendamento.dataAssinatura" />
    </div>
  </div>
</fieldset>
<fieldset class="form-group border p-2">
  <legend class="w-auto">Informações do Cancelamento</legend>
  <div class="form-group row">
    <label class="col-md-2 col-form-label">Data</label>
    <div class="col-md-3">
      <input type="date" [disabled]="true" class="form-control" id="dataCancelamento" [value]="agendamento.dataCancelamento" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label">Usuário</label>
    <div class="col-md-5">
      <input type="text" [disabled]="true" class="form-control" id="usuarioCancelamento" [value]="agendamento.usuarioCancelamento" />
    </div>
  </div>
</fieldset>
<div class="row p-2">
  <button type="button" class="btn btn-outline-secondary mr-2" routerLink="/agendamento">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle" fill="currentColor"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path fill-rule="evenodd"
            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
    </svg>
    Voltar
  </button>
</div>
