export enum ChartTypeEnum {
  Line = 'line',
  Bar = 'bar',
  horizontalBar = 'horizontalBar',
  Radar = 'radar',
  Pie = 'pie',
  PolarArea = 'polarArea',
  Doughnut = 'doughnut',
  Bubble = 'bubble',
  Scatter = 'scatter'
}
