import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModeloDiagnosticoAprovacaoListComponent } from './modelo-diagnostico-aprovacao-list/modelo-diagnostico-aprovacao-list.component';
import { ModeloDiagnosticoAprovacaoComponent } from './modelo-diagnostico-aprovacao/modelo-diagnostico-aprovacao.component';
import { ModeloDiagnosticoFormComponent } from './modelo-diagnostico-form/modelo-diagnostico-form.component';

const routes: Routes = [{ path: 'modelo-diagnostico',
children: [
  { path: '', redirectTo: '/modelo-diagnostico/form', pathMatch: 'full' },
  { path: 'form', component: ModeloDiagnosticoFormComponent },
  { path: 'aprovacao', component: ModeloDiagnosticoAprovacaoListComponent },
  { path: 'aprovacao/:id', component: ModeloDiagnosticoAprovacaoComponent }
] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModeloDiagnosticoRoutingModule { }
