import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ServicesModule } from "src/app/services/services.module";
import { TemplateModule } from "src/app/template/template.module";
import { AutorizacaoComponent } from "./autorizacao/autorizacao.component";
import { EtiquetaComponent } from "./etiqueta/etiqueta.component";
import { ImpressoesRoutingModule } from "./impressoes-routing.module";

@NgModule({
  declarations: [
    AutorizacaoComponent,
    EtiquetaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    TemplateModule,
    ServicesModule,
    ImpressoesRoutingModule
  ],
  exports: []
})
export class ImpressoesModule {

}
