import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarioFormComponent } from './calendario-form/calendario-form.component';
import { CalendarioViewComponent } from './calendario-view/calendario-view.component';

const routes: Routes = [{
    path: 'calendario',
    children: [
        { path: '', redirectTo: '/calendario', pathMatch: 'full' },
        { path: '', component: CalendarioFormComponent },
        { path: 'visualizar/:id', component: CalendarioViewComponent }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CalendarioRoutingModule {

}
