import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarioStateService } from 'src/app/services/calendario-state.service';
import { CalendarioService } from 'src/app/services/calendario.service';
import { CalendarComponent } from 'src/app/template/calendar/calendar.component';
import { EventDTO } from 'src/app/utils/calendar-event.dto';
import { CalendarioStateEnum } from 'src/app/utils/calendario-state.enum';
import { DominioIdEventoCalendario } from 'src/app/utils/dominio-enum';
import { EventDefaultColorEnum } from 'src/app/utils/event-default-color.enum';
import { CalendarioAtualizacaoFormComponent } from "src/app/modules/calendario/calendario-atualizacao/calendario-atualizacao-form.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AgendamentoDisponibilidade } from 'src/app/models/agendamento-disponibilidade';
import { MessageService } from 'src/app/services/message.service';
import { AgendaData } from 'src/app/models/agenda-data';
@Component({
    selector: 'app-calendario-view',
    templateUrl: './calendario-view.component.html',
    styleUrls: ['./calendario-view.component.css']
})
export class CalendarioViewComponent implements OnInit, AfterViewInit {
    canGetAvailability: boolean = false;
    eventColor = EventDefaultColorEnum;
    idMes: number = 11;
    calendarOptions = {
        headerToolbar: { left: '', center: 'title', right: '' },
        selectable: true,
        selectOverlap: false,
        dateClick: this.abrirModalAgendamento.bind(this),
        events: this.montaEventos.bind(this)
    };

    eventos: AgendaData[];
    agendamentoId: Boolean = true;
    @ViewChild(CalendarComponent)
    private calendar: CalendarComponent;

    constructor(private calendarioService: CalendarioService,
        private ngbModalService: NgbModal,
        private stateService: CalendarioStateService,
//         private agendamentoService: AgendamentoService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.stateService.updateState(CalendarioStateEnum.Visualizacao);
         if (this.route.snapshot.params['agendamentoId'] != undefined) {
            this.agendamentoId = false}
    }

    ngAfterViewInit(): void {
        this.montaEventos(+this.route.snapshot.params['id']);
        this.canGetAvailability = true;
        let now = new Date();
        now.setMonth(now.getMonth() +1);
        this.calendar.calendarApi.gotoDate(now);
    }


    abrirModalAgendamento(info) {
        let evento = this.eventos ? this.eventos.find(e => e.data == info.dateStr) : null;
        
        if (this.route.snapshot.params['agendamentoId'] != undefined) {
            const modal = this.ngbModalService.open(CalendarioAtualizacaoFormComponent, { backdrop: 'static' });
            modal.componentInstance.data = this.route.snapshot.params['data'];
            modal.componentInstance.periodo = this.route.snapshot.params['periodo'];
            modal.componentInstance.agendamentoId = this.route.snapshot.params['agendamentoId'];
            console.log("AgendamentoId" + this.route.snapshot.params['agendamentoId'])
            if(evento.dominioSituacao == 33){
                evento.periodo = null;
            modal.componentInstance.evento = evento;}
            modal.componentInstance.evento = evento;
            console.log("Evento" + evento.data)
//            modal.componentInstance.escolaId = this.escolaId;
    }
    }
    montaEventos(agendaId: number) {
        this.calendarioService.listEvents(agendaId)
            .subscribe(response => {
                this.eventos = response.list;    
                if (response.totalSize > 0) {
                    let eventos: EventDTO[] = [];
                    response.list.forEach((item) => {
                        if((item.periodo == 28 || item.periodo == 29) && item.dominioSituacao != 33){
                            let novoEvento: EventDTO = {
                                id: item.id,
                                start: item.data,
                                display: 'background',
                                startTime: item.horaInicio,
                                endTime: item.horaFim,
                                color: this.corDoEvento(item.periodo)
                            };
                            eventos.push(novoEvento);
                        }else{
                            let novoEvento: EventDTO = {
                                id: item.id,
                                start: item.data,
                                display: 'background',
                                startTime: item.horaInicio,
                                endTime: item.horaFim,
                                color: this.corDoEvento(item.dominioIdEvento)
                            };
                            eventos.push(novoEvento);
                             }
                    });
                
                    this.calendar.options.events = eventos as any[];
                    this.calendar.calendarApi.gotoDate(eventos[0].start);
                    
                }
            }, err => {
                if(this.idMes > 14){
                    this.idMes -= 1
            }});
    }


    avancarEvento(){        
        this.idMes += 1
        this.montaEventos(this.idMes);
    }
    
    voltarEvento(){
        if(this.idMes > 11){
            this.idMes -= 1
            this.montaEventos(this.idMes);
        }
    }


//        carregaEventos(fetchinfo, successCallback, failureCallback) {
//        if (this.canGetAvailability) {
//            this.agendamentoService.availability()
//                .subscribe(data => {
//                    this.eventos = data;
//                    successCallback(data.map(evento => {
//                       if(evento.periodo.length == 1){
//                           if(evento.periodo[0] == 29){
//                                evento.periodo[0] = 28
//                               }else{
//                                    evento.periodo[0] = 29
//                                }
//                        let novoEvento: EventDTO = {
//                            id: evento.agendaDataId,
//                            start: evento.data,
//                            display: 'background',
//                            color: this.corDoEvento(evento.periodo[0])
//                        };
//                        return novoEvento;
//                       }else{
//                           let novoEvento: EventDTO = {
//                            id: evento.agendaDataId,
//                            start: evento.data,
//                            display: 'background',
//                            color: this.corDoEvento(evento.dominioIdCalendarioEvento)
//                        };
//                            return novoEvento;
//                           }
//                    }))
//                }, err => {
//                    this.messageService.tratarMensagemErro(err, true, err, () => {
//                        this.router.navigate(['/agendamento']);
//                    });
//                    failureCallback(err);
//                });
//        }
//    }
    
    corDoEvento(dominioIdEvento: number): string {
        switch (dominioIdEvento) {
            case DominioIdEventoCalendario.NAO_DISPONIVEL:
                return EventDefaultColorEnum.NAO_DISPONIVEL;
            case DominioIdEventoCalendario.AGENDADA:
                return EventDefaultColorEnum.AGENDADA;
            case DominioIdEventoCalendario.DISPONIVEL:
                return EventDefaultColorEnum.DISPONIVEL;
            case DominioIdEventoCalendario.FERIADO_RECESSO:
                return EventDefaultColorEnum.FERIADO;
            case DominioIdEventoCalendario.FERIAS_RECESSO:
                return EventDefaultColorEnum.FERIAS_RECESSO;
            case DominioIdEventoCalendario.INDISPONIVEL:
                return EventDefaultColorEnum.INDISPONIVEL;
            case DominioIdEventoCalendario.PLANEJAMENTO:
                return EventDefaultColorEnum.PLANEJAMENTO;
            case DominioIdEventoCalendario.REUNIAO:
                return EventDefaultColorEnum.REUNIAO;
            case DominioIdEventoCalendario.MANHA:
                return EventDefaultColorEnum.MANHA;
            case DominioIdEventoCalendario.TARDE:
                return EventDefaultColorEnum.TARDE;
        }

        return '';
    }
}
