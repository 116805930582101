import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Agenda } from '../models/agenda';
import { AgendaData } from '../models/agenda-data';
import { ReponsePaginationListDTO } from '../utils/response-pagination-list.dto';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {
  constructor(private http: HttpClient) { }

  listMonths(agenda: Agenda, page: number, pageSize: number) : Observable<ReponsePaginationListDTO<Agenda>> {
    let httpParams = new HttpParams();
    Object.keys(agenda).forEach(
      key => agenda[key] && (httpParams = httpParams.append(key, agenda[key]))
    );

    httpParams = httpParams.append("page", page.toString());
    httpParams = httpParams.append("pageSize", pageSize.toString());

    return this.http.get<ReponsePaginationListDTO<Agenda>>(`${environment.apiBaseUrl}/agenda`, {
      params: httpParams
    });
  }

  findMonthById(id: number) : Observable<Agenda> {
    return this.http.get<Agenda>(`${environment.apiBaseUrl}/agenda/${id}`);
  }

  saveMonth(agenda: Agenda) : Observable<Agenda> {
    return this.http.post<Agenda>(`${environment.apiBaseUrl}/agenda`, agenda);
  }

  updateMonth(agenda: Agenda) : Observable<Agenda> {
    
    return this.http.put<Agenda>(`${environment.apiBaseUrl}/agenda/${agenda.id}`, agenda);
  }

  listDate(agendaData: AgendaData, page: number, pageSize: number) : Observable<ReponsePaginationListDTO<AgendaData>> {
    let httpParams = new HttpParams();
    Object.keys(agendaData).forEach(
      key => agendaData[key] && (httpParams = httpParams.append(key, agendaData[key]))
    );

    httpParams = httpParams.append("page", page.toString());
    httpParams = httpParams.append("pageSize", pageSize.toString());

    return this.http.get<ReponsePaginationListDTO<AgendaData>>(`${environment.apiBaseUrl}/agenda/data`, {
      params: httpParams
    });
  }

  listEvents(agendaId: number) : Observable<ReponsePaginationListDTO<AgendaData>> {
    return this.http.get<ReponsePaginationListDTO<AgendaData>>(`${environment.apiBaseUrl}/agenda/data/events/${agendaId}`);
  }

  findDateById(id: number) : Observable<AgendaData> {
    return this.http.get<AgendaData>(`${environment.apiBaseUrl}/agenda/data/${id}`);
  }

  saveDate(agendaData: AgendaData) : Observable<AgendaData> {
    return this.http.post<AgendaData>(`${environment.apiBaseUrl}/agenda/data`, agendaData);
  }

  updateDate(agendaData: AgendaData) : Observable<AgendaData> {
    return this.http.put<AgendaData>(`${environment.apiBaseUrl}/agenda/data/${agendaData.id}`, agendaData);
  }

  deleteDate(id: number) : Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/agenda/data/${id}`);
  }
}
