import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Dominio } from '../models/dominio';
import { DominioIdDescricaoDTO } from '../utils/dominio-id-descricao.dto';

@Injectable({
  providedIn: 'root'
})
export class DominioService {

  private readonly BASE_URL = `${environment.apiBaseUrl}/dominio/`;

  private readonly ESCOLA_SITUACAO_URL = `${this.BASE_URL}escola-situacao`;
  private readonly ESCOLA_TIPO_URL = `${this.BASE_URL}escola-tipo`;
  private readonly ESCOLA_MOD_DIAG_SITUACAO = `${this.BASE_URL}modelo-diagnostico-situacao`;
  private readonly ESCOLA_MOD_DIAG_VARIAVEL_TIPO_ALTERNATIVA = `${this.BASE_URL}modelo-diagnostico-variavel-tipo-alternativa`;
  private readonly CALENDARIO_SITUACAO = `${this.BASE_URL}calendario-situacao`;
  private readonly CALENDARIO_EVENTO = `${this.BASE_URL}calendario-evento`;
  private readonly AGENDAMENTO_SITUACAO = `${this.BASE_URL}agendamento-situacao`;
  private readonly RESPONSAVEL_FUNCAO = `${this.BASE_URL}responsavel-funcao`;
  private readonly PERIODO = `${this.BASE_URL}periodo`;
  private readonly ESCOLA_MOD_TERM_SITUACAO = `${this.BASE_URL}modelo-termo-situacao`;

  constructor(private http: HttpClient) { }

  getDominioEscolaSituacao() : Observable<DominioIdDescricaoDTO[]>{
    return this.http.get<DominioIdDescricaoDTO[]>(this.ESCOLA_SITUACAO_URL);
  }

  getDominioEscolaTipo() : Observable<DominioIdDescricaoDTO[]>{
    return this.http.get<DominioIdDescricaoDTO[]>(this.ESCOLA_TIPO_URL);
  }

  getDominioModeloDiagnostivoSituacao() : Observable<DominioIdDescricaoDTO[]>{
    return this.http.get<DominioIdDescricaoDTO[]>(this.ESCOLA_MOD_DIAG_SITUACAO);
  }

  getDominioModeloDiagnostivoTipoAlternativa() : Observable<DominioIdDescricaoDTO[]>{
    return this.http.get<DominioIdDescricaoDTO[]>(this.ESCOLA_MOD_DIAG_VARIAVEL_TIPO_ALTERNATIVA);
  }

  getDominioCalendarioSituacao() : Observable<Dominio[]> {
    return this.http.get<Dominio[]>(this.CALENDARIO_SITUACAO);
  }

  getDominioCalendarioEvento(): Observable<Dominio[]> {
    return this.http.get<Dominio[]>(this.CALENDARIO_EVENTO);
  }

  getDominioAgendamentoSituacao() : Observable<Dominio[]> {
    return this.http.get<Dominio[]>(this.AGENDAMENTO_SITUACAO);
  }

  getDominioResponsavelFuncao() : Observable<Dominio[]> {
    return this.http.get<Dominio[]>(this.RESPONSAVEL_FUNCAO);
  }

  getDominioPeriodo() : Observable<Dominio[]> {
    return this.http.get<Dominio[]>(this.PERIODO);
  }
  
  getDominioModeloTermoSituacao() : Observable<DominioIdDescricaoDTO[]>{
    return this.http.get<DominioIdDescricaoDTO[]>(this.ESCOLA_MOD_TERM_SITUACAO);
  }
}
