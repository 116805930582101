import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModeloTermoTexto } from 'src/app/models/modelo-termo-texto';
import { DominioService } from 'src/app/services/dominio.service';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloTermoTextoService } from 'src/app/services/modelo-termo-texto.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { DominioIdTipoAlternativa } from 'src/app/utils/dominio-enum';
import { DominioIdDescricaoDTO } from 'src/app/utils/dominio-id-descricao.dto';
import { MessageError } from 'src/app/utils/message-error';
import { ActivatedRoute, Router } from '@angular/router';
import { ModeloTermoCompromissoService } from 'src/app/services/modelo-termo.service';
import { ModeloTermoFormComponent } from '../modelo-termo-form/modelo-termo-form.component';

 
 @Component({
  selector: 'app-modelo-termo-texto',
  templateUrl: './modelo-termo-texto.component.html',
  styleUrls: ['./modelo-termo-texto.component.css']
})
export class ModeloTermoTextoComponent implements OnInit {
  formModeloTermoTexto : FormGroup;
  id: number;
  maxInputs: number = 2;
  page: number;
  pageSize: number = 10;
  totalSize: number;
  formModeloTermoVariavelDadosFind: FormGroup;
  messageError: MessageError;
  idModelo: number;
  @Input() idModeloTermo: number;
  submitted = false;
 
  constructor (
    private fb: FormBuilder,
    private dominioService: DominioService,
    private modalService: ModalUiService,
    private modeloTermoTextoService: ModeloTermoTextoService,
    private modalAlternativaService: NgbModal,
    private utils: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private modeloTermoService: ModeloTermoCompromissoService,
    @Optional() public parentComponent: ModeloTermoFormComponent
  ) {
  }
  
  ngOnInit(): void {
    this.buscar(this.route.snapshot.params['id'], false);
  }
  
  buscar(id: number, desativar: boolean) {
    this.idModelo = id;
    if(this.idModelo != undefined){
          this.formModeloTermoTexto = this.fb.group({
        //      cabecalho: [''],
              responsabilidadeCidadeEscolaTransito: [''],
              responsabilidadeEscola: [''],
              infoPeriodoMatutino: [''],
              infoPeriodoVespetino: [''],
              infoHorarioSaidaDoOnibus: [''],
              infoSeriesAtendidas: [''],
              infoTurmas: [''],
              observacao: [''],
              maioresInformacoes: [''],
              input1: [''],
              input2: [''],
              informacoesSobreVisita: ['']
            });

          const replaceBrWithNewline = (str: string) => str.replace(/<br\s*\/?>/gi, '\n');
          this.modeloTermoService.findById(this.idModelo).subscribe(data => {
            this.formModeloTermoTexto.patchValue({
              responsabilidadeCidadeEscolaTransito: replaceBrWithNewline(data.responsabilidadeCidadeEscolaTransito),
              responsabilidadeEscola: replaceBrWithNewline(data.responsabilidadeEscola),
              infoPeriodoMatutino: replaceBrWithNewline(data.infoPeriodoMatutino),
              infoPeriodoVespetino: replaceBrWithNewline(data.infoPeriodoVespetino),
              infoHorarioSaidaDoOnibus: replaceBrWithNewline(data.infoHorarioSaidaDoOnibus),
              infoSeriesAtendidas: replaceBrWithNewline(data.infoSeriesAtendidas),
              infoTurmas: replaceBrWithNewline(data.infoTurmas),
              observacao: replaceBrWithNewline(data.observacao),
              maioresInformacoes: replaceBrWithNewline(data.maioresInformacoes),
              input1: replaceBrWithNewline(data.input1),
              input2: replaceBrWithNewline(data.input2),
              informacoesSobreVisita: replaceBrWithNewline(data.infoSobreVisita)
            });    
            
            this.ativarCampos();
            
            if(desativar) {
                this.desativarCampos();
            } 
             
        });
    }    
    if(this.idModelo == undefined){      
        this.formModeloTermoTexto = this.fb.group({
          responsabilidadeCidadeEscolaTransito: new FormControl('', Validators.required),
          responsabilidadeEscola: new FormControl('', Validators.required),
          infoPeriodoMatutino: new FormControl('', Validators.required),
          infoPeriodoVespetino: new FormControl('', Validators.required),
          infoHorarioSaidaDoOnibus: new FormControl('', Validators.required),
          infoSeriesAtendidas: new FormControl('', Validators.required),
          infoTurmas: new FormControl('', Validators.required),
          observacao: new FormControl('', Validators.required),
          maioresInformacoes: new FormControl('', Validators.required),
          input1: new FormControl(''),
          input2: new FormControl(''),
          informacoesSobreVisita: new FormControl('', Validators.required),
        });
          this.page = 1;
          this.totalSize = 0;
          this.messageError = new MessageError();
    }
  }
 
 get inputs(): FormArray {
    return this.formModeloTermoTexto.get('inputs') as FormArray;
  }
  
    ativarCampos() {
        this.formModeloTermoTexto.get('responsabilidadeCidadeEscolaTransito').enable();
        this.formModeloTermoTexto.get('responsabilidadeEscola').enable();
        this.formModeloTermoTexto.get('informacoesSobreVisita').enable();
        this.formModeloTermoTexto.get('infoPeriodoMatutino').enable();
        this.formModeloTermoTexto.get('infoPeriodoVespetino').enable();
        this.formModeloTermoTexto.get('infoHorarioSaidaDoOnibus').enable();
        this.formModeloTermoTexto.get('infoSeriesAtendidas').enable();
        this.formModeloTermoTexto.get('infoTurmas').enable();
        this.formModeloTermoTexto.get('observacao').enable();
        this.formModeloTermoTexto.get('maioresInformacoes').enable();
        this.formModeloTermoTexto.get('input1').enable();
        this.formModeloTermoTexto.get('input2').enable();
        
        document.getElementById('salvarTexto').hidden = false;
    }
     
    desativarCampos() {
        this.formModeloTermoTexto.get('responsabilidadeCidadeEscolaTransito').disable();
        this.formModeloTermoTexto.get('responsabilidadeEscola').disable();
        this.formModeloTermoTexto.get('informacoesSobreVisita').disable();
        this.formModeloTermoTexto.get('infoPeriodoMatutino').disable();
        this.formModeloTermoTexto.get('infoPeriodoVespetino').disable();
        this.formModeloTermoTexto.get('infoHorarioSaidaDoOnibus').disable();
        this.formModeloTermoTexto.get('infoSeriesAtendidas').disable();
        this.formModeloTermoTexto.get('infoTurmas').disable();
        this.formModeloTermoTexto.get('observacao').disable();
        this.formModeloTermoTexto.get('maioresInformacoes').disable();
        this.formModeloTermoTexto.get('input1').disable();
        this.formModeloTermoTexto.get('input2').disable();
        
        document.getElementById('salvarTexto').hidden = true;
        
        this.formModeloTermoTexto.updateValueAndValidity({onlySelf:true});
    }
        
  submit(): void {
//    if (!this.formModeloTermoTexto.valid) {
//        return;
//    }
    const jsonTexto: ModeloTermoTexto = this.formModeloTermoTexto.value;
    jsonTexto.idModeloTermo = this.idModeloTermo;
    jsonTexto.usuario = this.utils.getDtnUserWrapper().getNomeUsuario();
//    jsonTexto.cabecalho = (document.getElementById('cabecalho') as HTMLTextAreaElement).value;
    jsonTexto.infoHorarioSaidaDoOnibus = (document.getElementById('informacoesSobreVisitaHorarioPrevistoSaidaOnibus') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.infoPeriodoMatutino = (document.getElementById('informacoesSobreVisitaPeriodoMatutino') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.infoPeriodoVespetino = (document.getElementById('informacoesSobreVisitaPeriodoVespetino') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.infoSeriesAtendidas = (document.getElementById('informacoesSobreVisitaSeriesAtendidas') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.infoTurmas = (document.getElementById('informacoesSobreVisitaTurmas') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.maioresInformacoes = (document.getElementById('maioresInformacoes') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.observacao = (document.getElementById('observacao') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.responsabilidadeCidadeEscolaTransito = (document.getElementById('responsabilidadeCidadeEscolaTransito') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.responsabilidadeEscola = (document.getElementById('responsabilidadeEscola') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.input1 = (document.getElementById('input1') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.input2 = (document.getElementById('input2') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');
    jsonTexto.infoSobreVisita = (document.getElementById('informacoesSobreVisita') as HTMLTextAreaElement).value.replace(/\n/g, '<br>');

  
        this.modeloTermoTextoService.update(this.idModeloTermo, jsonTexto)
          .subscribe(
          (response) => {
            this.tratarMensagemSucesso('alterado', this.id);
          },
          (errorResponse) => {
            this.tratarMensagemErro('alterar', errorResponse);
          }
        );
    } 
     
  limparForm() {
    this.id = null;
    this.formModeloTermoTexto.patchValue({
      text: '',
    });
    this.parentComponent.limparForm();
    this.cleanValidatorsError();
  }
  
  private tratarMensagemSucesso(acao: string, id: number) {
    this.modalService.open({
      type: EnumModalType.SUCCESS,
      message: `Texto do modelo termo compromisso ${acao} com sucesso`,
      buttons: [EnumModalButtons.OK],
      okEvent: () => {
        this.limparForm();
         var elemento = document.getElementById('ngb-tab-0');
         elemento.click();
      },
    });
  }

  private tratarMensagemErro(acao: string, errorResponse: any) {
    switch (errorResponse.status) {
      case 400:
      case 404:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: errorResponse.error.errors.join('. '),
          buttons: [EnumModalButtons.OK],
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: `Não foi possível ${acao} texto do modelo termo compromisso`,
          buttons: [EnumModalButtons.OK],
          okEvent: () => {
          },
        });
        break;
    }
  }
  
  voltar() {
    document.getElementById('ngb-tab-0').click();
  }

  private cleanValidatorsError() {
    this.formModeloTermoTexto.markAsPristine();
    this.formModeloTermoTexto.markAsUntouched();
  } 
//  
//  addInput(): void{
//      if (this.inputs.length < this.maxInputs){
//             this.inputs.push(this.createInput());
//             const textarea = document.getElementById('paragrafoAdicional1');
//          }
//      }
//      
// createInput(): FormGroup {
//    return this.fb.group({
//      text: ['', Validators.required],
//      id: ['paragrafoAdicional2']
//    });
//  }   
// 
//  removeInput (index: number): void{
//      if(index != 0){
//        this.inputs.removeAt(index);
//      }}
//      
//  focusToStart(event: any) {
//    if (event.target.value.trim()) {
//    // Se não houver texto, não faça nada
//        return;
//    }
//    event.target.setSelectionRange(0, 0);
//  }
}