import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoCompromissoAssinado } from 'src/app/models/termo-compromisso-assinado';
import { TermoCompromisso } from 'src/app/models/termo-compromisso';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { MessageService } from 'src/app/services/message.service';
import { DiagnosticoEscolaService } from 'src/app/services/diagnostico-escola.service';


@Component({
    selector: 'app-termo-compromisso-form',
    templateUrl: './termo-compromisso-form.component.html',
    styleUrls: ['./termo-compromisso-form.component.css']
})
export class TermoCompromissoFormComponent implements OnInit {

    id: number;
    idEscola: number;
    form: FormGroup;
    termoCompromisso: TermoCompromisso;
    termoCompromissoAssinado: TermoCompromissoAssinado;

    constructor(private formBuilder: FormBuilder,
        private messageService: MessageService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private agendamentoService: AgendamentoService,
        private diagnosticoEscolaService: DiagnosticoEscolaService) { }


    ngOnInit(): void {
        let params: Observable<Params> = this.activatedRoute.params;
        params.subscribe(urlParams => {
            this.id = urlParams['idEscola'];

            this.printAgendamento();

            this.agendamentoService.getTermoAssinado(this.id).subscribe(response => {
                this.termoCompromisso = response
                
                if (this.termoCompromisso.assinado) {
                    this.form.patchValue({ acceptTerms: true });
                    this.form.get('acceptTerms').disable();
                }
            },
                errorResponse => {
                    this.messageService.tratarMensagemErro("Erro ao buscar informações da escola", true, errorResponse, () => { });
                });
        });

        this.form = this.formBuilder.group({
            acceptTerms: [false, Validators.requiredTrue]
        });
    }

    print() {
        this.agendamentoService.printTerm(this.id).subscribe((response) => {

            let blob = new Blob([response], { type: 'application/pdf' });

            let downloadURL = window.URL.createObjectURL(blob);

            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "TermoDeCompromisso.pdf";
            link.click();

        }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível fazer o gerar a impressão", true, errorResponse, () => { })
        });
    }

    submit(): void {
        if (!this.form.controls.acceptTerms.value) {
            this.messageService.aviso('Ops! Você esqueceu de concordar.');
        }

        this.router.navigate(['/agendamento/criar/'+ this.id]);
    }

     printAgendamento() {
        this.agendamentoService.printTermAgendamento(this.id).subscribe((response) => {          
            let file = new Blob([response], { type: 'application/pdf' });            
            var fileURL = URL.createObjectURL(file);
            document.getElementById('pdfEmbed').setAttribute('src', fileURL);
          }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível gerar a impressão", true, errorResponse, () => { });
          });
    }

}
