import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModeloTermoAprovacaoListComponent } from './modelo-termo-aprovacao-list/modelo-termo-aprovacao-list.component';
import { ModeloTermoAprovacaoComponent } from './modelo-termo-aprovacao/modelo-termo-aprovacao.component';
import { ModeloTermoFormComponent } from './modelo-termo-form/modelo-termo-form.component';
import { ModeloTermoTextoComponent } from './modelo-termo-texto/modelo-termo-texto.component';
 
const routes: Routes = [{ path: 'modelo-termo',
children: [
  { path: '', redirectTo: '/modelo-termo/form', pathMatch: 'full' },
  { path: 'form', component: ModeloTermoFormComponent },
  { path: 'aprovacao', component: ModeloTermoAprovacaoListComponent },
  { path: 'aprovacao/:id', component: ModeloTermoAprovacaoComponent },
  { path: 'texto/:id', component: ModeloTermoTextoComponent }
] }];
 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModeloTermoRoutingModule { }