import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModeloDiagnostico } from 'src/app/models/modelo-diagnostico';
import { ModalUiService } from 'src/app/services/modal-ui.service';
import { ModeloDiagnosticoService } from 'src/app/services/modelo-diagnostico.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EnumModalButtons, EnumModalType } from 'src/app/template/modal/enum-modal';
import { MessageError } from 'src/app/utils/message-error';
import { ModeloDiagnosticoListComponent } from '../modelo-diagnostico-list/modelo-diagnostico-list.component';
import { ModeloDiagnosticoQuestoesComponent } from '../modelo-diagnostico-questoes/modelo-diagnostico-questoes.component';


@Component({
  selector: 'app-modelo-diagnostico-form',
  templateUrl: './modelo-diagnostico-form.component.html',
  styleUrls: ['./modelo-diagnostico-form.component.css']
})
export class ModeloDiagnosticoFormComponent implements OnInit {

  formModeloDiagnosticoDadosCreate: FormGroup;
  id: number;
  submitted: boolean = false;
  messageError: MessageError;

  @ViewChild(ModeloDiagnosticoListComponent) 
  private childModeloDiagnosticoListComponent: ModeloDiagnosticoListComponent;

  @ViewChild(ModeloDiagnosticoQuestoesComponent) 
  private childModeloDiagnosticoQuestoesComponent: ModeloDiagnosticoQuestoesComponent;

  constructor(
    private fb: FormBuilder,
    public util: UtilityService,
    private modeloDiagnosticoService: ModeloDiagnosticoService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private modalService: ModalUiService
  ) {
    
  }

  ngOnInit(): void {

    this.formModeloDiagnosticoDadosCreate = this.fb.group({
      numero: new FormControl({ value: '', disabled: true }),
      data: new FormControl({ value: '', disabled: true }),
      descricao: new FormControl('', [Validators.maxLength(200), Validators.required]),
      ativo: new FormControl({ value: true, disabled: true }, [Validators.required])
    });

    this.messageError = new MessageError();
  }

  submit() {
      
    this.formModeloDiagnosticoDadosCreate.value.usuario = this.util.getDtnUserWrapper().getNomeUsuario();

    if (this.id) {
      this.modeloDiagnosticoService.update(this.id, this.formModeloDiagnosticoDadosCreate.value)
        .subscribe(response => {
          this.tratarMensagemSucesso('alterado', this.id);
        },
          errorResponse => {
            this.tratarMensagemErro('alterar', errorResponse);
          })
    }
    else {
      this.modeloDiagnosticoService.save(this.formModeloDiagnosticoDadosCreate.value)
        .subscribe(response => {
          if (response) {
            this.tratarMensagemSucesso('cadastrado', response.numero);
          }
        },
          errorResponse => {
            this.tratarMensagemErro('cadastrar', errorResponse);
          })
    }
  }

  carregarParaAlterar(id: number) {
    if (id) {
      this.id = id;
      this.modeloDiagnosticoService.findById(id).subscribe(response => {
        this.formModeloDiagnosticoDadosCreate.patchValue({
          numero: response.numero,
          data: response.dataEnvioAprovacao,
          descricao: response.descricao,
          ativo: response.ativo
        });
        this.formModeloDiagnosticoDadosCreate.get('ativo').enable();

      }, errorResponse => {
        this.tratarMensagemErro('buscar', errorResponse);
        this.limparForm();
      }, () => this.childModeloDiagnosticoQuestoesComponent.buscar())
    }
  }

  enviarParaAprovacao(): void {
    this.formModeloDiagnosticoDadosCreate.value.usuario = this.util.getDtnUserWrapper().getNomeUsuario();
    this.modeloDiagnosticoService.sendToApprove(this.id, this.formModeloDiagnosticoDadosCreate.value).subscribe(response => {
      this.tratarMensagemSucesso('enviado', this.id)
    },
      errorResponse => {
        this.tratarMensagemErro('enviar para aprovação', errorResponse)
      });
  }

  limparForm() {
    this.id = null;
    this.formModeloDiagnosticoDadosCreate.patchValue({
      numero: '',
      data: '',
      descricao: '',
      ativo: true
    });
    this.formModeloDiagnosticoDadosCreate.get('ativo').disable();
    this.cleanValidatorsError();
  }

  private tratarMensagemSucesso(acao: string, id: number) {
    this.modalService.open({
      type: EnumModalType.SUCCESS,
      message: `Modelo Diagnóstico ${acao} com sucesso`,
      buttons: [EnumModalButtons.OK],
      okEvent: () => {
        this.limparForm();
        this.buscarDadosChild(id);
      }
    });
  }

  private tratarMensagemErro(acao: string, errorResponse: any) {
    switch (errorResponse.status) {
      case 404:
      case 422:
        this.modalService.open({
          type: EnumModalType.ALERT,
          message: errorResponse.error.errors.join('. '),
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.buscarDadosChild(); }
        });
        break;
      default:
        this.modalService.open({
          type: EnumModalType.ERROR,
          message: `Não foi possível ${acao} o modelo diagnóstico`,
          buttons: [EnumModalButtons.OK],
          okEvent: () => { this.buscarDadosChild(); }
        });
        break;
    }
  }

  get data() { return this.formModeloDiagnosticoDadosCreate.get('data'); }

  get formCreateControls() { return this.formModeloDiagnosticoDadosCreate.controls; }

  private buscarDadosChild(id?: number) {
    this.childModeloDiagnosticoListComponent.buscar(id);
    this.childModeloDiagnosticoQuestoesComponent.buscar();
  }

  private cleanValidatorsError() {
    this.formModeloDiagnosticoDadosCreate.markAsPristine();
    this.formModeloDiagnosticoDadosCreate.markAsUntouched();
  }
}
