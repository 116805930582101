import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InformacoesTurma } from "src/app/models/informacoes-turma";
import { MessageService } from "src/app/services/message.service";
import { TurmaService } from "src/app/services/turma.service";

@Component({
  selector: 'app-turma-view',
  templateUrl: './turma-view.component.html',
  styleUrls: ['./turma-view.component.css']
})
export class TurmaViewComponent implements OnInit {
  agendamentoId: number;
  turma: InformacoesTurma;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private turmaService: TurmaService,
    private messageService: MessageService) {
    this.agendamentoId = this.route.snapshot.params['agendamentoId'];
  }

  ngOnInit(): void {
    this.turmaService.find(this.agendamentoId)
      .subscribe(data => {
        if(data)
          this.turma = data;
      }, errorResponse => {
        this.messageService.tratarMensagemErro(
          'Não foi possível obter as informações da turma.',
          true,
          errorResponse,
          () => {
            this.router.navigate(['/turma']);
          })
      });
  }

  getAnoDescription() {
    if(!this.turma.anos || this.turma.anos.length == 0)
      return '';

    let description: string = '';
    this.turma.anos.map(ano => {
      description += ` ${ano}º,`;
    });

    return description.substring(0, description.length - 1);
  }

}
