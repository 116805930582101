import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TermoCompromisso } from 'src/app/models/termo-compromisso';
import { AgendamentoService } from 'src/app/services/agendamento.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
    selector: 'app-termo-compromisso',
    templateUrl: './termo-compromisso.component.html',
    styleUrls: ['./termo-compromisso.component.css']
})
export class TermoCompromissoComponent implements OnInit {

    id: number;
    form: FormGroup;
    termoCompromisso: TermoCompromisso;

    constructor(private formBuilder: FormBuilder,
        private messageService: MessageService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private agendamentoService: AgendamentoService) { }


    ngOnInit(): void {

        let params: Observable<Params> = this.activatedRoute.params;
        params.subscribe(urlParams => {
            this.id = urlParams['escolaId'];
            
            this.printAgendamento();

            this.agendamentoService.getInfo(this.id).subscribe(response => {
                this.termoCompromisso = response
                
                if(this.termoCompromisso.assinado){
                    this.router.navigate(['/agendamento/criar/'+ this.id])
                }
            },
                errorResponse => {
                    this.messageService.tratarMensagemErro("Erro ao buscar informações do agendamento", true, errorResponse, () => { this.router.navigate(['/agendamento']) });
                });
        });

        this.form = this.formBuilder.group({
            acceptTerms: [false, Validators.requiredTrue]
        });
    }

    print() {
        this.agendamentoService.printTerm(this.id).subscribe((response) => {

            let blob = new Blob([response], { type: 'application/pdf' });

            let downloadURL = window.URL.createObjectURL(blob);

            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = "TermoDeCompromisso.pdf";
            link.click();

        }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível fazer o gerar a impressão", true, errorResponse, () => { })
        });
    }
    
    printAgendamento() {
        this.agendamentoService.printTermAgendamento(this.id).subscribe((response) => {          
            let file = new Blob([response], { type: 'application/pdf' });            
            var fileURL = URL.createObjectURL(file);
            document.getElementById('pdfEmbed').setAttribute('src', fileURL);
          }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível gerar a impressão", true, errorResponse, () => { });
          });
    }

    submit(): void {
        if (!this.form.controls.acceptTerms.value) {
            this.messageService.aviso('Ops! Você esqueceu de concordar.');
        }
        this.agendamentoService.assinarTermo(this.id).subscribe((response) => {
            this.router.navigate([`/agendamento/criar/${this.id}`]);
            }, errorResponse => {
            this.messageService.tratarMensagemErro("Não foi possível assinar o documento", true, errorResponse, () => { });
        });
    }
}
